import { ActionsColumn, TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { isoToPy } from '../../../utils';
import { CONSULTATION_TYPES } from '../../../constants';
import { getAspirinText } from '../utils';
import { Button } from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import React from 'react';

const PregnancyConsultationHistory = ({ history, actions }) => {
  const columns = {
    date: 'Fecha',
    type: 'Tipo',
    gestationalAge: 'Gesta',
    bloodPressure: 'PA',
    proteinuria: 'Proteinuria',
    aspirin: 'AAS',
    hospital: 'Hospital',
    createdBy: 'Profesional',
  };

  return (
    <TableComposable>
      <Thead>
        <Tr key="heading">
          <Th key="date">{columns.date}</Th>
          <Th key="type">{columns.type}</Th>
          <Th key="gestationalAge">{columns.gestationalAge}</Th>
          <Th key="bloodPressure">{columns.bloodPressure}</Th>
          <Th key="proteinuria">{columns.proteinuria}</Th>
          <Th key="aspirinGiven">{columns.aspirin}</Th>
          <Th key="hospital">{columns.hospital}</Th>
          <Th key="doctor">{columns.createdBy}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {history.map((el) => {
          let bloodPressure;
          if (el.systolicSecondTake) {
            bloodPressure = `${el.systolicSecondTake}/${el.diastolicSecondTake}`;
          } else {
            bloodPressure = `${el.systolicFirstTake}/${el.diastolicFirstTake}`;
          }
          let proteinuria;
          if (el.proteinuria === null) proteinuria = 'No realizado';
          else if (el.proteinuria === 0) proteinuria = 'Negativo';
          else if (el.proteinuria > 0) {
            proteinuria = '+'.repeat(el.proteinuria);
          }
          return (
            <Tr key={el.id}>
              <Td key={el.id}>{isoToPy(el.consultationDate)}</Td>
              <Td key={`${el.id}_type`}>{CONSULTATION_TYPES[el.type]}</Td>
              <Td key={`${el.id}_gestationalAge`}>
                {el.weeksDays ? `${el.weeksDays.weeks}s ${el.weeksDays.days}d` : ''}
              </Td>
              <Td key={`${el.id}_bloodPressure`}>{bloodPressure}</Td>
              <Td key={`${el.id}_proteinuria`}>{proteinuria}</Td>
              <Td key={`${el.id}_aspirinGiven`}>
                {el.aspirinDispense ? getAspirinText(el.aspirinDispense.status) : '-'}
              </Td>
              <Td key={`${el.id}_hospital`}>{el.hospital ? el.hospital.name : ''}</Td>
              {el.createdBy ? (
                <Td key={el.createdBy.id}>
                  {el.createdBy.lastName
                    ? `${el.createdBy.lastName}, ${el.createdBy.firstName}`
                    : el.createdBy.username}
                </Td>
              ) : (
                <Td />
              )}
              <Td dataLabel={'Editar'} modifier="fitContent">
                <Button component={Link} to={`/patients/${el.patient}/history/${el.id}`} variant="secondary">
                  Editar
                </Button>
              </Td>
              <Td isActionCell>
                <ActionsColumn items={actions(el.id)} />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </TableComposable>
  );
};

export default PregnancyConsultationHistory;
