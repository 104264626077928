const calculateRisk = (riskFactors) => {
  let sum = 0;
  if (riskFactors.hypertensionPreviousPregnancies) sum += 2;
  if (riskFactors.chronicHypertension) sum += 2;
  if (riskFactors.diabetes) sum += 2;
  if (riskFactors.autoimmune) sum += 2;
  if (riskFactors.renal) sum += 2;
  if (riskFactors.firstPregnancy) sum += 1;
  if (riskFactors.tooDistantPregnancy) sum += 1;
  if (riskFactors.obesity) sum += 1;
  if (riskFactors.multiplePregnancy) sum += 1;
  if (riskFactors.familyPreeclampsia) sum += 1;
  if (riskFactors.oldAge) sum += 1;
  return sum;
};

const getAspirinText = (value) => {
  if (value === 1) return 'Entregado';
  if (value === 2) return 'Obtuvo por propios medios';
  if (value === 3) return 'No entregado';
  if (value === 4) return 'Otros';
  if (value === 5) return 'Indicado';
  if (value === 6) return 'No indicado';
};

export { calculateRisk, getAspirinText };
