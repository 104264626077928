import {
  Bullseye,
  Button,
  Flex,
  FlexItem,
  InputGroup,
  PageSection,
  Pagination,
  PaginationVariant,
  Spinner,
  TextInput,
  Toolbar,
  ToolbarContent,
} from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import EntityTable from '../../components/EntityTable';

const InstitutionList = () => {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getHospitals = (ordering = null, search = '', limit = 50, offset = 0) => {
    setIsLoading(true);
    api.hospitals.getMulti(ordering, search, limit, offset).then((response) => {
      setCount(response.count);
      setHospitals(response.results);
      setIsLoading(false);
    });
  };

  useEffect(getHospitals, []);

  const applyFilters = (e) => {
    e.preventDefault();
    getHospitals(null, search, limit, offset);
  };

  const onInstitutionDelete = (hospitalId) => {
    api.hospitals
      .delete(hospitalId)
      .then(() => {
        getHospitals();
      })
      .catch((e) => {
        console.error(e);
        alert('Error al eliminar el establecimiento.');
      });
  };

  const toolbarItems = (
    <form onSubmit={applyFilters} style={{ width: '100%' }}>
      <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
        <Flex alignItems={{ default: 'alignItemsCenter' }}>
          <FlexItem>
            <InputGroup>
              <TextInput
                name="search"
                id="search"
                type="search"
                value={search}
                onChange={setSearch}
                placeholder="Nombre del establecimiento"
              />
            </InputGroup>
          </FlexItem>
          <FlexItem variant="separator" />
          <FlexItem>
            <Button variant="primary" type="submit">
              Filtrar
            </Button>
          </FlexItem>
        </Flex>
        <Button component={Link} to={`/hospitals/new`} variant="secondary">
          Crear Establecimiento
        </Button>
      </Flex>
    </form>
  );

  const columns = { id: 'ID', name: 'Nombre', city: 'Ciudad' };
  const rows = hospitals.map((hospital) => ({ id: hospital.id, name: hospital.name, city: hospital.city?.fullName }));

  const onSetPage = (_event, pageNumber) => {
    setOffset((pageNumber - 1) * limit);
    setCurrentPage(pageNumber);
  };

  const onPerPageSelect = (_event, perPage) => {
    setCurrentPage(offset / perPage + 1);
    setLimit(perPage);
  };

  return (
    <PageSection>
      <Toolbar>
        <ToolbarContent>{toolbarItems}</ToolbarContent>
      </Toolbar>
      {isLoading && (
        <Bullseye>
          <Spinner isSVG />
        </Bullseye>
      )}
      {!isLoading && <EntityTable columns={columns} rows={rows} entity="hospitals" onDelete={onInstitutionDelete} />}
      <Pagination
        itemCount={count}
        widgetId="pagination-options-menu-bottom"
        perPage={limit}
        page={currentPage}
        variant={PaginationVariant.bottom}
        onSetPage={onSetPage}
        onPerPageSelect={onPerPageSelect}
      />
    </PageSection>
  );
};

export default InstitutionList;
