import { ActionGroup, Button, ButtonType, ButtonVariant } from '@patternfly/react-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
const FormButtons = ({ isSubmitDisabled, sendingData }) => {
  let navigate = useNavigate();

  return (
    <ActionGroup>
      {!sendingData ? (
        <Button type={ButtonType.submit} isDisabled={isSubmitDisabled()}>
          Guardar
        </Button>
      ) : (
        <Loading />
      )}
      <Button variant={ButtonVariant.secondary} onClick={() => navigate(-1)}>
        Cancelar
      </Button>
    </ActionGroup>
  );
};

FormButtons.defaultProps = {
  isSubmitDisabled: () => false,
};

export default FormButtons;
