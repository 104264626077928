import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Bullseye,
  Divider,
  PageSection,
  PageSectionVariants,
  Spinner,
  Title,
  TitleSizes,
} from '@patternfly/react-core';
import { usePatient } from '../../context/patientContext';
import Breadcrumbs from '../../components/Breadcrumbs';
import IconButton from '../../components/IconButton';
import { weekDayDifference } from '../../utils';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import AspirinModal from '../../components/AspirinModal';
import api from '../../services/api';

const PatientDashboard = () => {
  let { id } = useParams();
  let { userData } = useAuth();
  let navigate = useNavigate();
  const { fetchPatient, patient } = usePatient();
  const [loading, setLoading] = useState(true);
  const breadcrumbs = [
    { link: '/patients', title: 'Pacientes' },
    { link: `/patients/${id}`, title: `${patient?.firstName} ${patient?.lastName}` },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmit = (aspirinValue, aspirinComments) => {
    const d = new Date();
    const string_date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    const data = {
      patient: patient.id,
      status: aspirinValue,
      comments: aspirinComments,
      deliveryDate: string_date,
    };
    setSendingData(true);
    api.aspirins
      .create(data)
      .then(() => {
        handleModalToggle();
        setSendingData(false);
      })
      .catch((err) => {
        console.log(err);
        setSendingData(false);
      });
  };

  useEffect(() => {
    if (!patient) {
      fetchPatient(id).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, fetchPatient, patient]);

  if (loading) {
    return (
      <Bullseye>
        <Spinner />
      </Bullseye>
    );
  }

  let riskFactor;
  if (patient.riskIndex === null) riskFactor = 'RIESGO DE PREECLAMPSIA NO DEFINIDO';
  else if (patient.riskIndex >= 2) riskFactor = 'ALTO RIESGO DE PREECLAMPSIA';
  else if (patient.riskIndex <= 1) riskFactor = 'BAJO RIESGO DE PREECLAMPSIA';

  let pregnancyAge = '';
  if (patient.activePregnancy) {
    const date = patient.activePregnancy.pregnancyStartDate
      ? new Date(patient.activePregnancy.pregnancyStartDate)
      : new Date(patient.activePregnancy.lastMenstruation);
    const weeksDays = weekDayDifference(date);
    pregnancyAge = `${weeksDays.weeks} SEM y ${weeksDays.days} DIAS`;
  }

  if (patient && patient.needsConfirmation) {
    navigate(`/patients/${patient.id}/data?confirm=true`);
  }

  return (
    <PageSection>
      <Breadcrumbs links={breadcrumbs} />
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1" size={TitleSizes['4xl']}>
          {patient.firstName} {patient.lastName}
        </Title>
        <div className="patient-data">
          <span>C.I. {patient.govId}</span>
          {patient.age && <span>{patient.age} años</span>}
          <span>{pregnancyAge}</span>
          <span className={patient.riskIndex >= 2 ? 'high-risk' : 'low-risk'}>{riskFactor}</span>
        </div>
        <Divider style={styles.divider} />
        <div className="actions-area">
          <div>
            <Title headingLevel="h3">Consultas</Title>
            <div style={styles.buttonGroup}>
              {(userData?.isStaff || userData?.enableNangareko) && (
                <IconButton
                  action={null}
                  icon="gynecology"
                  link={`/patients/${id}/new-consultation/gynecology`}
                  text={
                    <p>
                      Ginecología y
                      <br />
                      Planificación
                    </p>
                  }
                />
              )}
              {/*<IconButton*/}
              {/*  icon="contraception"*/}
              {/*  link={`/patients/${id}/new-consultation/4`}*/}
              {/*  text={<p>Planificación<br />familiar</p>}*/}
              {/*/>*/}
              {!patient.activePregnancy && (
                <IconButton
                  action={null}
                  icon="pregnancyFirst"
                  link={`/patients/${id}/new-consultation/2`}
                  text={
                    <p>
                      Embarazo:
                      <br />
                      Primera Consulta
                    </p>
                  }
                />
              )}
              {patient.activePregnancy && (
                <>
                  <IconButton
                    action={null}
                    icon="pregnancyFollowUp"
                    link={`/patients/${id}/new-consultation/3`}
                    text={
                      <p>
                        Embarazo:
                        <br />
                        Seguimiento
                      </p>
                    }
                  />
                  <IconButton icon="baby" link={`/patients/${id}/pregnancy`} text={<p>Cierre de embarazo</p>} />
                </>
              )}
              {patient.riskFactorSum >= 2 ? (
                <IconButton icon="contraception" link={null} text="Cargar aspirinas" action={handleModalToggle} />
              ) : null}
            </div>
          </div>
          <div>
            <Title headingLevel="h3">Historial</Title>
            <div style={styles.buttonGroup}>
              <IconButton icon="patientDetails" text="Datos de la paciente" link={`/patients/${id}/data`} />
              <IconButton icon="patientHistory" text="Historial" link={`/patients/${id}/history`} />
            </div>
          </div>
        </div>
      </PageSection>
      <AspirinModal
        isModalOpen={isModalOpen}
        handleModalToggle={handleModalToggle}
        onSubmit={onSubmit}
        sendingData={sendingData}
        selectedPatient={patient}
        pvValue={null}
        pvComments={null}
      />
    </PageSection>
  );
};

const styles = {
  divider: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  patientName: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  patientDoc: {
    fontSize: '1.1rem',
    marginTop: 0,
    textAlign: 'center',
  },
  sectionContainer: {
    width: '100%',
  },
};

export default PatientDashboard;
