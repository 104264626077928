import { FormGroup, FormSection, FormSelect, FormSelectOption, Grid, TextArea } from '@patternfly/react-core';
import { TableComposable, TableVariant, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { getAspirinText } from '../utils';
import { isoToPy } from '../../../utils';
import { useAuth } from '../../../context/authContext';

const Medications = ({ aspirinData, onAspirinDataChange, aspirinHistory }) => {
  let auth = useAuth();

  const onAspirinValueChange = (value) => {
    onAspirinDataChange({
      ...aspirinData,
      value,
    });
  };

  const onAspirinCommentsChange = (comments) => {
    onAspirinDataChange({
      ...aspirinData,
      comments,
    });
  };

  return (
    <FormSection title="Medicamentos">
      <Grid md={4} hasGutter>
        <FormGroup
          isRequired
          label="AAS"
          fieldId="aspirin"
          validated={aspirinData.validated}
          helperText={aspirinData.helperText}
        >
          <FormSelect
            isDisabled={aspirinData.isDisabled}
            id="aspirin"
            value={aspirinData.value}
            onChange={onAspirinValueChange}
          >
            <FormSelectOption key="select" isPlaceholder value={0} label="Seleccionar" />
            {auth.userData?.enableDeliverAspirin ? (
              <>
                {' '}
                <FormSelectOption key={1} value={1} label="Entregado (especificar cantidad de cajas)" />
                <FormSelectOption key={3} value={3} label="No entregado" />
              </>
            ) : (
              <>
                <FormSelectOption key={5} value={5} label="Indicado" />
                <FormSelectOption key={6} value={6} label="No Indicado" />
              </>
            )}

            {/* <FormSelectOption key={2} value={2} label="Obtuvo por sus propios medios" />
            <FormSelectOption key={4} value={4} label="Otros (especificar)" /> */}
          </FormSelect>
        </FormGroup>
        <FormGroup isRequired label={aspirinData.value === 1 ? 'Cantidad' : 'Detalles'} fieldId="aspirinComments">
          <TextArea id="aspirincomments" onChange={onAspirinCommentsChange} value={aspirinData.comments} />
        </FormGroup>
        <FormGroup label="Historial de entregas" fieldId="aspirinHistory">
          <TableComposable variant={TableVariant.compact}>
            <Thead>
              <Tr>
                <Th key="fecha">Fecha</Th>
                <Th key="entregada">Entregado</Th>
                <Th key="cant">Cant./Comentarios</Th>
              </Tr>
            </Thead>
            <Tbody>
              {aspirinHistory.map((h, i) => (
                <Tr key={i}>
                  <Td key={`${i}_date`}>{isoToPy(h.date)}</Td>
                  <Td key={`${i}_given`}>{getAspirinText(h.aspirinGiven)}</Td>
                  <Td key={`${i}_comments`}>{h.aspirinComments}</Td>
                </Tr>
              ))}
            </Tbody>
          </TableComposable>
        </FormGroup>
      </Grid>
    </FormSection>
  );
};

export default Medications;
