import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
  Button,
  ButtonVariant,
  Checkbox,
  DatePicker,
  Form,
  FormGroup,
  FormSection,
  FormSelect,
  FormSelectOption,
  Grid,
  Modal,
  ModalVariant,
  NumberInput,
  PageSection,
  PageSectionVariants,
  Radio,
  TextArea,
  TextInput,
  Title,
} from '@patternfly/react-core';
import { dateFormat, dateFormatter, dateParse, dateRangeValidator, dateSubmitParser, isoToPy } from '../../utils';
import { usePatient } from '../../context/patientContext';
import FormButtons from '../../components/FormButtons';
import Loading from '../../components/Loading';
import api from '../../services/api';
import { GYNECOLOGY } from '../../constants';

const CONTRACEPTION_DATA = {
  none: true,
  implantInsertion: false,
  implantExtraction: false,
  aoc: false,
  dmpa: false,
  ai: false,
  counselingOnly: false,
  iudInsertion: false,
  iudExtraction: false,
  miniIudInsertion: false,
  miniIudExtraction: false,
  condomsGiven: false,
  pae: false,
  extractionReason: '',
  extractionReasonOther: '',
  extractionHospital: '',
  extractionHospitalOther: '',
};

const GynecologyConsultationForm = () => {
  let { id, consultationId } = useParams();
  let { patient, fetchPatient } = usePatient();
  let navigate = useNavigate();
  const [reasonsForConsultation, setReasonsForConsultation] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [consultation, setConsultation] = useState(null);
  const [consultationDate, setConsultationDate] = useState(dateFormat(new Date()));
  const [menarche, setMenarche] = useState(12);
  const [births, setBirths] = useState(0);
  const [vaginal, setVaginal] = useState(0);
  const [csections, setCsections] = useState(0);
  const [abortions, setAbortions] = useState(0);
  const [hpv, setHpv] = useState({
    firstDose: false,
    secondDose: false,
  });
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReason, setOtherReason] = useState({ isChecked: false, value: '' });
  const [contraception, setContraception] = useState(CONTRACEPTION_DATA);

  const [comments, setComments] = useState('');
  const [loadingPatient, setLoadingPatient] = useState(true);
  const [loadingConsultation, setLoadingConsultation] = useState(true);
  const [loadingReasons, setLoadingReasons] = useState(true);
  const [loadingHospitals, setLoadingHospitals] = useState(true);
  const [lastConsultation, setLastConsultation] = useState(true);
  const [errors, setErrors] = useState([]);
  const [sendingData, setSendingData] = useState(false);

  const [requestedAMethod, setRequestedAMethod] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [receivedTheRequestedMethod, setReceivedTheRequestedMethod] = useState(null);
  const [receivedMethod, setReceivedMethod] = useState('');
  const [whyNotReceiveRequestedMethod, setWhyNotReceiveRequestedMethod] = useState('');

  const [modal, setModal] = useState({
    isOpen: false,
    body: '',
    title: '',
    shown: false,
  });

  const breadcrumbs = [
    { link: '/patients', title: 'Pacientes' },
    { link: `/patients/${id}`, title: `${patient?.firstName} ${patient?.lastName}` },
  ];

  if (consultationId) {
    breadcrumbs.push(
      { link: `/patients/${id}/gynecology-history`, title: 'Historial de consultas' },
      { link: `/patients/${id}/gynecology-history/${consultationId}`, title: `Consulta #${consultationId}` }
    );
  } else {
    breadcrumbs.push({ link: `/patients/${id}/new-consultation/gynecology`, title: 'Nueva consulta' });
  }

  // fetch patient
  useEffect(() => {
    fetchPatient(id).then((p) => {
      setMenarche(p.menarche || 12);
      setBirths(p.births || 0);
      setVaginal(p.vaginalBirths || 0);
      setCsections(p.csections || 0);
      setAbortions(p.abortions || 0);
      setHpv({
        firstDose: !!p.hpvFirstDose,
        secondDose: !!p.hpvSecondDose,
      });
      if (p.age >= 15 && p.age <= 19) {
        setModal({
          isOpen: true,
          title: 'ATENCIÓN',
          body: <div className="high-risk">NO OLVIDE DAR ASESORAMIENTO SOBRE PLANIFICACIÓN FAMILIAR.</div>,
        });
      }

      setLoadingPatient(false);
    });
  }, [id]);

  const setConsultationData = (c) => {
    setSelectedReasons(c.reasonsForConsultation);
    setOtherReason({ isChecked: !!c.reasonsOther, value: c.reasonsOther || '' });
    setContraception(c.contraception || contraception);
    setComments(c.comments || '');
    setRequestedAMethod(c.requestedAParticularMethod);
    setSelectedMethod(c.requestedMethod === null ? '' : c.requestedMethod);
    setReceivedTheRequestedMethod(c.receivedTheRequestedMethod);
    setReceivedMethod(c.whatMethodReceived === null ? '' : c.whatMethodReceived);
    setWhyNotReceiveRequestedMethod(c.whyNotReceiveRequestedMethod === null ? '' : c.whyNotReceiveRequestedMethod);
  };

  // fetch last consultation
  useEffect(() => {
    if (patient && !consultationId) {
      api.gynecologyConsultations.getLastConsultation(id).then((res) => {
        setLastConsultation(res);
        if (isoToPy(res.consultationDate) === consultationDate && res.type === GYNECOLOGY) {
          setConsultationData(res);
        }
      });
    }
  }, [id, patient, consultationId]);

  // if consultationDate matches data from lastConsultation,
  // set data from last consultation and update consultation upon submit
  useEffect(() => {
    if (isoToPy(lastConsultation.consultationDate) === consultationDate && lastConsultation.type === GYNECOLOGY) {
      setConsultationData(lastConsultation);
    } else {
      setSelectedReasons([]);
      setOtherReason({ isChecked: false, value: '' });
      setContraception(CONTRACEPTION_DATA);
      setComments('');
    }
  }, [consultationDate, lastConsultation]);

  // fetch reasons for consultation and hospitals
  useEffect(() => {
    api.gynecologyConsultations.getReasons().then((res) => {
      setReasonsForConsultation(res);
      setLoadingReasons(false);
    });
    api.hospitals.getMulti().then((res) => {
      setHospitals(res.results);
      setLoadingHospitals(false);
    });
  }, []);

  // fetch consultation
  useEffect(() => {
    if (consultationId) {
      api.gynecologyConsultations.getOne(consultationId).then((c) => {
        setConsultation(c);
        setConsultationDate(isoToPy(c.consultationDate));
        setComments(c.comments);
        setConsultationData(c);

        setLoadingConsultation(false);
        console.log(c);
      });
    } else {
      setLoadingConsultation(false);
    }
  }, [consultationId]);

  // check vph 1st dose if second is checked
  useEffect(() => {
    if (hpv.secondDose && !hpv.firstDose) {
      setHpv({ firstDose: true, secondDose: true });
    }
  }, [hpv]);

  const onContraceptiveChange = (contraceptive) => {
    const _list = { ...CONTRACEPTION_DATA };
    _list['none'] = false;
    _list[contraceptive] = true;
    setContraception(_list);
    /*Automatically adds the reason "MAC Counseling" if any contraceptive method is selected*/
    if (contraceptive !== 'none') {
      setSelectedReasons([...selectedReasons, 10]);
    } else {
      setSelectedReasons(selectedReasons.filter((e) => e !== 10));
    }
  };

  const onReasonCheckChange = (id) => {
    if (selectedReasons.includes(id)) setSelectedReasons(selectedReasons.filter((e) => e !== id));
    else {
      setSelectedReasons([...selectedReasons, id]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSendingData(true);
    const data = {
      patient: patient.id,
      consultationDate: dateSubmitParser(consultationDate),
      menarche,
      births,
      vaginalBirths: vaginal,
      csections,
      abortions,
      reasonsForConsultation: selectedReasons,
      reasonsOther: otherReason.value,
      contraception,
      comments,
      requestedAParticularMethod: requestedAMethod,
      requestedMethod: selectedMethod === '' ? null : selectedMethod,
      receivedTheRequestedMethod: receivedTheRequestedMethod,
      whyNotReceiveRequestedMethod: whyNotReceiveRequestedMethod === '' ? null : whyNotReceiveRequestedMethod,
      whatMethodReceived: receivedMethod === '' ? null : receivedMethod,
      hpvFirstDose: hpv.firstDose,
      hpvSecondDose: hpv.secondDose,
    };
    if (contraception.extractionHospital === 'other') data.contraception.extractionHospital = null;
    if (consultation) {
      data['type'] = GYNECOLOGY;
      api.gynecologyConsultations.update(consultationId, data).then(() => {
        navigate(`/patients/${patient.id}/history`);
      });
    } else {
      api.gynecologyConsultations
        .create(data)
        .then(() => {
          setSendingData(false);
          fetchPatient(patient.id);
          navigate(`/patients/${patient.id}`);
        })
        .catch((e) => {
          setSendingData(false);
          console.error(e);
          if (e.data?.nonFieldErrors) {
            alert(e.data.nonFieldErrors.join('\n'));
          } else alert('Error al guardar los datos. Por favor intente de nuevo.');
        });
    }
  };

  const isSubmitDisabled = () => {
    if (!consultationDate) return true;
    if (menarche < 5 || births < 0 || vaginal < 0 || csections < 0 || abortions < 0) return true;
    if (contraception.extractionHospital === 'other' && contraception.extractionHospitalOther === '') return true;
    if (contraception.extractionReason === 'other' && contraception.extractionReasonOther === '') return true;
    if (
      (contraception.extractionHospital === '' || contraception.extractionReason === '') &&
      !contraception.none &&
      (contraception.implantExtraction || contraception.iudExtraction || contraception.miniIudExtraction)
    )
      return true;
    if (selectedReasons.length === 0 && !otherReason.isChecked) return true;
    return false;
  };

  if (loadingConsultation || loadingPatient || loadingReasons || loadingHospitals) {
    return <Loading />;
  }

  return (
    <PageSection>
      <Breadcrumbs links={breadcrumbs} />
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1">NUEVA CONSULTA</Title>
        <Form onSubmit={onSubmit} style={{ marginTop: '1.5rem' }}>
          <FormGroup label="Fecha de consulta" fieldId="consultationDate">
            <DatePicker
              id="consultationDate"
              dateFormat={dateFormat}
              dateParse={dateParse}
              placeholder="dd/mm/aaaa"
              locale="es-PY"
              onChange={(value) => setConsultationDate(dateFormatter(value))}
              value={consultationDate}
              validators={[dateRangeValidator]}
              invalidFormatText="Formato de fecha inválido. El formato debe ser dd/mm/aaaa"
            />
          </FormGroup>
          <FormSection title="Datos generales">
            <Grid md={3}>
              <FormGroup fieldId="name" label="Nombre">
                {patient?.firstName} {patient?.lastName}
              </FormGroup>
              <FormGroup fieldId="govId" label="CI">
                {patient?.govId}
              </FormGroup>
              <FormGroup fieldId="age" label="Edad">
                {patient?.age} años
              </FormGroup>
            </Grid>
          </FormSection>
          <FormSection title="Historial clínico">
            <Grid md={3} hasGutter>
              <FormGroup label="Menarca" isRequired fieldId="menarche">
                <NumberInput
                  id="menarche"
                  name="menarche"
                  onChange={(e) => setMenarche(e.target.value)}
                  onPlus={() => setMenarche(parseInt(menarche || 0) + 1)}
                  onMinus={() => setMenarche(parseInt(menarche || 0) - 1)}
                  min={5}
                  unit="años"
                  value={menarche}
                  widthChars={2}
                />
              </FormGroup>
              <FormGroup label="Gesta" isRequired fieldId="births">
                <NumberInput
                  id="births"
                  name="births"
                  onChange={(e) => setBirths(e.target.value)}
                  onPlus={() => setBirths(parseInt(births || 0) + 1)}
                  onMinus={() => setBirths(parseInt(births || 0) - 1)}
                  min={0}
                  value={births}
                  widthChars={2}
                />
              </FormGroup>
              <FormGroup label="Para" isRequired fieldId="vaginal">
                <NumberInput
                  id="vaginal"
                  name="vaginal"
                  onChange={setVaginal}
                  onPlus={() => setVaginal(parseInt(vaginal || 0) + 1)}
                  onMinus={() => setVaginal(parseInt(vaginal || 0) - 1)}
                  min={0}
                  value={vaginal}
                  widthChars={2}
                />
              </FormGroup>
              <FormGroup label="Cesáreas" isRequired fieldId="csections">
                <NumberInput
                  id="csections"
                  name="csections"
                  onChange={(e) => setCsections(parseInt(e.target.value))}
                  onPlus={() => setCsections(parseInt(csections || 0) + 1)}
                  onMinus={() => setCsections(parseInt(csections || 0) - 1)}
                  min={0}
                  value={csections}
                  widthChars={2}
                />
              </FormGroup>
              <FormGroup label="Abortos" isRequired fieldId="abortions">
                <NumberInput
                  id="abortions"
                  name="abortions"
                  onChange={(e) => setAbortions(e.target.value)}
                  onPlus={() => setAbortions(parseInt(abortions || 0) + 1)}
                  onMinus={() => setAbortions(parseInt(abortions || 0) - 1)}
                  min={0}
                  value={abortions}
                  widthChars={2}
                />
              </FormGroup>
              <FormGroup fieldId="vphFirstDose" style={{ alignItems: 'center', display: 'flex' }}>
                <Checkbox
                  isChecked={hpv.firstDose}
                  onChange={() => setHpv({ ...hpv, firstDose: !hpv.firstDose })}
                  id="vphFirstDose"
                  name="vphFirstDose"
                  label="VPH 1ra dosis"
                />
              </FormGroup>
              <FormGroup fieldId="vphSecondDose" style={{ alignItems: 'center', display: 'flex' }}>
                <Checkbox
                  isChecked={hpv.secondDose}
                  onChange={() => setHpv({ ...hpv, secondDose: !hpv.secondDose })}
                  id="vphSecondDose"
                  name="vphSecondDose"
                  label="VPH 2da dosis"
                />
              </FormGroup>
            </Grid>
          </FormSection>
          <FormSection title="Motivos de consulta">
            <Grid md={3} hasGutter>
              {reasonsForConsultation.map((reason) => (
                <Checkbox
                  key={`reason_${reason.id}`}
                  id={`reason_${reason.id}`}
                  name={`reason_${reason.id}`}
                  label={reason.title}
                  isChecked={selectedReasons.includes(reason.id)}
                  onChange={() => onReasonCheckChange(reason.id)}
                />
              ))}
              <div>
                <Checkbox
                  isChecked={otherReason.isChecked}
                  onChange={() => setOtherReason({ ...otherReason, isChecked: !otherReason.isChecked })}
                  id="other"
                  name="other"
                  label="Otros (especificar)"
                />
                <TextInput
                  name="otherDetail"
                  id="otherDetail"
                  isDisabled={!otherReason.isChecked}
                  value={otherReason.value}
                  onChange={(e) => setOtherReason({ ...otherReason, value: e })}
                />
              </div>
            </Grid>
          </FormSection>
          <FormSection title="Planificación">
            <label>
              <strong>¿Qué método recibió?</strong>
            </label>
            <Radio
              isChecked={contraception.none}
              onChange={() => onContraceptiveChange('none')}
              id="planningNone"
              name="planning"
              label="Ninguno"
            />
            <Grid md={3} hasGutter>
              <FormGroup fieldId="planning-implant" label="Implante">
                <Radio
                  isChecked={contraception.implantInsertion}
                  onChange={() => onContraceptiveChange('implantInsertion')}
                  id="implantInsertion"
                  name="planning"
                  label="Inserción"
                />
                <Radio
                  isChecked={contraception.implantExtraction}
                  onChange={() => onContraceptiveChange('implantExtraction')}
                  id="implantExtraction"
                  name="planning"
                  label="Extracción"
                />
              </FormGroup>
              <FormGroup fieldId="planning-diu" label="DIU">
                <Radio
                  isChecked={contraception.iudInsertion}
                  onChange={() => onContraceptiveChange('iudInsertion')}
                  id="iudInsertion"
                  name="planning"
                  label="Inserción"
                />
                <Radio
                  isChecked={contraception.iudExtraction}
                  onChange={() => onContraceptiveChange('iudExtraction')}
                  id="iudExtraction"
                  name="planning"
                  label="Extracción"
                />
              </FormGroup>
              <FormGroup fieldId="planning-minidiu" label="Mini DIU">
                <Radio
                  isChecked={contraception.miniIudInsertion}
                  onChange={() => onContraceptiveChange('miniIudInsertion')}
                  id="miniIudInsertion"
                  name="planning"
                  label="Inserción"
                />
                <Radio
                  isChecked={contraception.miniIudExtraction}
                  onChange={() => onContraceptiveChange('miniIudExtraction')}
                  id="miniIudExtraction"
                  name="planning"
                  label="Extracción"
                />
              </FormGroup>
              <FormGroup fieldId="planning-other" label="Otros">
                <Radio
                  isChecked={contraception.aoc}
                  onChange={() => onContraceptiveChange('aoc')}
                  id="aoc"
                  name="planning"
                  label="AOC"
                />
                <Radio
                  isChecked={contraception.dmpa}
                  onChange={() => onContraceptiveChange('dmpa')}
                  id="dmpa"
                  name="planning"
                  label="DMPA"
                />
                <Radio
                  isChecked={contraception.pae}
                  onChange={() => onContraceptiveChange('pae')}
                  id="pae"
                  name="planning"
                  label="PAE"
                />
                <Radio
                  isChecked={contraception.ai}
                  onChange={() => onContraceptiveChange('ai')}
                  id="ai"
                  name="ai"
                  label="AI"
                />
                <Radio
                  isChecked={contraception.counselingOnly}
                  onChange={() => onContraceptiveChange('counselingOnly')}
                  id="counseling"
                  name="counseling"
                  label="Solo consejería"
                />
              </FormGroup>
              <FormGroup fieldId="extractionPlace" label="Lugar de inserción">
                <FormSelect
                  value={contraception.extractionHospital}
                  onChange={(v) => setContraception({ ...contraception, extractionHospital: v })}
                  id="extractionPlace"
                  name="extractionPlace"
                  isDisabled={
                    !contraception.implantExtraction && !contraception.iudExtraction && !contraception.miniIudExtraction
                  }
                >
                  <FormSelectOption label="Seleccionar" isPlaceholder />
                  {hospitals?.map((h) => (
                    <FormSelectOption key={h.id} value={h.id} label={h.name} />
                  ))}
                  <FormSelectOption key="other" value="other" label="Otro" />
                </FormSelect>
                {contraception.extractionHospital === 'other' && (
                  <TextInput
                    placeholder="Nombre del hospital"
                    id="extractionPlaceOther"
                    value={contraception.extractionHospitalOther}
                    onChange={(v) => setContraception({ ...contraception, extractionHospitalOther: v })}
                  />
                )}
              </FormGroup>
              <FormGroup fieldId="extractionReason" label="Motivo de extracción">
                <FormSelect
                  value={contraception.extractionReason}
                  onChange={(v) => setContraception({ ...contraception, extractionReason: v })}
                  id="extractionReason"
                  name="extractionReason"
                  isDisabled={
                    !contraception.implantExtraction && !contraception.iudExtraction && !contraception.miniIudExtraction
                  }
                >
                  <FormSelectOption label="Seleccionar" isPlaceholder />
                  <FormSelectOption label="Acne" key="acne" value="acne" />
                  <FormSelectOption label="Aumento de peso" key="weight_gain" value="weight_gain" />
                  <FormSelectOption label="Expulsión" key="expulsion" value="expulsion" />
                  <FormSelectOption
                    label="Síntomas gastrointestinales"
                    key="gastrointestinal_symptoms"
                    value="gastrointestinal_symptoms"
                  />
                  <FormSelectOption
                    label="Trastornos Menstruales"
                    key="menstrual_disorder"
                    value="menstrual_disorder"
                  />
                  <FormSelectOption label="Vencimiento" key="expiration" value="expiration" />
                  <FormSelectOption key="other" value="other" label="Otro" />
                </FormSelect>
                {contraception.extractionReason === 'other' && (
                  <TextInput
                    id="extractionReasonOther"
                    placeholder="Escribe el motivo de extracción"
                    value={contraception.extractionReasonOther}
                    onChange={(v) => setContraception({ ...contraception, extractionReasonOther: v })}
                  />
                )}
              </FormGroup>
            </Grid>
            <Checkbox
              isChecked={contraception.condomsGiven}
              onChange={() => setContraception({ ...contraception, condomsGiven: !contraception.condomsGiven })}
              id="condoms"
              name="condoms"
              label="Se entregan preservativos"
            />
            <Grid md={4} hasGutter style={{ marginTop: '2rem' }}>
              <FormGroup fieldId="requested_a_particular_method" label="¿La paciente solicitó un método en particular?">
                <Radio
                  id="particularMethodYes"
                  name="requested_a_particular_method"
                  label="Sí"
                  onChange={() => setRequestedAMethod(true)}
                  isChecked={requestedAMethod === true}
                />
                <Radio
                  id="particularMethodNo"
                  name="requested_a_particular_method"
                  label="No"
                  onChange={() => setRequestedAMethod(false)}
                  isChecked={requestedAMethod === false}
                />
                <FormGroup fieldId="particularMethodSelect" label="Método solicitado" style={{ marginTop: '1rem' }}>
                  <FormSelect
                    value={selectedMethod}
                    id="particularMethodSelect"
                    name="particularMethodSelect"
                    isDisabled={!requestedAMethod}
                    onChange={(v) => setSelectedMethod(v)}
                  >
                    <FormSelectOption label="Seleccionar" isPlaceholder />
                    <FormSelectOption label="Implante" key="implant" value={0} />
                    <FormSelectOption label="DIU" key="iud" value={1} />
                    <FormSelectOption label="Mini DIU" key="mini_iud" value={2} />
                    <FormSelectOption label="AOC" key="aoc" value={3} />
                    <FormSelectOption label="DMPA" key="dmpa" value={4} />
                    <FormSelectOption label="PAE" key="pae" value={5} />
                    <FormSelectOption label="AI" key="ai" value={6} />
                  </FormSelect>
                </FormGroup>
              </FormGroup>
              <FormGroup fieldId="received_the_requested_method" label="¿La paciente recibió el método solicitado?">
                <Radio
                  id="receivedYes"
                  name="received_the_requested_method"
                  label="Sí"
                  onChange={() => setReceivedTheRequestedMethod(true)}
                  isChecked={receivedTheRequestedMethod === true}
                />
                <Radio
                  id="receivedNo"
                  name="received_the_requested_method"
                  label="No"
                  onChange={() => setReceivedTheRequestedMethod(false)}
                  isChecked={receivedTheRequestedMethod === false}
                />
                <FormGroup
                  label="¿Por qué no recibió el método solicitado?"
                  fieldId="whyNotReceiveRequestedMethod"
                  style={{ marginTop: '1rem' }}
                >
                  <FormSelect
                    value={whyNotReceiveRequestedMethod}
                    name="whyNotReceiveRequestedMethod"
                    id="whyNotReceiveRequestedMethod"
                    isDisabled={receivedTheRequestedMethod !== false}
                    onChange={(e) => setWhyNotReceiveRequestedMethod(e)}
                  >
                    <FormSelectOption label="Seleccionar" isPlaceholder />
                    <FormSelectOption label="No se cuenta con el insumo" key="non_existence" value={0} />
                    <FormSelectOption label="No corresponde" key="not_applicable" value={1} />
                  </FormSelect>
                </FormGroup>
              </FormGroup>
            </Grid>
          </FormSection>
          <FormSection title="Observaciones generales">
            <FormGroup fieldId="comments">
              <TextArea id="comments" onChange={setComments} value={comments} />
            </FormGroup>
          </FormSection>
          <FormButtons isSubmitDisabled={isSubmitDisabled} sendingData={sendingData} />
        </Form>
      </PageSection>
      <Modal
        aria-label="popup"
        variant={ModalVariant.small}
        title={modal.title}
        isOpen={modal.isOpen}
        onClose={() => setModal({ ...modal, isOpen: false })}
        actions={[
          <Button key="confirm" variant={ButtonVariant.primary} onClick={() => setModal({ ...modal, isOpen: false })}>
            Aceptar
          </Button>,
        ]}
      >
        {modal.body}
      </Modal>
    </PageSection>
  );
};

export default GynecologyConsultationForm;
