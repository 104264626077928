import React from 'react';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { Button } from '@patternfly/react-core';
import { isoToPy } from '../../../utils';
import AspirinModal from '../../../components/AspirinModal';
import { useState } from 'react';
import api from '../../../services/api';
import { useNavigate } from 'react-router-dom';

const AspirintHistory = ({ history, actions, selectedPatient }) => {
  const columns = {
    date: 'Fecha',
    status: 'Estado',
    comments: 'Comentarios',
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [pvValue, setPvValue] = useState(null);
  const [pvComments, setPvComments] = useState(null);
  const [selected, setSelected] = useState(null);
  let navigate = useNavigate();

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmit = (aspirinValue, aspirinComments) => {
    const data = {
      id: selected.id,
      patient: selected.patient,
      status: aspirinValue,
      comments: aspirinComments,
      deliveryDate: selected.deliveryDate,
    };
    api.aspirins
      .update(selected.id, data)
      .then(() => {
        handleModalToggle();
        setSelected(null);
        setSendingData(false);
        navigate(`/patients/${selected.patient}/`);
      })
      .catch((err) => {
        console.log(err);
        setSendingData(false);
        alert('Ocurrio un error al realizar los cambios');
      });
  };

  return (
    <TableComposable>
      <Thead>
        <Tr>
          <Th>{columns.date}</Th>
          <Th>{columns.status}</Th>
          <Th>{columns.comments}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {history.map((el) => {
          let status = '';
          if (el.status === 1) {
            status = 'Entregado';
          } else if (el.status === 2) {
            status = 'Obtuvo por sus propios medios';
          } else if (el.status === 3) {
            status = 'No entregado';
          } else if (el.status === 5) {
            status = 'Indicado';
          } else if (el.status === 6) {
            status = 'No indicado';
          } else {
            status = 'Otro';
          }
          return (
            <Tr key={el.id}>
              <Td>{isoToPy(el.deliveryDate)}</Td>
              <Td>{status}</Td>
              <Td>{el.comments === '' ? '-' : el.comments}</Td>
              <Td dataLabel={'Editar'} modifier="fitContent">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setSelected(el);
                    setPvValue(el.status);
                    setPvComments(el.comments);
                    handleModalToggle();
                  }}
                >
                  {'Editar'}
                </Button>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
      <AspirinModal
        isModalOpen={isModalOpen}
        handleModalToggle={handleModalToggle}
        onSubmit={onSubmit}
        sendingData={sendingData}
        selectedPatient={selectedPatient}
        pvValue={pvValue}
        pvComments={pvComments}
      />
    </TableComposable>
  );
};

export default AspirintHistory;
