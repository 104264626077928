import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  Select,
  SelectOption,
  SelectVariant,
  Spinner,
  TextInput,
} from '@patternfly/react-core';
import api from '../services/api';
import DepartmentSelect from './DepartmentSelect';

const CitySelect = ({ value, onChange, id }) => {
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [newCity, setNewCity] = useState('');
  const [newDepartment, setNewDepartment] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch all cities from api
  useEffect(() => {
    api.cities.getMulti('name', '', 1000, 0).then((data) => {
      const _cities = data.results.map((city) => ({
        id: city.id,
        toString: () => city.fullName,
      }));
      setCities(_cities);
      if (value) {
        setCity(_cities.find((el) => el.id === value) || null);
      }
    });
  }, [value]);

  function toTitleCase(str) {
    /**
     * Converts string to 'Title Case'
     */
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const onCreateOption = (newCity) => {
    setNewCity(toTitleCase(newCity));
    setIsModalOpen(true);
  };

  const onSelectChange = (e, v) => {
    onChange(v.id);
    setIsOpen(false);
  };

  const onCreateCity = (e) => {
    setLoading(true);
    api.cities.create({ name: newCity, department: newDepartment }).then((res) => {
      setNewCity('');
      setNewDepartment(null);
      onChange(res.id);
      setLoading(false);
      setIsModalOpen(false);
    });
  };

  return (
    <>
      <Select
        id={id}
        variant={SelectVariant.typeahead}
        isOpen={isOpen}
        onToggle={() => setIsOpen(!isOpen)}
        selections={city}
        onSelect={onSelectChange}
        isCreatable
        onCreateOption={onCreateOption}
        placeholderText="Ciudad"
        createText="Crear"
        maxHeight="200px"
      >
        <SelectOption key={-1} value={{ id: '', toString: () => 'Ninguno' }} isPlaceholder />
        {cities.map((c) => (
          <SelectOption key={c.id} value={c} />
        ))}
      </Select>
      <Modal
        variant={ModalVariant.small}
        title="Agregar Ciudad"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Form onSubmit={onCreateCity}>
          <FormGroup label="Nombre de la Ciudad" fieldId="newCity">
            <TextInput id="newCity" value={newCity} onChange={setNewCity} />
          </FormGroup>
          <FormGroup fieldId="department" label="Departamento">
            <DepartmentSelect value={newDepartment} onChange={setNewDepartment} />
          </FormGroup>
          {loading && <Spinner />}
          {!loading && <Button onClick={onCreateCity}>Agregar</Button>}
        </Form>
      </Modal>
    </>
  );
};

CitySelect.defaultProps = {
  id: '',
};

export default CitySelect;
