import {
  Alert,
  AlertActionCloseButton,
  Bullseye,
  DatePicker,
  Form,
  FormGroup,
  Grid,
  PageSection,
  PageSectionVariants,
  Spinner,
  TextInput,
  Title,
  Radio,
} from '@patternfly/react-core';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import CitySelect from '../../components/CitySelect';
import FormButtons from '../../components/FormButtons';
import { usePatient } from '../../context/patientContext';
import {
  dateFormat,
  dateRangeValidator,
  dateParse,
  dateSubmitParser,
  titleCase,
  dateFormatter,
  dateRangeAndAgeValidator,
} from '../../utils';

const PatientForm = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const { patient, fetchPatient, createPatient, updatePatient } = usePatient();
  const [loading, setLoading] = useState(!!id);
  const [firstName, setFirstName] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState('default');
  const [lastName, setLastName] = useState('');
  const [isLastNameValid, setIsLastNameValid] = useState('default');
  const [govId, setGovId] = useState('');
  const [isGovIdValid, setIsGovIdValid] = useState('default');
  const [birthdate, setBirthdate] = useState('');
  const [isBirthdateValid, setIsBirthdateValid] = useState('default');
  const [cityOfResidence, setCityOfResidence] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [telephone, setTelephone] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [firstPeriod, setFirstPeriod] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [gender, setGender] = useState(null);
  // fetch patient from db
  useEffect(() => {
    if (patient) {
      setFirstName(patient.firstName);
      setLastName(patient.lastName);
      setGovId(patient.govId);
      if (patient.birthdate) {
        const split = patient.birthdate.split('-');
        setBirthdate(`${split[2]}/${split[1]}/${split[0]}`);
      }
      setCityOfResidence(patient.cityOfResidence?.id || null);
      setNeighborhood(patient.neighborhood);
      setTelephone(patient.telephone);
      setCellphone(patient.cellphone);
      setFirstPeriod(patient.firstPeriod || '');
      setGender(patient.gender);
      setLoading(false);
    } else if (id) {
      fetchPatient(id);
    }
  }, [id, patient, fetchPatient]);

  // generate internal code
  useEffect(() => {
    let code = '';
    code += firstName
      .split(' ')
      .map((el) => el[0])
      .join('');
    code += lastName
      .split(' ')
      .map((el) => el[0])
      .join('');
    code += birthdate.split('/').join('');
    setGeneratedCode(code);
  }, [firstName, lastName, birthdate]);

  // generate breadbrumbs
  let breadcrumbs;
  if (id) {
    breadcrumbs = [
      { link: '/patients', title: 'Pacientes' },
      { link: `/patients/${id}`, title: `${patient?.firstName} ${patient?.lastName}` },
      { link: `/patients/${id}/data`, title: 'Datos de la Paciente' },
    ];
  } else {
    breadcrumbs = [
      { link: '/patients', title: 'Pacientes' },
      { link: `/patients/new`, title: 'Nueva paciente' },
    ];
  }

  const isSubmitDisabled = () => {
    if (dateRangeValidator(dateParse(birthdate))) return true;
    return firstName.length < 2 || lastName.length < 2 || govId.length < 4 || !birthdate;
  };

  const onGovIdChange = (v) => {
    const re = /[0-9\-a-zA-Z]+/gm;
    setGovId(v.match(re).join(''));
  };

  const numberRegex = /[0-9]/gm;

  const onTelephoneChange = (v) => {
    setTelephone(v.match(numberRegex).join(''));
  };

  const onCellphoneChange = (v) => {
    setCellphone(v.match(numberRegex).join(''));
  };

  const onBlur = (e) => {
    const v = e.target.value;
    switch (e.target.name) {
      case 'firstName':
        setFirstName(v.trim());
        setIsFirstNameValid(v.length >= 2 ? 'default' : 'error');
        break;
      case 'lastName':
        setLastName(v.trim());
        setIsLastNameValid(v.length >= 2 ? 'default' : 'error');
        break;
      case 'govId':
        setGovId(v.trim());
        setIsGovIdValid(v.length >= 4 ? 'default' : 'error');
        break;
      case 'birthdate':
        setIsBirthdateValid(new Date(v) < new Date() ? 'default' : 'error');
        break;

      default:
        break;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      firstName,
      lastName,
      internalId: generatedCode,
      govId: govId ? govId : generatedCode,
      birthdate: dateSubmitParser(birthdate),
      cityOfResidence,
      neighborhood,
      telephone,
      cellphone,
      gender,
    };
    if (firstPeriod) data['firstPeriod'] = firstPeriod;
    if (id) {
      updatePatient(id, data, false, params.get('confirm') || false)
        .then((p) => {
          fetchPatient(patient.id);
          navigate(`/patients/${id}`);
        })
        .catch((e) => {
          if (e.govId && e.govId[0].includes('existe')) {
            setErrorMessage(e.govId[0]);
          }
          console.error(e);
        });
    } else {
      createPatient(data)
        .then((res) => {
          navigate(`/patients/${res.id}`);
        })
        .catch((e) => {
          if (e.govId && e.govId[0].includes('existe')) {
            setErrorMessage(e.govId[0]);
          }
          console.error(e);
        });
    }
  };

  if (loading) {
    return (
      <>
        <Breadcrumbs links={breadcrumbs} />
        <Bullseye>
          <Spinner />
        </Bullseye>
      </>
    );
  }

  return (
    <PageSection>
      <Breadcrumbs links={breadcrumbs} />
      <PageSection variant={PageSectionVariants.light}>
        {errorMessage && (
          <Alert
            variant="danger"
            title={errorMessage}
            actionClose={<AlertActionCloseButton onClose={() => setErrorMessage('')} />}
          />
        )}
        {params.get('confirm') && (
          <Alert style={{ marginBottom: '1.5rem' }} variant="warning" title="Favor confirmar datos de la paciente" />
        )}
        <Title headingLevel="h2">Datos de la paciente</Title>
        <Form onSubmit={onSubmit}>
          <Grid md={6} hasGutter>
            <FormGroup
              label="Nombres"
              isRequired
              fieldId="firstName"
              validated={isFirstNameValid}
              helperTextInvalid="Debe tener al menos 2 caracteres."
            >
              <TextInput
                id="firstName"
                isRequired
                name="firstName"
                onChange={(v) => setFirstName(titleCase(v))}
                onBlur={onBlur}
                value={firstName}
                validated={isFirstNameValid}
              />
            </FormGroup>
            <FormGroup
              label="Apellidos"
              isRequired
              fieldId="lastName"
              validated={isLastNameValid}
              helperTextInvalid="Debe tener al menos 2 caracteres."
            >
              <TextInput
                id="lastName"
                isRequired
                name="lastName"
                onChange={(v) => setLastName(titleCase(v))}
                onBlur={onBlur}
                value={lastName}
                validated={isLastNameValid}
              />
            </FormGroup>
            <FormGroup
              label="Fecha de nacimiento"
              isRequired
              fieldId="birthdate"
              validated={isBirthdateValid}
              helperTextInvalid="La fecha no puede ser posterior a hoy."
            >
              <DatePicker
                dateFormat={dateFormat}
                dateParse={dateParse}
                placeholder="dd/mm/aaaa"
                locale="es-PY"
                name="birthdate"
                validators={[dateRangeAndAgeValidator]}
                onChange={(v) => setBirthdate(dateFormatter(v))}
                value={birthdate}
                invalidFormatText="Formato de fecha inválido. El formato debe ser dd/mm/aaaa"
              />
            </FormGroup>
            <FormGroup
              fieldId="internalCode"
              label="Código Interno"
              validated="warning"
              helperText='Copiar a "Nro . de Documento" en caso de que la paciente no cuente con CI.'
            >
              {generatedCode}
            </FormGroup>
            <FormGroup
              label="Nro. de Documento"
              isRequired
              fieldId="govId"
              validated={isGovIdValid}
              helperTextInvalid="Debe tener almenos 4 dígitos."
            >
              <TextInput
                id="govId"
                isRequired
                name="govId"
                onChange={onGovIdChange}
                onBlur={onBlur}
                value={govId}
                validated={isGovIdValid}
              />
            </FormGroup>
            <FormGroup fieldId="gender" label="Sexo">
              <Radio id="male" name="gender" label="Masculino" onChange={() => setGender(0)} isChecked={gender === 0} />
              <Radio
                id="female"
                name="gender"
                label="Femenino"
                onChange={() => setGender(1)}
                isChecked={gender === 1}
              />
            </FormGroup>
            <FormGroup fieldId="city" label="Ciudad de residencia" isRequired>
              <CitySelect id="city" value={cityOfResidence} onChange={setCityOfResidence} />
            </FormGroup>
            <FormGroup fieldId="neighborhood" label="Barrio">
              <TextInput
                id="neighborhood"
                isRequired
                name="neighborhood"
                onChange={setNeighborhood}
                onBlur={onBlur}
                value={neighborhood}
              />
            </FormGroup>
            <FormGroup fieldId="telephone" label="Teléfono">
              <TextInput
                id="telephone"
                name="telephone"
                onChange={onTelephoneChange}
                onBlur={onBlur}
                value={telephone}
              />
            </FormGroup>
            <FormGroup fieldId="cellphone" label="Celular">
              <TextInput
                id="cellphone"
                name="cellphone"
                onChange={onCellphoneChange}
                onBlur={onBlur}
                value={cellphone}
              />
            </FormGroup>
          </Grid>
          <FormButtons isSubmitDisabled={isSubmitDisabled} />
        </Form>
      </PageSection>
    </PageSection>
  );
};

export default PatientForm;
