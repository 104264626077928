import { Breadcrumb, BreadcrumbItem } from '@patternfly/react-core';
import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ links }) => {
  return (
    <Breadcrumb style={{ marginBottom: '1rem' }}>
      {links.map((el, i) => {
        // this is the last link
        if (i === links.length - 1) {
          return (
            <BreadcrumbItem to="#" isActive key={i}>
              {el.title}
            </BreadcrumbItem>
          );
        // render link to previous component
        } else {
          return (
            <BreadcrumbItem key={i}>
              <Link to={el.link}>{el.title}</Link>
            </BreadcrumbItem>
          );
        }
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
