import React from 'react';
import {
  Title,
  EmptyState as PFEmptyState,
  EmptyStateIcon,
  EmptyStateBody,
} from '@patternfly/react-core';

const EmptyState = ({icon}) => (
  <PFEmptyState>
    <EmptyStateIcon icon={icon} />
    <Title headingLevel="h4" size="lg">
      Pacientes
    </Title>
    <EmptyStateBody>
      Utilice el cuadro de búsqueda para mostrar una lista de resultados.<br />Puede buscar por nro. de documento o nombre y apellido.
    </EmptyStateBody>
  </PFEmptyState>
)

export default EmptyState;
