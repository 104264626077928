import {
  Bullseye,
  Button,
  Flex,
  FlexItem,
  InputGroup,
  Spinner,
  TextInput,
  Toolbar,
  ToolbarContent,
} from '@patternfly/react-core';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../../services/api';
import EntityTable from '../../components/EntityTable';
import Pagination from '../../components/Pagination';
import EmptyState from '../../components/EmptyState';
import { UsersIcon } from '@patternfly/react-icons';

const PatientList = () => {
  const { search: searchParams } = useLocation();
  const queryParams = new URLSearchParams(searchParams);
  const [search, setSearch] = useState(queryParams.get('search') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [limit, setLimit] = useState(queryParams.get('limit') || 50);
  const [offset, setOffset] = useState(queryParams.get('offset') || 0);
  const [count, setCount] = useState(0);

  const applyFilters = () => {
    if (search.length >= 3) {
      setIsLoading(true);
      api.patients.getMulti(null, search, limit, offset).then((response) => {
        setCount(response.count);
        setEntityList(response.results);
        setIsLoading(false);
      });
    } else {
      setEntityList([]);
      setIsLoading(false);
    }
  };

  const onFilterSubmit = (e) => {
    e.preventDefault();
    setOffset(0);
    applyFilters();
  };

  const onDelete = (id) => {
    setIsLoading(true);
    api.patients.delete(id).then(() => {
      api.patients.getMulti(null, search, limit, offset).then((response) => {
        setCount(response.count);
        setEntityList(response.results);
        setIsLoading(false);
      });
    });
  };

  const toolbarItems = (
    <form onSubmit={onFilterSubmit} style={{ width: '100%' }}>
      <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
        <Flex alignItems={{ default: 'alignItemsCenter' }}>
          <FlexItem>
            <InputGroup>
              <TextInput
                name="search"
                id="search"
                type="search"
                value={search}
                onChange={setSearch}
                placeholder="Nombre o CI"
              />
            </InputGroup>
          </FlexItem>
          <FlexItem variant="separator" />
          <FlexItem>
            <Button variant="primary" type="submit" isDisabled={search.length < 3}>
              Buscar
            </Button>
          </FlexItem>
        </Flex>
        <Button component={Link} to={`/patients/new`} variant="secondary">
          Crear Paciente
        </Button>
      </Flex>
    </form>
  );

  const columns = { id: 'ID', firstName: 'Nombres', lastName: 'Apellidos', govId: 'Nro. Doc.' };
  const rows = entityList.map((patient) => ({
    id: patient.id,
    firstName: patient.firstName,
    lastName: patient.lastName,
    govId: patient.govId,
  }));

  return (
    <>
      <Toolbar>
        <ToolbarContent>{toolbarItems}</ToolbarContent>
      </Toolbar>
      {isLoading && (
        <Bullseye>
          <Spinner isSVG />
        </Bullseye>
      )}
      {!isLoading && <EntityTable columns={columns} rows={rows} entity="patients" onDelete={onDelete} />}
      {entityList.length === 0 && !isLoading && <EmptyState icon={UsersIcon} />}
      <Pagination pageCount={count} limit={limit} offset={offset} onLimitChange={setLimit} onOffsetChange={setOffset} />
    </>
  );
};

export default PatientList;
