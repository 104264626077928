import {
  Bullseye,
  Button,
  Checkbox,
  Flex,
  FlexItem,
  InputGroup,
  PageSection,
  Pagination,
  PaginationVariant,
  Spinner,
  TextInput,
  Toolbar,
  ToolbarContent,
} from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import EntityTable from '../../components/EntityTable';
import InstitutionSelect from '../../components/InstitutionSelect';

const UserList = () => {
  const [search, setSearch] = useState('');
  const [isStaff, setIsStaff] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [institution, setInstitution] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  function fetchUsers(ordering = null, search = '', limit = 50, offset = 0, extra = {}) {
    setIsLoading(true);
    api.users.getMulti(ordering, search, limit, offset, extra).then((response) => {
      setCount(response.count);
      setUsers(response.results);
      setIsLoading(false);
    });
  }

  useEffect(fetchUsers, []);

  const applyFilters = (e) => {
    e.preventDefault();
    fetchUsers(null, search, limit, offset, {
      isStaff: isStaff === true ? true : null,
      hospital: institution > 0 ? institution : '',
    });
  };

  const toolbarItems = (
    <form onSubmit={applyFilters} style={{ width: '100%' }}>
      <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
        <Flex alignItems={{ default: 'alignItemsCenter' }}>
          <FlexItem>
            <InputGroup>
              <TextInput
                name="search"
                id="search"
                type="search"
                value={search}
                onChange={setSearch}
                placeholder="Nombre o Usuario"
              />
            </InputGroup>
          </FlexItem>
          <FlexItem>
            <InstitutionSelect
              onChange={(e) => {
                setInstitution(e);
                setOffset(0);
              }}
              value={institution}
            />
          </FlexItem>
          <FlexItem>
            <Checkbox
              label="Es Staff"
              isChecked={isStaff}
              onChange={() => setIsStaff(!isStaff)}
              name="isStaff"
              id="isStaff"
            />
          </FlexItem>
          <FlexItem variant="separator" />
          <FlexItem>
            <Button variant="primary" type="submit">
              Filtrar
            </Button>
          </FlexItem>
        </Flex>
        <Button component={Link} to={`/users/new`} variant="secondary">
          Crear Usuario
        </Button>
      </Flex>
    </form>
  );

  const columns = {
    id: 'ID',
    username: 'Nombre de usuario',
    firstName: 'Nombres',
    lastName: 'Apellidos',
    hospital: 'Hospital',
    isStaff: 'Es staff de FJRE',
  };
  const rows = users.map((user) => ({
    id: user.id,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    hospital: user.hospital?.name,
    isStaff: user.isStaff,
  }));

  const onSetPage = (_event, pageNumber) => {
    setOffset((pageNumber - 1) * limit);
    setCurrentPage(pageNumber);
    fetchUsers(null, search, limit, (pageNumber - 1) * limit, {
      isStaff: isStaff === true ? true : null,
      hospital: institution > 0 ? institution : '',
    });
  };

  const onPerPageSelect = (_event, perPage) => {
    setCurrentPage(offset / perPage + 1);
    setLimit(perPage);
    fetchUsers(null, search, perPage, offset, {
      isStaff: isStaff === true ? true : null,
      hospital: institution > 0 ? institution : '',
    });
  };

  const onDelete = (id) => {
    api.users.delete(id).then(() => {
      const newUsers = users.filter((u) => u.id !== id);
      setUsers(newUsers);
    });
  };

  return (
    <PageSection>
      <Toolbar>
        <ToolbarContent>{toolbarItems}</ToolbarContent>
      </Toolbar>
      {isLoading && (
        <Bullseye>
          <Spinner isSVG />
        </Bullseye>
      )}
      {!isLoading && <EntityTable columns={columns} rows={rows} entity="users" onDelete={onDelete} />}
      <Pagination
        itemCount={count}
        widgetId="pagination-options-menu-bottom"
        perPage={limit}
        page={currentPage}
        variant={PaginationVariant.bottom}
        onSetPage={onSetPage}
        onPerPageSelect={onPerPageSelect}
      />
    </PageSection>
  );
};

export default UserList;
