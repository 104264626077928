/**
 * Formats a given date into Paraguay format dd/mm/aaaa
 * @param date input date
 * @returns {string|string}
 */
const dateFormat = (date) =>
  date ? date.toLocaleDateString('es-PY', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';

/**
 * parses a date string in dd/mm/aaaa format into a JS Date object
 * @param date {String} date string to be parsed
 * @param allowEmpty {boolean} if true, invalid date string returns empty string, else returns null.
 * @returns {Date|string}
 */
const dateParse = (date, allowEmpty = false) => {
  const split = date.split('/');
  if (split.length !== 3) {
    return allowEmpty ? null : '';
  }
  let day = split[0];
  let month = split[1];
  let year = split[2];
  return new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
};

/**
 * Converts ISO date string into dd/mm/aaaa format.
 * @param dateStr {String} date string to be converted
 */
const isoToPy = (dateStr) => {
  if (dateStr) {
    const split = dateStr.split('-');
    return `${split[2]}/${split[1]}/${split[0]}`;
  }
};

/**
 * Validates that a given date is in between a certain range.
 * @param date {Date} date to be validated.
 * @param fromDate {Date} min. possible date value.
 * @param toDate {Date} max. possible date value.
 */
const dateRangeValidator = (date, fromDate = new Date(1900, 11, 31), toDate = new Date()) => {
  if (!date) return;
  if (
    date.getFullYear() < fromDate.getFullYear() ||
    date.getMonth() > fromDate.getMonth() ||
    date.getDate() > fromDate.getDate()
  )
    return 'Formato de fecha inválido. El formato debe ser dd/mm/aaaa';
  if (date > toDate) {
    return 'La fecha no puede ser superior a hoy.';
  }
};

/**
 * Converts date from dd/mm/aaaa format to ISO format.
 * @param date {String} date string to be converted
 * @returns {String}
 */
const dateSubmitParser = (date) => {
  const split = date.split('/');
  if (split.length !== 3) {
    return null;
  }
  let day = split[0];
  let month = split[1];
  let year = split[2];
  return `${year}-${month}-${day}`;
};

/**
 * @typedef {Object} WeeksDays
 * @property {Number} weeks
 * @property {Number} days
 */

/**
 * Determines the number of weeks and days passed from a given date.
 * @param date {Date} date from which to calculate weeks and days.
 * @param today {Date} date to which to calculate weeks and days.
 * @returns {WeeksDays} Object containing `weeks` and `days` values.
 */
const weekDayDifference = (date, today = new Date()) => {
  const weeks = Math.floor((today - date) / (86400000 * 7));
  const days = Math.floor(((today - date) % (7 * 86400000)) / 86400000);
  return { weeks, days };
};

/**
 * Determines the start date of a pregnancy using ecography data.
 * @param weeks {Number} weeks pregnant at moment of ecography
 * @param days {Number} days pregnant at moment of ecography
 * @param date {Date} date of ecography
 * @returns {Date} pregnancy start date
 */
const getPregnancyStart = (weeks, days, date = new Date()) => {
  return new Date(date - (weeks * 86400000 * 7 + days * 86400000));
};

/**
 * Converts `str` into "Title Case"
 * @param str {String} string to convert
 * @returns {String} converted string
 */
function titleCase(str) {
  const s = str.toLowerCase().split(' ');
  for (var i = 0; i < s.length; i++) {
    s[i] = s[i].charAt(0).toUpperCase() + s[i].slice(1);
  }
  return s.join(' ');
}

function fileDownload(data, filename, mime, bom) {
  var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
}

/**
 * Adds slashes to given date
 * @param dateStr {String} date to be formatted
 */
function dateFormatter(dateStr) {
  if (dateStr.length === 2 || dateStr.length === 5) {
    return `${dateStr}/`;
  }
  return dateStr;
}

const dateRangeAndAgeValidator = (date, fromDate = new Date(1900, 11, 31), toDate = new Date()) => {
  if (!date) return;
  if (
    date.getFullYear() < fromDate.getFullYear() ||
    date.getMonth() > fromDate.getMonth() ||
    date.getDate() > fromDate.getDate()
  )
    return 'Formato de fecha inválido. El formato debe ser dd/mm/aaaa';
  if (date > toDate) {
    return 'La fecha no puede ser superior a hoy.';
  }
  if (toDate.getFullYear() - date.getFullYear() < 10) return 'La edad mínima es de 10 años';
};

function differenceInMonths(date1, date2) {
  const monthDiff = date1.getMonth() - date2.getMonth();
  const yearDiff = date1.getYear() - date2.getYear();

  return monthDiff + yearDiff * 12;
}

const dateRangeAndMonthValidator = (date, fromDate = new Date(1900, 11, 31), toDate = new Date()) => {
  if (!date) return;
  if (
    date.getFullYear() < fromDate.getFullYear() ||
    date.getMonth() > fromDate.getMonth() ||
    date.getDate() > fromDate.getDate()
  )
    return 'Formato de fecha inválido. El formato debe ser dd/mm/aaaa';
  if (date > toDate) {
    return 'La fecha no puede ser superior a hoy.';
  }
  if (differenceInMonths(toDate, date) > 9) return 'La fecha mínima es de 9 meses atrás';
};

export {
  dateFormat,
  dateParse,
  dateRangeValidator,
  dateSubmitParser,
  weekDayDifference,
  getPregnancyStart,
  titleCase,
  isoToPy,
  fileDownload,
  dateFormatter,
  dateRangeAndAgeValidator,
  dateRangeAndMonthValidator,
};
