import { useState } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';
import MainNav from './components/MainNav';
import { Page, PageHeader, PageHeaderTools, PageSection, PageSidebar } from '@patternfly/react-core';
import UserMenuDropdown from './components/UserMenuDropdown';
import Login from './pages/Login';
import CityList from './pages/cities/CityList';
import CityForm from './pages/cities/CityForm';
import InstitutionList from './pages/institutions/InstitutionList';
import InstitutionForm from './pages/institutions/InstitutionForm';
import UserList from './pages/Users/UserList';
import UserForm from './pages/Users/UserForm';
import Reporting from './pages/Reporting';
import PatientList from './pages/patients/PatientList';
import PatientBase from './pages/patients/PatientBase';
import PreeclampsiaReport from './pages/reporting/PreeclampsiaReport';
import NangarekoReport from './pages/reporting/NangarekoReport';
import PregnantPatientList from './pages/patients/PregnantPatientList';
import SatisfactionSurverReport from './pages/reporting/SatisfactionSurveyReport';

const App = () => {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const auth = useAuth();

  const Header = (
    <PageHeader
      logo={'Sistema Hospitalario'}
      logoComponent="h2"
      headerTools={
        <PageHeaderTools>
          <UserMenuDropdown />
        </PageHeaderTools>
      }
      showNavToggle
      isNavOpen={isNavOpen}
      onNavToggle={() => setIsNavOpen(!isNavOpen)}
    />
  );

  const toggleSidebar = () => {
    if (document.body.clientWidth < 1200) setIsNavOpen(!isNavOpen);
  };

  const Sidebar = <PageSidebar nav={<MainNav onItemClick={toggleSidebar} />} isNavOpen={isNavOpen} />;

  const authenticatedRoutes = (
    <Page header={Header} sidebar={Sidebar}>
      <Routes>
        <Route path="/cities" element={<CityList />} />
        <Route path="/cities/new" element={<CityForm />} />
        <Route path="/cities/:id" element={<CityForm />} />
        <Route path="/hospitals" element={<InstitutionList />} />
        <Route path="/hospitals/new" element={<InstitutionForm />} />
        <Route path="/hospitals/:id" element={<InstitutionForm />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/users/new" element={<UserForm />} />
        <Route path="/users/:id" element={<UserForm />} />
        <Route path="/reporting" element={<Reporting />} />
        <Route path="/reporting/preeclampsia" element={<PreeclampsiaReport />} />
        <Route path="/reporting/nangareko" element={<NangarekoReport />} />
        <Route path="/reporting/satisfaction-survey" element={<SatisfactionSurverReport />} />
        <Route path="/patients" element={<PatientList />} />
        <Route path="/patients/*" element={<PatientBase />} />
        <Route path="/pregnant-patients" element={<PregnantPatientList />} />
        <Route path="*" element={<Navigate to="/patients" />} />
      </Routes>
    </Page>
  );

  return (
    <Router>
      {auth.isAuthenticated ? (
        authenticatedRoutes
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
