import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
  Button,
  ButtonVariant,
  DatePicker,
  Form,
  FormGroup,
  FormSection,
  Grid,
  Modal,
  ModalVariant,
  NumberInput,
  PageSection,
  PageSectionVariants,
  TextArea,
  Title,
} from '@patternfly/react-core';
import RiskFactors from './components/RiskFactors';
import BloodPressure from './components/BloodPressure';
import Medications from './components/Medications';
import Analisis from './components/Analisis';
import { dateFormat, dateFormatter, dateParse, dateRangeValidator, dateSubmitParser, isoToPy } from '../../utils';
import { usePatient } from '../../context/patientContext';
import GestationalAge from './components/GestationalAge';
import FormButtons from '../../components/FormButtons';
import Loading from '../../components/Loading';
import { calculateRisk } from './utils';
import { PREGNANCY_FIRST_VISIT, PREGNANCY_FOLLOW_UP } from '../../constants';
import api from '../../services/api';
import { useAuth } from '../../context/authContext';

const PregnancyConsultationForm = () => {
  let { id, consultationType, consultationId } = useParams();
  let { patient, fetchPatient } = usePatient();
  const [consultation, setConsultation] = useState(null);
  // const [_consultationType, setConsultationType] = useState(consultationType);
  let navigate = useNavigate();
  const [consultationDate, setConsultationDate] = useState(dateFormat(new Date()));
  const [weight, setWeight] = useState({
    value: '',
    validated: 'general',
    helperTextInvalid: '',
  });
  const [height, setHeight] = useState({
    value: '',
    validated: 'general',
    helperTextInvalid: '',
  });
  const [imc, setImc] = useState('');
  const [lastMenstruation, setLastMenstruation] = useState('');
  const [ecographyData, setEcographyData] = useState({
    ecographyDate: '',
    weeksOnEcography: '',
    daysOnEcography: '',
  });
  const [calculatedAge, setCalculatedAge] = useState({ weeks: '', days: '' });
  // disable risk factors change when consultationType or consultation.type is PREGNANCY_FOLLOW_UP
  const [riskFactorsDisabled, setRiskFactorsDisabled] = useState(parseInt(consultationType) === PREGNANCY_FOLLOW_UP);
  const [riskFactors, setRiskFactors] = useState({
    hypertensionPreviousPregnancies: false,
    chronicHypertension: false,
    diabetes: false,
    autoimmune: false,
    renal: false,
    obesity: false,
    firstPregnancy: false,
    tooDistantPregnancy: false,
    multiplePregnancy: false,
    familyPreeclampsia: false,
    oldAge: false,
  });
  const [bloodPressure, setBloodPressure] = useState({
    systolicFirstTake: '',
    diastolicFirstTake: '',
    systolicSecondTake: '',
    diastolicSecondTake: '',
  });
  const [proteinuria, setProteinuria] = useState('');
  const [aspirinData, setAspirinData] = useState({
    value: 0,
    validated: 'general',
    comments: '',
    isDisabled: false,
  });
  const [comments, setComments] = useState('');
  const [loadingPatient, setLoadingPatient] = useState(true);
  const [loadingConsultation, setLoadingConsultation] = useState(true);
  const [modal, setModal] = useState({
    isOpen: false,
    body: '',
    title: '',
    shown: false,
    messageType: '',
  });
  const [errors, setErrors] = useState([]);
  const [sendingData, setSendingData] = useState(false);
  const [mustCompleteAspirinDetails, setMustCompleteAspirinDetails] = useState(false);
  let auth = useAuth();
  const breadcrumbs = [
    { link: '/patients', title: 'Pacientes' },
    { link: `/patients/${id}`, title: `${patient?.firstName} ${patient?.lastName}` },
  ];

  if (consultationType) {
    breadcrumbs.push({ link: `/patients/${id}/new-consultation/${consultationType}`, title: 'Nueva consulta' });
  } else if (consultationId) {
    breadcrumbs.push(
      { link: `/patients/${id}/history`, title: 'Historial de consultas' },
      { link: `/patients/${id}/history/${consultationId}`, title: `Consulta #${consultationId}` }
    );
  }

  useEffect(() => {
    if (
      (parseInt(consultationType) === 2 &&
        parseInt(calculatedAge.weeks) > 16 &&
        (parseInt(aspirinData.value) === 1 || parseInt(aspirinData.value) === 5)) ||
      (parseInt(consultationType) === 3 &&
        parseInt(calculatedAge.weeks) > 16 &&
        patient.aspirinHistory.find((el) => parseInt(el.aspirinGiven) === 1 || parseInt(el.aspirinGiven) === 5) !==
          -1 &&
        (parseInt(aspirinData.value) === 1 || parseInt(aspirinData.value) === 5))
    ) {
      setMustCompleteAspirinDetails(true);
      setModal({
        title: 'ATENCIÓN',
        body: (
          <div className="high-risk">
            <p>ENTREGA DE ASPIRINAS PASADAS LAS 16 SEMANAS.</p>
            <p>COMPLETAR EL CAMPO DETALLES INDICANDO EL MOTIVO DE LA ENTREGA.</p>
          </div>
        ),
        isOpen: true,
      });
    } else if (
      calculateRisk(riskFactors) >= 2 &&
      (parseInt(aspirinData.value) === 3 || parseInt(aspirinData.value) === 6)
    ) {
      setModal({
        title: 'ATENCIÓN',
        body: (
          <div className="high-risk">
            <p>PACIENTE DE ALTO RIESGO SIN ENTREGA DE ASPIRINA.</p>
            <p>COMPLETAR EL CAMPO DETALLES INDICANDO EL MOTIVO.</p>
          </div>
        ),
        isOpen: true,
      });
    } else {
      setMustCompleteAspirinDetails(false);
    }
  }, [calculatedAge, aspirinData.value]);

  // fetch patient
  useEffect(() => {
    fetchPatient(id).then((p) => {
      setRiskFactors({
        hypertensionPreviousPregnancies: p.hypertensionPreviousPregnancies || false,
        chronicHypertension: p.chronicHypertension || false,
        diabetes: p.diabetes || false,
        autoimmune: p.autoimmune || false,
        renal: p.renal || false,
        obesity: p.activePregnancy?.obesity || false,
        firstPregnancy: p.activePregnancy?.firstPregnancy || false,
        tooDistantPregnancy: p.activePregnancy?.tooDistantPregnancy || false,
        multiplePregnancy: p.activePregnancy?.multiplePregnancy || false,
        familyPreeclampsia: p.familyPreeclampsia || false,
        oldAge: (p.age && p.age > 40) || false,
      });
      setLastMenstruation(isoToPy(p.activePregnancy?.lastMenstruation) || '');
      // Only set these values if it is a new consultation.
      if (!consultationId) {
        setEcographyData({
          ecographyDate: isoToPy(p.activePregnancy?.ecographyDate) || '',
          weeksOnEcography: p.activePregnancy?.weeksOnEcography || '',
          daysOnEcography: p.activePregnancy?.daysOnEcography || '',
        });
      }

      setLoadingPatient(false);
    });
  }, [id]);

  // fetch consultation
  useEffect(() => {
    if (consultationId) {
      api.consultations.getOne(consultationId).then((c) => {
        setConsultation(c);
        setConsultationDate(isoToPy(c.consultationDate));
        setWeight({ ...weight, value: c.weight || '' });
        setHeight({ ...height, value: c.height || '' });
        setEcographyData({
          ecographyDate: isoToPy(c.ecographyDate) || '',
          weeksOnEcography: c.weeksOnEcography || '',
          daysOnEcography: c.daysOnEcography === 0 ? 0 : c.daysOnEcography || '',
        });
        setBloodPressure({
          systolicFirstTake: c.systolicFirstTake || '',
          diastolicFirstTake: c.diastolicFirstTake || '',
          systolicSecondTake: c.systolicSecondTake || '',
          diastolicSecondTake: c.diastolicSecondTake || '',
        });
        setProteinuria(c.proteinuria != null ? c.proteinuria : '');
        if (c.aspirinDispense !== null) {
          setAspirinData({
            value: c.aspirinDispense.status,
            comments: c.aspirinDispense.comments,
          });
        }

        setComments(c.comments);
        setLoadingConsultation(false);
        setRiskFactorsDisabled(c.type === PREGNANCY_FOLLOW_UP);
      });
    } else {
      setLoadingConsultation(false);
    }
  }, [consultationId]);

  // calculate IMC
  useEffect(() => {
    if (weight.value > 0 && height.value > 0) {
      const heightInMeters = height.value / 100;
      setImc(Math.round((weight.value / heightInMeters ** 2 + Number.EPSILON) * 100) / 100);
    } else {
      setImc('');
    }
  }, [weight, height]);

  // set obesity dynamically based on IMC
  useEffect(() => {
    if (imc && imc >= 35) setRiskFactors({ ...riskFactors, obesity: true });
    else setRiskFactors({ ...riskFactors, obesity: false });
  }, [imc]);

  // show riskFactor modal when risk factor >= 2
  useEffect(() => {
    if (calculateRisk(riskFactors) >= 2) {
      if (!modal.shown)
        setModal({
          isOpen: true,
          title: 'ATENCIÓN',
          body: <div className="high-risk">PACIENTE CON ALTO RIESGO DE PREECLAMPSIA.</div>,
          messageType: 'riskFactor',
        });
    }
  }, [riskFactors]);

  // show proteinuria modal when >= 2
  useEffect(() => {
    if (proteinuria >= 2) {
      setModal({
        title: 'ATENCIÓN',
        body: <div className="high-risk">PROTEINURIA SIGNIFICATIVA</div>,
        isOpen: true,
        messageType: 'proteinuria',
      });
    }
  }, [proteinuria]);

  // disable aspirin if gestationalAge.weeks > 36
  useEffect(() => {
    setAspirinData({
      ...aspirinData,
      isDisabled: calculatedAge.weeks > 36,
      value: calculatedAge.weeks > 36 ? 3 : aspirinData.value,
    });
    if (calculateRisk(riskFactors) < 2) {
      setAspirinData({ ...aspirinData, isDisabled: true, value: auth.userData?.enableDeliverAspirin ? 3 : 6 });
    } else {
      setAspirinData({
        ...aspirinData,
        value: calculatedAge.weeks > 36 ? (auth.userData?.enableDeliverAspirin ? 3 : 6) : 0,
        isDisabled: calculatedAge.weeks > 36,
      });
    }
  }, [calculatedAge, riskFactors]);

  const filterNumbers = (v) => {
    const re = /[0-9.]+/gm;
    return parseFloat(v.toString().match(re).join('') || 0);
  };

  const onWeightChange = (e) => {
    if (e.target.value) setWeight({ ...weight, value: filterNumbers(e.target.value) });
    else setWeight({ ...weight, value: '' });
  };

  const onHeightChange = (e) => {
    if (e.target.value) {
      setHeight({ ...height, value: filterNumbers(e.target.value) });
    } else setHeight({ ...height, value: '' });
  };

  const onBloodPressureChange = (_bloodPressure) => {
    setBloodPressure(_bloodPressure);
    console.log(_bloodPressure);
    if (_bloodPressure.systolicSecondTake >= 140 || _bloodPressure.diastolicSecondTake >= 90) {
      setModal({
        title: 'ATENCIÓN',
        body: (
          <div className="high-risk">
            <p>PACIENTE CON PRESIÓN ALTA.</p>
            <p>REFERIR AL DOCTOR Y COMPLETAR EL CAMPO DE OBSERVACIONES CON LAS MEDIDAS QUE SE TOMARON.</p>
          </div>
        ),
        isOpen: true,
      });
    } else if (
      (_bloodPressure.systolicFirstTake >= 140 || _bloodPressure.diastolicFirstTake >= 90) &&
      !_bloodPressure.systolicSecondTake &&
      !_bloodPressure.diastolicSecondTake
    ) {
      setModal({
        title: 'ATENCIÓN',
        body: (
          <div className="high-risk">
            <p>PACIENTE CON PRESIÓN ALTA.</p>
            <p>MEDIR NUEVAMENTE EN 10 MIN.</p>
          </div>
        ),
        isOpen: true,
      });
    }
  };

  const onRiskFactorEdit = () => {
    setRiskFactorsDisabled(!riskFactorsDisabled);
  };

  const onWeightBlur = (e) => {
    setWeight({
      value: weight.value,
      validated: weight.value > 200 || weight.value < 20 ? 'error' : 'general',
      helperTextInvalid: weight.value > 200 || weight.value < 20 ? 'El valor es incorrecto.' : '',
    });
  };

  const onHeightBlur = (e) => {
    setHeight({
      value: height.value,
      validated: height.value > 250 || height.value < 70 ? 'error' : 'general',
      helperTextInvalid: height.value > 250 || height.value < 70 ? 'El valor es incorrecto.' : '',
    });
  };

  const onModalClose = () => {
    setModal({
      isOpen: false,
      shown: modal.messageType === 'riskFactor',
      messageType: '',
      body: '',
      title: '',
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSendingData(true);
    const data = {
      patient: patient.id,
      type: consultationType,
      consultationDate: dateSubmitParser(consultationDate),
      systolicFirstTake: bloodPressure.systolicFirstTake || null,
      diastolicFirstTake: bloodPressure.diastolicFirstTake || null,
      systolicSecondTake: bloodPressure.systolicSecondTake || null,
      diastolicSecondTake: bloodPressure.diastolicSecondTake || null,
      lastMenstruation: dateSubmitParser(lastMenstruation),
      ecographyDate: ecographyData.ecographyDate ? dateSubmitParser(ecographyData.ecographyDate) : null,
      weeksOnEcography: ecographyData.weeksOnEcography || null,
      daysOnEcography: ecographyData.daysOnEcography === 0 ? 0 : ecographyData.daysOnEcography || null,
      proteinuria: proteinuria >= 0 ? proteinuria : -1,
      aspirinDispense: {
        status: aspirinData.value,
        comments: aspirinData.comments,
      },
      ...riskFactors,
      comments,
    };
    if (parseInt(consultationType) === PREGNANCY_FIRST_VISIT || consultation?.type === PREGNANCY_FIRST_VISIT) {
      data['weight'] = weight.value;
      data['height'] = height.value;
    }

    if (consultation) {
      data['type'] = consultation.type;
      api.consultations.update(consultationId, data).then(() => {
        setSendingData(false);
        fetchPatient(patient.id);
        navigate(`/patients/${patient.id}/history`);
      });
    } else {
      api.consultations
        .create(data)
        .then(() => {
          setSendingData(false);
          fetchPatient(patient.id);
          navigate(`/patients/${patient.id}`);
        })
        .catch((e) => {
          setSendingData(false);
          console.error(e);
          if (e.data?.nonFieldErrors) {
            alert(e.data.nonFieldErrors.join('\n'));
          } else alert('Error al guardar los datos. Por favor intente de nuevo.');
        });
    }
  };

  const isSubmitDisabled = () => {
    if (!consultationDate) return true;
    if (parseInt(consultationType) === PREGNANCY_FIRST_VISIT || consultation?.type === PREGNANCY_FIRST_VISIT) {
      if (weight.value === '' || weight.validated !== 'general') return true;
      if (height.value === '' || weight.validated !== 'general') return true;
    }
    // if there is no calculated age, it means there's an error with those dates
    if (!calculatedAge) return true;
    if (!bloodPressure.systolicFirstTake) return true;
    if (!bloodPressure.diastolicFirstTake) return true;
    if (bloodPressure.systolicFirstTake > 140 && !bloodPressure.systolicSecondTake) return true;
    if (bloodPressure.diastolicFirstTake > 140 && !bloodPressure.diastolicSecondTake) return true;
    if (proteinuria === '') return true;
    if (aspirinData.value === 0) return true;
    if (!lastMenstruation && !ecographyData.ecographyDate) return true;
    if (mustCompleteAspirinDetails && aspirinData.comments === '') return true;
    if (
      (parseInt(aspirinData.value) === 3 || parseInt(aspirinData.value) === 6) &&
      aspirinData.comments === '' &&
      calculateRisk(riskFactors) >= 2
    )
      return true;
    if (
      ecographyData.ecographyDate !== '' &&
      ecographyData.weeksOnEcography !== '' &&
      ecographyData.daysOnEcography === ''
    )
      return true;
    return false;
  };

  if (loadingConsultation || loadingPatient) {
    return <Loading />;
  }

  return (
    <PageSection>
      <Breadcrumbs links={breadcrumbs} />
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1">NUEVA CONSULTA</Title>
        <Form onSubmit={onSubmit} style={{ marginTop: '1.5rem' }}>
          <FormGroup label="Fecha de consulta" fieldId="consultationDate">
            <DatePicker
              id="consultationDate"
              dateFormat={dateFormat}
              dateParse={dateParse}
              placeholder="dd/mm/aaaa"
              locale="es-PY"
              onChange={(value) => setConsultationDate(dateFormatter(value))}
              value={consultationDate}
              validators={[dateRangeValidator]}
              invalidFormatText="Formato de fecha inválido. El formato debe ser dd/mm/aaaa"
            />
          </FormGroup>
          <FormSection title="Datos generales">
            <Grid md={3}>
              <FormGroup fieldId="name" label="Nombre">
                {patient?.firstName} {patient?.lastName}
              </FormGroup>
              <FormGroup fieldId="govId" label="CI">
                {patient?.govId}
              </FormGroup>
              <FormGroup fieldId="age" label="Edad">
                {patient?.age} años
              </FormGroup>
              <FormGroup fieldId="preeclampsiaRisk" label="Riesgo de Preeclampsia">
                {patient?.riskFactorSum >= 2 && <span className="high-risk">ALTO</span>}
                {patient?.riskFactorSum < 2 && <span className="low-risk">BAJO</span>}
              </FormGroup>
            </Grid>
            {(consultationType === '2' || consultation?.type === 2) && (
              <Grid md={4} hasGutter>
                <FormGroup
                  label="Peso"
                  isRequired
                  fieldId="weight"
                  validated={weight.validated}
                  helperTextInvalid={weight.helperTextInvalid}
                  onBlur={onWeightBlur}
                >
                  <NumberInput
                    id="weight"
                    name="weight"
                    onChange={onWeightChange}
                    min={20}
                    value={weight.value}
                    unit="Kg"
                    widthChars={7}
                    minusBtnProps={{ style: { display: 'none' } }}
                    plusBtnProps={{ style: { display: 'none' } }}
                  />
                </FormGroup>
                <FormGroup
                  label="Altura"
                  isRequired
                  fieldId="height"
                  validated={height.validated}
                  helperTextInvalid="La alutra debe estar en cm."
                  onBlur={onHeightBlur}
                >
                  <NumberInput
                    id="height"
                    name="height"
                    onChange={onHeightChange}
                    min={0}
                    value={height.value}
                    unit="cm"
                    widthChars={3}
                    minusBtnProps={{ style: { display: 'none' } }}
                    plusBtnProps={{ style: { display: 'none' } }}
                  />
                </FormGroup>
                <FormGroup fieldId="imd" label="IMC">
                  <span id="imc">{imc}</span>
                </FormGroup>
              </Grid>
            )}
          </FormSection>
          <GestationalAge
            isRequired
            consultationDate={consultationDate}
            lastMenstruation={lastMenstruation}
            ecographyData={ecographyData}
            onLastMenstruationChange={setLastMenstruation}
            onEcographyDataChange={setEcographyData}
            onCalculatedAgeChange={setCalculatedAge}
          />
          <RiskFactors
            riskFactors={riskFactors}
            onRiskFactorsChange={setRiskFactors}
            isComponentDisabled={riskFactorsDisabled}
            onEditButtonClicked={onRiskFactorEdit}
            showEditButton={
              parseInt(consultationType) === PREGNANCY_FOLLOW_UP || consultation?.type === PREGNANCY_FOLLOW_UP
            }
          />
          <BloodPressure
            bloodPressure={bloodPressure}
            onBloodPressureChange={onBloodPressureChange}
            bloodPressureHistory={patient.bloodPressureHistory}
          />
          <Analisis proteinuria={proteinuria} onProteinuriaChange={(v) => setProteinuria(v)} />
          <Medications
            aspirinData={aspirinData}
            onAspirinDataChange={setAspirinData}
            aspirinHistory={patient.aspirinHistory}
          />
          <FormSection title="Observaciones generales">
            <FormGroup fieldId="comments">
              <TextArea id="comments" onChange={setComments} value={comments} />
            </FormGroup>
          </FormSection>
          <FormButtons isSubmitDisabled={isSubmitDisabled} sendingData={sendingData} />
        </Form>
      </PageSection>
      <Modal
        aria-label="popup"
        variant={ModalVariant.small}
        title={modal.title}
        isOpen={modal.isOpen}
        onClose={() => setModal({ ...modal, isOpen: false })}
        actions={[
          <Button key="confirm" variant={ButtonVariant.primary} onClick={onModalClose}>
            Aceptar
          </Button>,
        ]}
      >
        {modal.body}
      </Modal>
    </PageSection>
  );
};

export default PregnancyConsultationForm;
