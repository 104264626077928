import React, { useEffect, useState } from 'react';
import {
  Button,
  FormSelectOption,
  FormSelect,
  FormGroup,
  Modal,
  ModalVariant,
  TextArea,
  Form,
  HelperText,
  HelperTextItem,
} from '@patternfly/react-core';
import { useAuth } from '../context/authContext';

const AspirinModal = ({
  isModalOpen,
  handleModalToggle,
  onSubmit,
  sendingData,
  selectedPatient,
  pvValue,
  pvComments,
}) => {
  const [aspirinComments, setAspirinComments] = useState(pvValue || '');
  const [aspirinValue, setAspirinValue] = useState(pvComments || 0);
  let auth = useAuth();

  useEffect(() => {
    if (pvValue !== null) setAspirinValue(pvValue);
    if (pvComments !== null) setAspirinComments(pvComments);
  }, [isModalOpen]);

  return (
    <Modal
      variant={ModalVariant.small}
      title="Carga de aspirinas"
      isOpen={isModalOpen}
      onClose={() => {
        setAspirinValue(0);
        setAspirinComments('');
        handleModalToggle();
      }}
      showClose={false}
      actions={[
        <Button
          key="confirm"
          variant="primary"
          onClick={() => onSubmit(aspirinValue, aspirinComments)}
          isDisabled={aspirinValue === 0 || sendingData ? true : false}
        >
          Guardar
        </Button>,
        <Button
          key="cancel"
          variant="link"
          onClick={() => {
            setAspirinValue(0);
            setAspirinComments('');
            handleModalToggle();
          }}
        >
          Cancelar
        </Button>,
      ]}
    >
      <Form>
        {selectedPatient !== null ? (
          <HelperText>
            <HelperTextItem variant="warning">
              Está a punto de asignar aspirinas a{' '}
              <span style={{ textTransform: 'uppercase' }}>
                {selectedPatient.firstName} {selectedPatient.lastName}
              </span>
            </HelperTextItem>
          </HelperText>
        ) : null}
        <FormGroup label="AAS" fieldId="aspirin" isRequired>
          <FormSelect id="aspirin" value={aspirinValue} onChange={(v) => setAspirinValue(v)}>
            <FormSelectOption key="select" isPlaceholder value={0} label="Seleccionar" />
            {auth.userData?.enableDeliverAspirin ? (
              <>
                {' '}
                <FormSelectOption key={1} value={1} label="Entregado (especificar cantidad de cajas)" />
                <FormSelectOption key={3} value={3} label="No entregado" />
              </>
            ) : (
              <>
                <FormSelectOption key={5} value={5} label="Indicado" />
                <FormSelectOption key={6} value={6} label="No Indicado" />
              </>
            )}
          </FormSelect>
        </FormGroup>
        <FormGroup label={'Detalles'} fieldId="aspirinComments">
          <TextArea id="aspirincomments" onChange={(v) => setAspirinComments(v)} value={aspirinComments} />
        </FormGroup>
      </Form>
    </Modal>
  );
};

export default AspirinModal;
