import { Pagination as BasePagination, PaginationVariant } from '@patternfly/react-core';
import { useState } from 'react';
import { PAGINATION_TITLES } from '../constants';

const Pagination = ({ pageCount, limit, offset, onLimitChange, onOffsetChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onSetPage = (_event, pageNumber) => {
    onOffsetChange((pageNumber - 1) * limit);
    setCurrentPage(pageNumber);
  };

  const onPerPageSelect = (_event, perPage) => {
    setCurrentPage(offset / perPage + 1);
    onLimitChange(perPage);
  };

  return (
    <BasePagination
      itemCount={pageCount}
      widgetId="pagination-options-menu-bottom"
      perPage={limit}
      page={currentPage}
      variant={PaginationVariant.bottom}
      onSetPage={onSetPage}
      onPerPageSelect={onPerPageSelect}
      titles={PAGINATION_TITLES}
    />
  );
};

export default Pagination;
