import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActionGroup,
  Alert,
  AlertActionCloseButton,
  Bullseye,
  Button,
  Form,
  FormGroup,
  Grid,
  PageSection,
  Spinner,
  TextArea,
  TextInput,
  Title,
  Checkbox,
} from '@patternfly/react-core';
import CitySelect from '../../components/CitySelect';
import api from '../../services/api';
import Breadcrumbs from '../../components/Breadcrumbs';

const InstitutionForm = () => {
  let { id } = useParams();
  const [instance, setInstance] = useState(null);
  const [name, setName] = useState('');
  const [city, setCity] = useState(null);
  const [comments, setComments] = useState('');
  const [created, setCreated] = useState(false);
  const [availableForSurvey, setAvailableForSurvey] = useState(false);
  let navigate = useNavigate();

  const breadcrumbs = () => {
    return [
      { link: '/hospitals', title: 'Establecimientos' },
      {
        link: instance ? `/hospitals/${id}` : '/hospitals/new',
        title: instance ? instance.name : 'Nuevo Establecimiento',
      },
    ];
  };

  const fetchHospital = useCallback(() => {
    id &&
      api.hospitals.getOne(id).then((hospital) => {
        setName(hospital.name);
        if (hospital.city !== null) setCity(hospital.city.id);
        setComments(hospital.comments);
        setInstance(hospital);
        setAvailableForSurvey(hospital.availableForSurvey);
      });
  }, [id]);

  useEffect(fetchHospital, [id, fetchHospital]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    instance
      ? api.hospitals.update(id, { name, city, comments, availableForSurvey }).then(() => navigate('/hospitals'))
      : api.hospitals.create({ name, city, comments, availableForSurvey }).then((res) => {
          setCreated(true);
          navigate(`/hospitals/${res.id}`);
        });
  };

  if (instance || !id) {
    return (
      <PageSection>
        <Breadcrumbs links={breadcrumbs()} />
        <PageSection variant="light">
          {created && (
            <Alert
              variant="success"
              title="Establecimiento creado correctamente."
              actionClose={<AlertActionCloseButton onClose={() => setCreated(false)} />}
            />
          )}
          <Title headingLevel="h2">{id ? 'Editar' : 'Crear'} Establecimiento</Title>
          <Form onSubmit={onFormSubmit}>
            <Grid hasGutter md={6}>
              <FormGroup label="Nombre" isRequired fieldId="name">
                <TextInput id="name" isRequired name="name" onChange={setName} value={name} />
              </FormGroup>
              <FormGroup label="Ciudad" isRequired fieldId="city">
                <CitySelect onChange={setCity} value={city} />
              </FormGroup>
              <FormGroup label="Comentarios" fieldId={'comments'}>
                <TextArea id="comments" onChange={setComments} value={comments} />
              </FormGroup>

              <Checkbox
                label="¿Disponible para encuesta?"
                isChecked={availableForSurvey}
                onChange={() => setAvailableForSurvey(!availableForSurvey)}
                id="available"
                name="available"
              />
            </Grid>
            <ActionGroup>
              <Button variant="primary" type="submit" isDisabled={name.length < 3 || city === null}>
                Guardar
              </Button>
              <Button variant="link" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </ActionGroup>
          </Form>
        </PageSection>
      </PageSection>
    );
  } else {
    return (
      <Bullseye>
        <Spinner />
      </Bullseye>
    );
  }
};

export default InstitutionForm;
