import { createContext, useContext, useState } from 'react';
import api from '../services/api';

const PatientContext = createContext({});

const PatientProvider = ({ children }) => {
  const [patientId, setPatientId] = useState(null);
  const [patient, setPatient] = useState(null);

  const fetchPatient = async (id) => {
    if (patientId === id) return Promise.resolve(patient);
    const data = await api.patients.getOne(id);
    setPatient(data);
    return Promise.resolve(data);
  };

  const createPatient = async (data) => {
    try {
      const res = await api.patients.create(data);
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err.data);
    }
  };

  async function updatePatient(id, data, partial = false, isConfirmation = false) {
    /**
     * Updates patient data.
     * @param id {Number} ID of patient to be updated
     * @param data {Object} data to be updated
     * @param partial {boolean} Flag that indicates if a partial update is needed.
     * @params isConfirmation {boolean} tells the endpoint if the update is a data confirmation
     * @returns {Promise<Object>} updated patient instance
     */
    console.log(isConfirmation);
    try {
      const response = await api.patients.update(id, data, partial, isConfirmation);
      setPatient(response);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err.data);
    }
  }

  const deletePatient = async (id) => {
    const response = await api.patients.delete(id);
    if (response) {
      setPatient(null);
      setPatientId(null);
      return Promise.resolve(true);
    }
    return Promise.reject(false);
  };

  const context = {
    patient,
    fetchPatient,
    createPatient,
    updatePatient,
    deletePatient,
  };

  return <PatientContext.Provider value={context}>{children}</PatientContext.Provider>;
};

const PatientConsumer = ({ children }) => <PatientContext.Consumer>{children}</PatientContext.Consumer>;

const usePatient = () => useContext(PatientContext);

export { PatientProvider, PatientConsumer, usePatient };
export default PatientContext;
