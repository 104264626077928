import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api, { errorCodes } from '../../services/api';
import {
  ActionGroup,
  Bullseye,
  Button,
  Checkbox,
  Form,
  FormGroup,
  Grid,
  GridItem,
  PageSection,
  PageSectionVariants,
  Spinner,
  TextInput,
  Title,
  FormAlert,
  Alert,
} from '@patternfly/react-core';
import InstitutionSelect from '../../components/InstitutionSelect';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useAuth } from '../../context/authContext';

const UserForm = () => {
  let { id } = useParams();
  let { userData } = useAuth();
  let navigate = useNavigate();
  const [instance, setInstance] = useState(null);
  const [username, setUsername] = useState('');
  const [isUsernameValid, setIsUsernameValid] = useState('default');
  const [usernameHelperText, setUsernameHelperText] = useState('Debe tener al menos 4 caracteres.');
  const [firstName, setFirstName] = useState('');
  const [isFirstNameValid, setIsFirstNameValid] = useState('default');
  const [lastName, setLastName] = useState('');
  const [isLastNameValid, setIsLastNameValid] = useState('default');
  const [isActive, setIsActive] = useState(true);
  const [isStaff, setIsStaff] = useState(false);
  const [isIsStaffValid, setIsIsStaffValid] = useState('default');
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [isSuperuserValid, setIsSuperuserValid] = useState('default');
  const [licenseNo, setLicenseNo] = useState('');
  const [hospital, setHospital] = useState(null);
  const [isHospitalValid, setIsHospitalValid] = useState('default');
  const [isHospitalManager, setIsHospitalManager] = useState(false);
  const [enableNangareko, setEnableNangareko] = useState(false);
  const [enablePreeclampsia, setEnablePreeclampsia] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState('default');
  const [enableDeliverAspirin, setEnableDeliverAspirin] = useState(false);
  const breadcrumbs = () => {
    let title = 'Nuevo Usuario';
    if (instance) title = instance.firstName ? `${instance.firstName} ${instance.lastName}` : instance.username;
    return [
      { link: '/users', title: 'Usuarios' },
      {
        link: `/users/${id}`,
        title,
      },
    ];
  };

  useEffect(() => {
    id &&
      api.users.getOne(id).then((user) => {
        setInstance(user);
        setUsername(user.username);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setIsActive(user.isActive);
        setIsStaff(user.isStaff);
        setIsSuperuser(user.isSuperuser);
        setLicenseNo(user.licenseNo || '');
        setEnableNangareko(user.enableNangareko);
        setEnablePreeclampsia(user.enablePreeclampsia);
        if (user.hospital) setHospital(user.hospital.id);
        setIsHospitalManager(user.isHospitalManager);
        setEnableDeliverAspirin(user.enableDeliverAspirin);
      });
  }, [id]);

  useEffect(() => {
    setIsHospitalValid(!isStaff && hospital < 1 ? 'error' : 'default');
  }, [isStaff, hospital]);

  const processRequestError = (error) => {
    if (error.code === errorCodes.badRequest) {
      if (typeof error.data === 'object' && typeof error.data.username !== 'undefined') {
        setUsernameHelperText('El nombre de usuario ya existe.');
        setIsUsernameValid('error');
      }
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!isStaff && hospital === null) {
      setIsIsStaffValid('error');
      return;
    }
    const data = {
      username,
      firstName,
      lastName,
      isActive,
      isStaff,
      isSuperuser,
      enableNangareko,
      enablePreeclampsia,
      hospital: hospital > 0 ? hospital : null,
      isHospitalManager,
      enableDeliverAspirin,
    };
    if (licenseNo) data.licenseNo = licenseNo;
    if (password) data.password = password;
    if (instance) {
      api.users
        .update(id, data)
        .then(() => navigate('/users'))
        .catch(processRequestError);
    } else {
      api.users
        .create(data)
        .then(() => navigate('/users'))
        .catch(processRequestError);
    }
  };

  const onBlur = (field) => {
    switch (field) {
      case 'username':
        setUsernameHelperText('Debe tener al menos 4 caracteres.');
        setIsUsernameValid(username.length > 3 ? 'default' : 'error');
        break;
      case 'firstName':
        setIsFirstNameValid(firstName.length > 1 ? 'default' : 'error');
        break;
      case 'lastName':
        setIsLastNameValid(lastName.length > 1 ? 'default' : 'error');
        break;
      case 'password':
        setIsPasswordValid(password.length >= 8 ? 'default' : 'error');
        break;
      case 'hospital':
        break;
      default:
        break;
    }
  };

  const isSubmitDisabled = () => {
    if (
      isUsernameValid.includes('error') ||
      isPasswordValid.includes('error') ||
      isFirstNameValid.includes('error') ||
      isLastNameValid.includes('error') ||
      isIsStaffValid.includes('error') ||
      isHospitalValid.includes('error')
    )
      return true;
    if (!instance) if (!username || username.length < 3 || !password || password.length < 8) return true;
    if (!firstName || firstName.length < 2 || !lastName || lastName.length < 2) return true;
    return false;
  };

  if (instance || !id) {
    return (
      <PageSection>
        <Breadcrumbs links={breadcrumbs()} />
        <PageSection variant={PageSectionVariants.light}>
          {instance && <Title headingLevel="h2">Editar usuario: {instance.username}</Title>}
          {!instance && <Title headingLevel="h2">Crear usuario</Title>}
          <Form onSubmit={onFormSubmit}>
            {isIsStaffValid === 'error' && (
              <FormAlert>
                <Alert
                  variant="danger"
                  title="Debe seleccionar un hospital o marcar al usuario como staff."
                  aria-live="polite"
                  isInline
                />
              </FormAlert>
            )}
            <Grid md={6} hasGutter>
              <GridItem span={6}>
                <FormGroup
                  label="Nombre de usuario"
                  isRequired
                  fieldId="username"
                  validated={isUsernameValid}
                  helperTextInvalid={usernameHelperText}
                >
                  <TextInput
                    id="username"
                    isRequired
                    onChange={(v) => setUsername(v.toLowerCase())}
                    value={username}
                    validated={isUsernameValid}
                    onBlur={() => onBlur('username')}
                  />
                </FormGroup>
              </GridItem>
              <GridItem span={6}>
                <FormGroup
                  label="Contraseña"
                  isRequired={!id}
                  fieldId="password"
                  validated={isPasswordValid}
                  helperTextInvalid="Debe tener al menos 8 caracteres."
                >
                  <TextInput
                    id="password"
                    type="password"
                    isRequired={!id}
                    onChange={setPassword}
                    value={password}
                    validated={isPasswordValid}
                    onBlur={() => onBlur('password')}
                  />
                </FormGroup>
              </GridItem>
              <FormGroup
                label="Nombres"
                isRequired
                fieldId="firstName"
                validated={isFirstNameValid}
                helperTextInvalid="Debe tener al menos 2 caracteres."
              >
                <TextInput
                  id="firstName"
                  isRequired
                  onChange={setFirstName}
                  value={firstName}
                  validated={isFirstNameValid}
                  onBlur={() => onBlur('firstName')}
                />
              </FormGroup>
              <FormGroup
                label="Apellidos"
                isRequired
                fieldId="lastName"
                validated={isLastNameValid}
                helperTextInvalid="Debe tener al menos 2 caracteres."
              >
                <TextInput
                  id="lastName"
                  isRequired
                  onChange={setLastName}
                  value={lastName}
                  validated={isLastNameValid}
                  onBlur={() => onBlur('lastName')}
                />
              </FormGroup>
              <FormGroup label="Nro. de registro" fieldId="licenseNo">
                <TextInput id="licenseNo" onChange={setLicenseNo} value={licenseNo} />
              </FormGroup>
              <FormGroup
                isRequired
                label="Establecimiento"
                validated={isHospitalValid}
                helperTextInvalid="Debe seleccionar un establecimiento o marcar al usuario como staff."
              >
                <InstitutionSelect
                  isRequired={!id}
                  value={hospital}
                  onChange={setHospital}
                  validated={isIsStaffValid}
                />
                {userData.isStaff && (
                  <Checkbox
                    label="Es administrador"
                    isChecked={isHospitalManager}
                    onChange={() => setIsHospitalManager(!isHospitalManager)}
                    id="isHospitalManager"
                    name="isHospitalManager"
                  />
                )}
              </FormGroup>
              <FormGroup label="Permisos">
                <Checkbox
                  label="Activo"
                  isChecked={isActive}
                  onChange={() => setIsActive(!isActive)}
                  id="isActive"
                  name="isActive"
                />
                <Checkbox
                  label="Puede entregar aspirina"
                  isChecked={enableDeliverAspirin}
                  onChange={() => setEnableDeliverAspirin(!enableDeliverAspirin)}
                  id="enableDeliverAspirin"
                  name="enableDeliverAspirin"
                />
                {userData.isStaff && (
                  <Checkbox
                    label="Es Staff de FJRE"
                    isChecked={isStaff}
                    onChange={() => setIsStaff(!isStaff)}
                    id="isStaff"
                    name="isStaff"
                    isValid={isIsStaffValid !== 'error'}
                  />
                )}
                {userData.isSuperuser && (
                  <Checkbox
                    label="Es superusuario"
                    isChecked={isSuperuser}
                    onChange={() => setIsSuperuser(!isSuperuser)}
                    id="isSuperuser"
                    name="isSuperuser"
                    isValid={isSuperuserValid !== 'error'}
                  />
                )}
              </FormGroup>
              <FormGroup label="Módulos">
                {(userData.isStaff || (userData.isHospitalManager && userData.enableNangareko)) && (
                  <Checkbox
                    label="Activar Ñangareko"
                    isChecked={enableNangareko}
                    onChange={() => setEnableNangareko(!enableNangareko)}
                    id="enableNangareko"
                    name="enableNangareko"
                  />
                )}
                {(userData.isStaff || (userData.isHospitalManager && userData.enablePreeclampsia)) && (
                  <Checkbox
                    label="Activar Preeclampsia"
                    isChecked={enablePreeclampsia}
                    onChange={() => setEnablePreeclampsia(!enablePreeclampsia)}
                    id="enablePreeclampsia"
                    name="enablePreeclampsia"
                  />
                )}
              </FormGroup>
              <GridItem span={12}>
                <ActionGroup>
                  <Button variant="primary" type="submit" isDisabled={isSubmitDisabled()}>
                    Guardar
                  </Button>
                  <Button variant="link" onClick={() => navigate(-1)}>
                    Cancelar
                  </Button>
                </ActionGroup>
              </GridItem>
            </Grid>
          </Form>
        </PageSection>
      </PageSection>
    );
  } else {
    return (
      <Bullseye>
        <Spinner />
      </Bullseye>
    );
  }
};

export default UserForm;
