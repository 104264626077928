import { useState } from 'react';
import { Select, SelectOption, SelectVariant } from '@patternfly/react-core';
import { DEPARTMENTS } from '../constants';

const DepartmentSelect = ({ value, onChange }) => {
  const departments = DEPARTMENTS.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const [isOpen, setIsOpen] = useState();
  const department = departments.find((el) => el.id === value) || null;
  const onSelectChange = (e, v) => {
    onChange(v.id);
    setIsOpen(false);
  };

  return (
    <Select
      variant={SelectVariant.typeahead}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      menuAppendTo="parent"
      selections={department}
      onSelect={onSelectChange}
      placeholderText="Departamento"
      maxHeight="200px"
    >
      <SelectOption key={-1} value={{ id: '', toString: () => 'Todos' }} isPlaceholder />
      {departments.map((d) => (
        <SelectOption key={d.id} value={d} />
      ))}
    </Select>
  );
};

DepartmentSelect.defaultProps = {
  value: null,
};

export default DepartmentSelect;
