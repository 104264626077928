import { useState } from 'react';
import { Bullseye, Nav, NavExpandable, NavItem, NavList } from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.svg';
import { useAuth } from '../context/authContext';

const MainNav = ({ onItemClick }) => {
  const [activeItem, setActiveItem] = useState('itm-0');
  const [activeGroup, setActiveGroup] = useState('');
  let navigate = useNavigate();
  const { userData } = useAuth();

  const onSelect = (res) => {
    setActiveItem(res.itemId);
    setActiveGroup(res.groupId);
    navigate(res.to);
  };

  let navLinks = [
    // { to: '/', label: 'Panel de Control' },
    { to: '/patients', label: 'Pacientes' },
    { to: '/pregnant-patients', label: 'Visita de pacientes embarazadas' },
  ];

  if (userData?.isStaff || userData?.isSuperuser || (userData?.enableNangareko && userData?.enablePreeclampsia)) {
    navLinks.push({
      label: 'Reportes',
      expandable: true,
      items: [
        { to: '/reporting/preeclampsia', label: 'Preeclampsia' },
        { to: '/reporting/nangareko', label: 'Nangareko' },
        { to: '/reporting/satisfaction-survey', label: 'Encuesta de satisfacción' },
      ],
    });
  } else if (userData?.enableNangareko) {
    navLinks.push({ to: '/reporting/nangareko', label: 'Reportes' });
  } else {
    navLinks.push({ to: '/reporting/preeclampsia', label: 'Reportes' });
  }

  if (userData?.isStaff) {
    navLinks.push(
      { to: '/users', label: 'Usuarios' },
      { to: '/hospitals', label: 'Establecimientos' },
      { to: '/cities', label: 'Ciudades' }
    );
  } else if (userData?.isHospitalManager) {
    navLinks.push({ to: '/users', label: 'Usuarios' });
  }

  return (
    <Nav onSelect={onSelect}>
      <Bullseye style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
        <img src={logo} alt="logo" style={{ height: 80 }} />
      </Bullseye>
      <NavList>
        {navLinks.map((item, idx) => {
          if (item.expandable) {
            return (
              <NavExpandable
                key={idx}
                title={item.label}
                groupId={`grp-${idx}`}
                isActive={activeGroup === `grp-${idx}`}
              >
                {item.items.map((subItem, subIndex) => (
                  <NavItem
                    key={subIndex}
                    preventDefault={true}
                    to={subItem.to}
                    itemId={`grp-${idx}_itm-${subIndex}`}
                    isActive={activeItem === `grp-${idx}_itm-${subIndex}`}
                    onClick={onItemClick}
                  >
                    {subItem.label}
                  </NavItem>
                ))}
              </NavExpandable>
            );
          } else {
            return (
              <NavItem
                key={idx}
                preventDefault={true}
                to={item.to}
                itemId={`itm-${idx}`}
                isActive={activeItem === `itm-${idx}`}
                onClick={onItemClick}
              >
                {item.label}
              </NavItem>
            );
          }
        })}
      </NavList>
    </Nav>
  );
};

export default MainNav;
