const DEPARTMENT_CHOICES = [
  { id: 0, name: 'Asunción' },
  { id: 1, name: 'Concepción' },
  { id: 2, name: 'San Pedro' },
  { id: 3, name: 'Cordillera' },
  { id: 4, name: 'Guairá' },
  { id: 5, name: 'Caaguazú' },
  { id: 6, name: 'Caazapá' },
  { id: 7, name: 'Itapúa' },
  { id: 8, name: 'Misiones' },
  { id: 9, name: 'Paraguarí' },
  { id: 10, name: 'Alto Paraná' },
  { id: 11, name: 'Central' },
  { id: 12, name: 'Ñeembucú' },
  { id: 13, name: 'Amambay' },
  { id: 14, name: 'Canindeyú' },
  { id: 15, name: 'Presidente Hayes' },
  { id: 16, name: 'Alto Paraguay' },
  { id: 17, name: 'Boquerón' },
];

function Department(id, name) {
  this.id = id;
  this.name = name;
  this.toString = function () {
    return this.name;
  };
}

const DEPARTMENTS = DEPARTMENT_CHOICES.map((d) => new Department(d.id, d.name));

const PREECLAMPSIA = 0;
const NANGAREKO = 1;
const BOTH = 2;

const GYNECOLOGY = 1;
const PREGNANCY_FIRST_VISIT = 2;
const PREGNANCY_FOLLOW_UP = 3;

const CONSULTATION_TYPES = {
  1: 'Consulta Ginecológica',
  2: 'Embarazo - Primera consulta',
  3: 'Embarazo - Seguimiento',
};

const PAGINATION_TITLES = {
  items: '',
  page: '',
  itemsPerPage: 'Items por página',
  perPageSuffix: 'por página',
  toFirstPage: 'Ir a la primera página',
  toPreviousPage: 'Ir a la página anterior',
  toLastPage: 'Ir a la última página',
  toNextPage: 'Ir a la página siguiente',
  optionsToggle: 'Items por página',
  currPage: 'Página actual',
  paginationTitle: 'Paginación',
  ofWord: 'de',
};

export {
  DEPARTMENTS,
  PREECLAMPSIA,
  NANGAREKO,
  BOTH,
  GYNECOLOGY,
  PREGNANCY_FIRST_VISIT,
  PREGNANCY_FOLLOW_UP,
  CONSULTATION_TYPES,
  PAGINATION_TITLES,
};
