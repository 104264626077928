import React, { useState } from 'react';
import { dateFormat, dateParse, dateSubmitParser, fileDownload } from '../../utils';
import {
  Button,
  ButtonVariant,
  Card,
  CardBody,
  CardTitle,
  DatePicker,
  Flex,
  FlexItem,
  FormSelect,
  FormSelectOption,
  Grid,
  GridItem,
  PageSection,
  Select,
  SelectOption,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  ToolbarToggleGroup,
} from '@patternfly/react-core';
import { FilterIcon } from '@patternfly/react-icons';
import InstitutionSelect from '../../components/InstitutionSelect';
import { useAuth } from '../../context/authContext';
import api from '../../services/api';
import Loading from '../../components/Loading';
import { ChartPie, ChartThemeColor } from '@patternfly/react-charts';

const PreeclampsiaReport = () => {
  const oneMonthAgo = new Date(new Date() - 30 * 86400000);
  let { userData } = useAuth();
  const [filters, setFilters] = useState({
    date_from: dateFormat(oneMonthAgo),
    date_to: dateFormat(new Date()),
    type: 'any',
    weeks_pregnant: '',
    proteinuria: 'any',
    aspirin: 0,
    hospital: 0,
    high_bp: 'any',
    high_risk: 'any',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [report, setReport] = useState(null);
  const [aspirinSelect, setAspirinSelect] = useState({
    isOpen: false,
    selected: [],
  });
  const [proteinuriaSelect, setProteinuriaSelect] = useState({
    isOpen: false,
    selected: [],
  });

  const onAspirinSelect = (event, selection) => {
    const { selected } = aspirinSelect;
    if (selected.includes(selection)) {
      const newSelection = selected.filter((el) => el !== selection);
      setAspirinSelect({ ...aspirinSelect, selected: newSelection });
    } else {
      setAspirinSelect({ ...aspirinSelect, selected: [...selected, selection] });
    }
  };

  const onProteinuriaSelect = (event, selection) => {
    const { selected } = proteinuriaSelect;
    if (selected.includes(selection)) {
      const newSelection = selected.filter((el) => el !== selection);
      setProteinuriaSelect({ ...proteinuriaSelect, selected: newSelection });
    } else {
      setProteinuriaSelect({ ...proteinuriaSelect, selected: [...selected, selection] });
    }
  };

  const buildParams = () => {
    const params = {};
    if (filters.date_from) params['date_from'] = dateSubmitParser(filters.date_from);
    else params['date_from'] = dateSubmitParser(dateFormat(oneMonthAgo));
    if (filters.date_to) params['date_to'] = dateSubmitParser(filters.date_to);
    if (filters.type !== 'any') params['type'] = filters.type;
    if (filters.weeks_pregnant) params['weeks_pregnant'] = filters.weeks_pregnant;
    if (filters.proteinuria !== 'any') params['proteinuria'] = filters.proteinuria;
    //if (filters.aspirin > 0) params['aspirin'] = filters.aspirin;
    if (filters.high_bp !== 'any') params['high_bp'] = filters.high_bp;
    if (filters.high_risk !== 'any') params['high_risk'] = filters.high_risk;
    if (filters.hospital > 0) params['hospital'] = filters.hospital;
    if (aspirinSelect.selected.length > 0) {
      params['aspirin'] = [];
      for (const r of aspirinSelect.selected) {
        params['aspirin'].push(r);
      }
    }
    if (proteinuriaSelect.selected.length > 0) {
      params['proteinuria'] = [];
      for (const r of proteinuriaSelect.selected) {
        params['proteinuria'].push(r);
      }
    }
    return params;
  };

  const getReport = () => {
    const params = buildParams();
    setIsLoading(true);
    api.reports
      .getPreeclampsia(params)
      .then((res) => {
        setReport(res);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al obtener datos.');
        setIsLoading(false);
      });
  };

  const onDownload = () => {
    const params = buildParams();
    setIsDownloading(true);
    api.reports
      .downloadPreeclampsia(params)
      .then((data) => {
        const now = new Date();
        const filename = `Preeclampsia ${now.getFullYear()}${
          now.getMonth() + 1
        }${now.getDate()}-${now.getHours()}${now.getMinutes()}.xlsx`;
        fileDownload(data, filename);
        setIsDownloading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al descargar el reporte.');
        setIsDownloading(false);
      });
  };

  return (
    <>
      <PageSection variant="light">
        <Toolbar id="preeclampsiaFilters1">
          <ToolbarContent>
            <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="lg">
              <ToolbarItem variant="label">Fecha</ToolbarItem>
              <ToolbarGroup variant="filter-group">
                <ToolbarItem>
                  <DatePicker
                    id="dateFrom"
                    dateFormat={dateFormat}
                    dateParse={dateParse}
                    placeholder="dd/mm/aaaa"
                    locale="es-PY"
                    onChange={(value) => setFilters({ ...filters, date_from: value })}
                    value={filters.date_from}
                  />
                </ToolbarItem>
                <ToolbarItem>
                  <DatePicker
                    id="dateTo"
                    dateFormat={dateFormat}
                    dateParse={dateParse}
                    placeholder="dd/mm/aaaa"
                    locale="es-PY"
                    onChange={(value) => setFilters({ ...filters, date_to: value })}
                    value={filters.date_to}
                  />
                </ToolbarItem>
              </ToolbarGroup>
              <ToolbarItem variant="label">Tipo</ToolbarItem>
              <ToolbarItem>
                <FormSelect
                  id="consultationType"
                  value={filters.type}
                  onChange={(v) => setFilters({ ...filters, type: v })}
                >
                  <FormSelectOption key="0" value="any" label="Todos" />
                  <FormSelectOption key="1" value={2} label="Embarazo - Primera consulta" />
                  <FormSelectOption key="2" value={3} label="Embarazo - Seguimiento" />
                </FormSelect>
              </ToolbarItem>
              {userData?.isStaff && (
                <>
                  <ToolbarItem variant="label">Establecimiento</ToolbarItem>
                  <ToolbarItem>
                    <InstitutionSelect
                      id="hospital"
                      value={filters.hospital}
                      onChange={(v) => setFilters({ ...filters, hospital: v })}
                      defaultValue={{ id: 0, toString: () => 'Todos' }}
                    />
                  </ToolbarItem>
                </>
              )}
            </ToolbarToggleGroup>
          </ToolbarContent>
        </Toolbar>
        <Toolbar id="preeclampsiaFilters2">
          <ToolbarContent>
            {/*<ToolbarItem variant="label">Sem</ToolbarItem>*/}
            {/*<ToolbarItem>*/}
            {/*  <TextInput*/}
            {/*    value={filters.weeks_pregnant}*/}
            {/*    width={2}*/}
            {/*    onChange={(v) => setFilters({ ...filters, weeks_pregnant: v })}*/}
            {/*  />*/}
            {/*</ToolbarItem>*/}
            <ToolbarItem variant="label">Proteinuria</ToolbarItem>

            <ToolbarItem>
              <Select
                variant="checkbox"
                onToggle={(isOpen) => setProteinuriaSelect({ ...proteinuriaSelect, isOpen })}
                onSelect={onProteinuriaSelect}
                selections={proteinuriaSelect.selected}
                isOpen={proteinuriaSelect.isOpen}
                placeholderText="Proteinuria"
              >
                <SelectOption key={'notDone'} value={-1}>
                  No realizado
                </SelectOption>
                <SelectOption key={'negative'} value="0">
                  Negativo
                </SelectOption>
                <SelectOption key={1} value={1}>
                  +
                </SelectOption>
                <SelectOption key={2} value={2}>
                  ++
                </SelectOption>
                <SelectOption key={3} value={3}>
                  +++
                </SelectOption>
                <SelectOption key={4} value={4}>
                  ++++
                </SelectOption>
              </Select>
              {/* <FormSelect
                id="proteinuria"
                value={filters.proteinuria}
                onChange={(v) => setFilters({ ...filters, proteinuria: v })}
              >
                <FormSelectOption key="select" isPlaceholder value="any" label="Todos" />
                <FormSelectOption key="notDone" value={-1} label="No realizado" />
                <FormSelectOption key="negative" value={0} label="Negativo" />
                <FormSelectOption key={1} value={1} label="+" />
                <FormSelectOption key={2} value={2} label="++" />
                <FormSelectOption key={3} value={3} label="+++" />
                <FormSelectOption key={4} value={4} label="++++" />
              </FormSelect> */}
            </ToolbarItem>
            <ToolbarItem variant="label">AAS</ToolbarItem>
            <ToolbarItem>
              <Select
                variant="checkbox"
                onToggle={(isOpen) => setAspirinSelect({ ...aspirinSelect, isOpen })}
                onSelect={onAspirinSelect}
                selections={aspirinSelect.selected}
                isOpen={aspirinSelect.isOpen}
                placeholderText="Entrega de aspirinas"
              >
                <SelectOption key={1} value={1}>
                  Entregado
                </SelectOption>
                <SelectOption key={2} value={2}>
                  Obtuvo por sus propios medios
                </SelectOption>
                <SelectOption key={3} value={3}>
                  No entregado
                </SelectOption>
                <SelectOption key={4} value={4}>
                  Otros
                </SelectOption>
              </Select>
              {/* <FormSelect id="aspirin" value={filters.aspirin} onChange={(v) => setFilters({ ...filters, aspirin: v })}>
                <FormSelectOption key="select" isPlaceholder value={0} label="Todos" />
                <FormSelectOption key={1} value={1} label="Entregado" />
                <FormSelectOption key={2} value={2} label="Obtuvo por sus propios medios" />
                <FormSelectOption key={3} value={3} label="No entregado" />
                <FormSelectOption key={4} value={4} label="Otros" />
              </FormSelect> */}
            </ToolbarItem>
            <ToolbarItem variant="label">PA</ToolbarItem>
            <ToolbarItem>
              <FormSelect id="highBp" value={filters.high_bp} onChange={(v) => setFilters({ ...filters, high_bp: v })}>
                <FormSelectOption key="any" value="any" label="Todos" />
                <FormSelectOption key="high" value={true} label="Alta" />
                <FormSelectOption key="normal" value={false} label="Normal" />
              </FormSelect>
            </ToolbarItem>
            <ToolbarItem variant="label">Factor de riesgo</ToolbarItem>
            <ToolbarItem>
              <FormSelect
                id="highBp"
                value={filters.high_risk}
                onChange={(v) => setFilters({ ...filters, high_risk: v })}
              >
                <FormSelectOption key="any" value="any" label="Todos" />
                <FormSelectOption key="high" value={true} label="Alto" />
                <FormSelectOption key="low" value={false} label="Bajo" />
              </FormSelect>
            </ToolbarItem>
            <ToolbarItem>
              {isLoading && <Spinner size="md" />}
              {!isLoading && (
                <Button variant={ButtonVariant.primary} onClick={getReport}>
                  Consultar
                </Button>
              )}
            </ToolbarItem>
            <ToolbarItem>
              {isDownloading && <Spinner size="md" />}
              {!isDownloading && (
                <Button variant={ButtonVariant.secondary} onClick={onDownload}>
                  Descargar Excel
                </Button>
              )}
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      </PageSection>
      <PageSection />
      <PageSection>
        {isLoading && <Loading />}
        <Grid hasGutter>
          <GridItem span={8}>
            {report && (
              <Card>
                <CardTitle>Resumen</CardTitle>
                <CardBody>
                  <Grid md={2}>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.consultations}</FlexItem>
                        <FlexItem>Consultas</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.patients}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Pacientes</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.highRiskPatients}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Alto Riesgo</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.highBpPatients}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>PA Alta</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.proteinuriaPositiveWithBp}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Proteinuria Significativa con PA</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.proteinuriaPositiveWithoutBp}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Proteinuria Significativa sin PA</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.highRiskWithAspirin}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Alto Riesgo con AAS (sem 12-16)</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.statedAspirin1216}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Empezaron AAS (sem 12-16)</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.statedAspirinAfter16}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Empezaron AAS (sem +16)</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.returnedFollowUp}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Volvieron a consultas de seguimiento</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.missedFollowUp}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>No volvieron a consultas de seguimiento</FlexItem>
                      </Flex>
                    </GridItem>
                  </Grid>
                </CardBody>
                <CardBody>
                  <Flex>
                    <FlexItem style={{ height: '230px', width: '350px' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Sem. gest. en 1ra consulta</p>
                      <ChartPie
                        ariaTitle="Semanas de gestación en primera consulta"
                        constrainToVisibleArea={true}
                        data={[
                          { x: '0-11 sem.', y: report['1-11'] },
                          { x: '12-16 sem.', y: report['12-16'] },
                          { x: '17+ sem.', y: report['17-40'] },
                        ]}
                        height={230}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `0-11 sem.: ${report['1-11']}` },
                          { name: `12-16 sem.: ${report['12-16']}` },
                          { name: `17+ sem.: ${report['17-40']}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 160,
                          top: 20,
                        }}
                        width={350}
                      />
                    </FlexItem>
                    <FlexItem style={{ height: '230px', width: '350px' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Entrega de aspirinas</p>
                      <ChartPie
                        ariaTitle="Entrega de aspirinas"
                        constrainToVisibleArea={true}
                        data={[
                          { x: '1ra consulta', y: report.highRiskAspirinFirstVisit },
                          { x: 'Seguimiento', y: report.highRiskAspirinFollowUp },
                        ]}
                        height={230}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `1ra. consulta: ${report.highRiskAspirinFirstVisit}` },
                          { name: `Seguimiento.: ${report.highRiskAspirinFollowUp}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 160,
                          top: 20,
                        }}
                        width={350}
                        themeColor={ChartThemeColor.green}
                      />
                    </FlexItem>
                  </Flex>
                </CardBody>
              </Card>
            )}
          </GridItem>
        </Grid>
      </PageSection>
    </>
  );
};

export default PreeclampsiaReport;
