import { DatePicker, FormGroup, FormSection, Grid, NumberInput } from '@patternfly/react-core';
import {
  dateFormat,
  dateFormatter,
  dateParse,
  dateRangeAndMonthValidator,
  dateRangeValidator,
  weekDayDifference,
} from '../../../utils';
import { useEffect, useState } from 'react';
import { ASTERISK } from '@patternfly/react-core';
import { css } from '@patternfly/react-styles';
import styles from '@patternfly/react-styles/css/components/Form/form';

const GestationalAge = ({
  consultationDate,
  lastMenstruation,
  ecographyData,
  onLastMenstruationChange,
  onEcographyDataChange,
  onCalculatedAgeChange,
  isRequired = false,
}) => {
  // pregnancy age is calculated based on lastMenstruation and ecography data
  const [pregnancyAge, setPregnancyAge] = useState({ weeks: '', days: '' });
  const [calculationSource, setCalculationSource] = useState('');

  // recalculate pregnancy age when data changes
  useEffect(() => {
    const ecoDate = ecographyData.ecographyDate;
    const woe = ecographyData.weeksOnEcography;
    const doe = ecographyData.daysOnEcography;
    if (ecoDate && woe > 0 && doe >= 0 && woe !== '' && doe !== '') {
      const weeksDays = weekDayDifference(dateParse(ecoDate), dateParse(consultationDate));
      let calculatedWeeks = weeksDays.weeks + (parseInt(woe) || 0);
      let calculatedDays = weeksDays.days + (parseInt(doe) || 0);
      if (calculatedDays / 7 >= 1) {
        calculatedWeeks += Math.floor(calculatedDays / 7);
        calculatedDays = calculatedDays % 7;
      }
      setPregnancyAge({ weeks: calculatedWeeks, days: calculatedDays });
      setCalculationSource('Eco');
      onCalculatedAgeChange({ weeks: calculatedWeeks, days: calculatedDays });
    } else if (lastMenstruation) {
      const weeksDays = weekDayDifference(dateParse(lastMenstruation), dateParse(consultationDate));
      setPregnancyAge(weeksDays);
      setCalculationSource('FUM');
      onCalculatedAgeChange(weeksDays);
    }
  }, [consultationDate, lastMenstruation, ecographyData]);

  const onWeeksChange = (e) => {
    if (e.target.value.includes('.')) {
      const parts = e.target.value.split('.');
      onEcographyDataChange({
        ...ecographyData,
        weeksOnEcography: parts[0],
        daysOnEcography: parts[1],
      });
    } else {
      onEcographyDataChange({
        ...ecographyData,
        weeksOnEcography: parseInt(e.target.value) || '',
      });
    }
  };

  const onDaysChange = (e) => {
    onEcographyDataChange({
      ...ecographyData,
      daysOnEcography: parseInt(e.target.value),
    });
  };

  return (
    <FormSection
      title={
        <>
          Edad gestacional
          {isRequired && (
            <span className={css(styles.formLabelRequired)} aria-hidden="true">
              {' '}
              {ASTERISK}
            </span>
          )}
        </>
      }
    >
      <Grid md={3} hasGutter>
        <FormGroup fieldId="lastMenstruation" label="Fecha de última menstruación">
          <DatePicker
            id="lastMenstruation"
            dateFormat={dateFormat}
            dateParse={dateParse}
            placeholder="DD/MM/AAAA"
            locale="es-PY"
            onChange={(value) => onLastMenstruationChange(dateFormatter(value))}
            value={lastMenstruation}
            validators={[dateRangeAndMonthValidator]}
            invalidFormatText="Formato de fecha inválido. El formato debe ser DD/MM/AAAA"
          />
        </FormGroup>
        <FormGroup fieldId="ecographyDate" label="Ecografía realizada en fecha:">
          <DatePicker
            id="ecographyDate"
            dateFormat={dateFormat}
            dateParse={dateParse}
            placeholder="DD/MM/AAAA"
            locale="es-py"
            onChange={(v) => onEcographyDataChange({ ...ecographyData, ecographyDate: dateFormatter(v) })}
            value={ecographyData.ecographyDate}
            validators={[dateRangeValidator]}
            invalidFormatText="Formato de fecha inválido. El formato debe ser DD/MM/AAAA"
          />
        </FormGroup>
        <FormGroup fieldId="_weeksOnEcography" label="Edad gestacional en día de la ecografía">
          <NumberInput
            id="_weeksOnEcography"
            name="_weeksOnEcography"
            value={ecographyData.weeksOnEcography}
            unit="semanas"
            widthChars={2}
            minusBtnProps={{ style: { display: 'none' } }}
            plusBtnProps={{ style: { display: 'none' } }}
            style={{ marginRight: '1rem' }}
            onChange={onWeeksChange}
          />
          <NumberInput
            id="_daysOnEcography"
            name="_daysOnEcography"
            onChange={onDaysChange}
            value={ecographyData.daysOnEcography}
            unit="días"
            widthChars={2}
            minusBtnProps={{ style: { display: 'none' } }}
            plusBtnProps={{ style: { display: 'none' } }}
            style={{ marginRight: '1rem' }}
          />
        </FormGroup>
        <FormGroup fieldId="pregnancyAge" label="Edad gestacional final">
          {(lastMenstruation || ecographyData.weeksOnEcography) &&
            `${pregnancyAge.weeks} semanas y ${pregnancyAge.days} días (${calculationSource})`}
        </FormGroup>
      </Grid>
    </FormSection>
  );
};

export default GestationalAge;
