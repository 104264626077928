import React, { useState } from 'react';
import { dateFormat, dateParse, dateSubmitParser, fileDownload } from '../../utils';
import {
  Button,
  ButtonVariant,
  Card,
  CardBody,
  CardTitle,
  DatePicker,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  PageSection,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  ToolbarToggleGroup,
} from '@patternfly/react-core';
import { FilterIcon } from '@patternfly/react-icons';
import api from '../../services/api';
import Loading from '../../components/Loading';
import {
  ChartPie,
  ChartThemeColor,
  Chart,
  ChartAxis,
  ChartBar,
  ChartGroup,
  ChartVoronoiContainer,
  ChartDonut,
  ChartLabel,
} from '@patternfly/react-charts';

const SatisfactionSurverReport = () => {
  const oneMonthAgo = new Date(new Date() - 30 * 86400000);
  const [filters, setFilters] = useState({
    date_from: dateFormat(oneMonthAgo),
    date_to: dateFormat(new Date()),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [report, setReport] = useState(null);

  const buildParams = () => {
    const params = {};
    if (filters.date_from) params['date_from'] = dateSubmitParser(filters.date_from);
    else params['date_from'] = dateSubmitParser(dateFormat(oneMonthAgo));
    if (filters.date_to) params['date_to'] = dateSubmitParser(filters.date_to);

    return params;
  };

  const getReport = () => {
    const params = buildParams();
    setIsLoading(true);

    api.survey
      .getQuestionReport(params)
      .then((res) => {
        setReport(res);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al obtener datos.');
        setIsLoading(false);
      });
  };

  const onDownload = () => {
    const params = buildParams();
    setIsDownloading(true);
    api.survey
      .downloadReport(params)
      .then((data) => {
        const now = new Date();
        const filename = `Encuesta ${now.getFullYear()}${
          now.getMonth() + 1
        }${now.getDate()}-${now.getHours()}${now.getMinutes()}.xlsx`;
        fileDownload(data, filename);
        setIsDownloading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al descargar el reporte.');
        setIsDownloading(false);
      });
  };

  return (
    <>
      <PageSection variant="light">
        <Toolbar id="surveyFilters">
          <ToolbarContent>
            <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="lg">
              <ToolbarItem variant="label">Fecha</ToolbarItem>
              <ToolbarGroup variant="filter-group">
                <ToolbarItem>
                  <DatePicker
                    id="dateFrom"
                    dateFormat={dateFormat}
                    dateParse={dateParse}
                    placeholder="dd/mm/aaaa"
                    locale="es-PY"
                    onChange={(value) => setFilters({ ...filters, date_from: value })}
                    value={filters.date_from}
                  />
                </ToolbarItem>
                <ToolbarItem>
                  <DatePicker
                    id="dateTo"
                    dateFormat={dateFormat}
                    dateParse={dateParse}
                    placeholder="dd/mm/aaaa"
                    locale="es-PY"
                    onChange={(value) => setFilters({ ...filters, date_to: value })}
                    value={filters.date_to}
                  />
                </ToolbarItem>
              </ToolbarGroup>
            </ToolbarToggleGroup>
            <ToolbarItem>
              {isLoading && <Spinner size="md" />}
              {!isLoading && (
                <Button variant={ButtonVariant.primary} onClick={getReport}>
                  Consultar
                </Button>
              )}
            </ToolbarItem>
            <ToolbarItem>
              {isDownloading && <Spinner size="md" />}
              {!isDownloading && (
                <Button variant={ButtonVariant.secondary} onClick={onDownload}>
                  Descargar Excel
                </Button>
              )}
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      </PageSection>
      <PageSection />
      <PageSection>
        {isLoading && <Loading />}
        <Grid hasGutter>
          <GridItem span={12}>
            {report && (
              <Card>
                <CardTitle>Resumen</CardTitle>
                <CardBody style={{ marginBottom: 40 }}>
                  <Grid md={2} hasGutter>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.participants}</FlexItem>
                        <FlexItem>Total de Respuestas</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.differentParticipants}</FlexItem>
                        <FlexItem>Participantes</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.fromWhatsapp}</FlexItem>
                        <FlexItem>Whatsapp</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.fromWeb}</FlexItem>
                        <FlexItem>Web</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.consultations}</FlexItem>
                        <FlexItem>Consultas</FlexItem>
                      </Flex>
                    </GridItem>

                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.totalScore}</FlexItem>
                        <FlexItem>Puntaje alcanzable</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.maxScore}</FlexItem>
                        <FlexItem>Puntaje máximo</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{Math.round(report.avgScore * 10) / 10}</FlexItem>
                        <FlexItem>Puntaje promedio</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.minScore}</FlexItem>
                        <FlexItem>Puntaje mínimo</FlexItem>
                      </Flex>
                    </GridItem>
                  </Grid>
                </CardBody>
                <CardBody>
                  <Flex>
                    {report.questions.map((el, index) => (
                      <FlexItem key={index} style={{ height: '200px', width: '450px', marginBottom: 30 }}>
                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{el.question}</p>
                        <ChartPie
                          ariaTitle="Semanas de gestación en primera consulta"
                          constrainToVisibleArea={true}
                          data={el.answers.map((e) => {
                            return { x: e.answer, y: e.qty };
                          })}
                          height={200}
                          labels={({ datum }) => `${datum.x}: ${datum.y}`}
                          legendData={el.answers.map((e) => {
                            return { name: `${e.answer}: ${e.qty} (${e.percentage}%)` };
                          })}
                          legendOrientation="vertical"
                          legendPosition="right"
                          legendAllowWrap={true}
                          padding={{
                            bottom: 20,
                            left: 0,
                            right: 200,
                            top: 20,
                          }}
                          width={300}
                          themeColor={ChartThemeColor.cyan}
                        />
                      </FlexItem>
                    ))}
                    <FlexItem style={{ height: '200px', width: '450px', marginBottom: 30 }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Distribución de edades</p>
                      <ChartPie
                        ariaTitle=">Distribución de edade"
                        constrainToVisibleArea={true}
                        data={Object.keys(report.ages).map((age) => {
                          return { x: age, y: report.ages[age] };
                        })}
                        height={200}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={Object.keys(report.ages).map((age) => {
                          return { name: `${age} años: ${report.ages[age]}` };
                        })}
                        legendOrientation="vertical"
                        legendPosition="right"
                        legendAllowWrap={true}
                        padding={{
                          bottom: 20,
                          left: 0,
                          right: 200,
                          top: 20,
                        }}
                        width={300}
                        themeColor={ChartThemeColor.cyan}
                      />
                    </FlexItem>
                  </Flex>
                  <Flex>
                    <FlexItem>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>¿Cómo te enteraste sobre este servicio?</p>
                      <ChartDonut
                        constrainToVisibleArea
                        data={[
                          { x: 'Redes sociales', y: report.foundBy.socialMedia },
                          { x: 'Familia o amigos', y: report.foundBy.familyOrFriends },
                          { x: 'Charlas en mi colegio o comunidad', y: report.foundBy.communityTalks },
                          { x: 'Agente comunitario', y: report.foundBy.communityAgent },
                          { x: 'Referido por USF', y: report.foundBy.referredByUsf },
                          { x: 'Otros', y: report.foundBy.other },
                        ]}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `Redes sociales: ${report.foundBy.socialMedia}` },
                          { name: `Familia o amigos: ${report.foundBy.familyOrFriends}` },
                          { name: `Charlas en mi colegio o comunidad: ${report.foundBy.communityTalks}` },
                          { name: `Agente comunitario: ${report.foundBy.communityAgent}` },
                          { name: `Referido por USF: ${report.foundBy.referredByUsf}` },
                          { name: `Otros: ${report.foundBy.other}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 140, // Adjusted to accommodate legend
                          top: 20,
                        }}
                        subTitle="Respuestas"
                        title={Object.values(report.foundBy).reduce((total, valor) => total + valor, 0)}
                        width={800}
                        themeColor={ChartThemeColor.multi}
                      />
                    </FlexItem>
                  </Flex>
                </CardBody>
              </Card>
            )}
          </GridItem>
        </Grid>
      </PageSection>
    </>
  );
};

export default SatisfactionSurverReport;
