import {
  Bullseye,
  Button,
  Flex,
  FlexItem,
  InputGroup,
  PageSection,
  Spinner,
  TextInput,
  Toolbar,
  ToolbarContent,
} from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import api from '../../services/api';
import EntityTable from '../../components/EntityTable';
import Pagination from '../../components/Pagination';
import DepartmentSelect from '../../components/DepartmentSelect';

const CityList = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const [search, setSearch] = useState(queryParams.get('search') || '');
  const [department, setDepartment] = useState(queryParams.get('department') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [limit, setLimit] = useState(queryParams.get('limit') || 50);
  const [offset, setOffset] = useState(queryParams.get('offset') || 0);
  const [count, setCount] = useState(0);

  function fetchCities(ordering = null, limit = null, search = '', offset = 0, extra = {}) {
    setIsLoading(true);
    api.cities.getMulti(ordering, limit, search, offset, extra).then((response) => {
      setCount(response.count);
      setEntityList(response.results);
      setIsLoading(false);
    });
  }

  // fetch cities after component is mounted
  useEffect(() => fetchCities(), []);

  const onFilterSubmit = (e) => {
    e.preventDefault();
    fetchCities(null, search, limit, offset, { department });
  };

  const onCityDelete = (cityId) => {
    api.cities
      .delete(cityId)
      .then(() => {
        fetchCities();
      })
      .catch((e) => {
        console.error(e);
        alert('Error al eliminar la ciudad.');
      });
  };

  const toolbarItems = (
    <form onSubmit={onFilterSubmit} style={{ width: '100%' }}>
      <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
        <Flex alignItems={{ default: 'alignItemsCenter' }}>
          <FlexItem>
            <InputGroup>
              <TextInput
                name="search"
                id="search"
                type="search"
                value={search}
                onChange={setSearch}
                placeholder="Nombre de la ciudad"
              />
            </InputGroup>
          </FlexItem>
          <FlexItem>
            <DepartmentSelect onChange={setDepartment} value={department} />
          </FlexItem>
          <FlexItem variant="separator" />
          <FlexItem>
            <Button variant="primary" type="submit">
              Filtrar
            </Button>
          </FlexItem>
        </Flex>
        <Button component={Link} to={`/cities/new`} variant="secondary">
          Crear Ciudad
        </Button>
      </Flex>
    </form>
  );

  const columns = {
    id: 'ID',
    name: 'Nombre',
    department: 'Departamento',
  };

  const rows = entityList.map((e) => ({
    id: e.id,
    name: e.name,
    department: e.departmentName,
  }));

  return (
    <PageSection>
      <Toolbar>
        <ToolbarContent>{toolbarItems}</ToolbarContent>
      </Toolbar>
      {isLoading && (
        <Bullseye>
          <Spinner isSVG />
        </Bullseye>
      )}
      {!isLoading && <EntityTable columns={columns} rows={rows} entity="cities" onDelete={onCityDelete} />}
      <Pagination pageCount={count} limit={limit} offset={offset} onLimitChange={setLimit} onOffsetChange={setOffset} />
    </PageSection>
  );
};

export default CityList;
