import React from 'react';
import {
  Bullseye,
  Button,
  Flex,
  FlexItem,
  InputGroup,
  Spinner,
  TextInput,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  FormSelectOption,
  FormSelect,
  FormGroup,
  Modal,
  ModalVariant,
  TextArea,
  Form,
  HelperText,
  HelperTextItem,
} from '@patternfly/react-core';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../../services/api';
import Pagination from '../../components/Pagination';
import EmptyState from '../../components/EmptyState';
import { UsersIcon } from '@patternfly/react-icons';
import { useEffect } from 'react';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import { fileDownload, isoToPy } from '../../utils';
import AspirinModal from '../../components/AspirinModal';

const PregnantPatientList = () => {
  const { search: searchParams } = useLocation();
  const queryParams = new URLSearchParams(searchParams);
  const [search, setSearch] = useState(queryParams.get('search') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [limit, setLimit] = useState(queryParams.get('limit') || 50);
  const [offset, setOffset] = useState(queryParams.get('offset') || 0);
  const [count, setCount] = useState(0);
  const [highRisk, setHighRisk] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [sendingData, setSendingData] = useState(false);
  const [ordering, setOrdering] = useState('last_consultation_date');
  const [days, setDays] = useState(30);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setSelectedPatient(null);
  };

  const download = () => {
    setIsLoading(true);
    api.patients
      .downloadPregnantPatients(highRisk, days)
      .then((data) => {
        const now = new Date();
        const filename = `Preeclampsia ${now.getFullYear()}${
          now.getMonth() + 1
        }${now.getDate()}-${now.getHours()}${now.getMinutes()}.xlsx`;
        fileDownload(data, filename);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al descargar el reporte.');
        setIsLoading(false);
      });
  };

  const onSubmit = (aspirinValue, aspirinComments) => {
    const d = new Date();
    const string_date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    const data = {
      patient: selectedPatient.id,
      status: aspirinValue,
      comments: aspirinComments,
      deliveryDate: string_date,
    };
    setSendingData(true);
    api.aspirins
      .create(data)
      .then(() => {
        handleModalToggle();
        setSendingData(false);
      })
      .catch((err) => {
        console.log(err);
        setSendingData(false);
      });
  };

  const applyFilters = () => {
    if (search.length >= 3) {
      setIsLoading(true);
      api.patients.getPregnantPatients(null, search, limit, offset, highRisk, days).then((response) => {
        setCount(response.count);
        setEntityList(response.results);
        setIsLoading(false);
      });
    } else {
      setEntityList([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    api.patients.getPregnantPatients(ordering, search, limit, offset, highRisk, days).then((response) => {
      setCount(response.count);
      setEntityList(response.results);
      setIsLoading(false);
    });
  }, [limit, offset, highRisk, ordering, days]);

  const onFilterSubmit = (e) => {
    e.preventDefault();
    setOffset(0);
    applyFilters();
  };

  const parseCell = (cell) => {
    if (typeof cell == 'boolean') return cell === true ? <CheckIcon /> : '';
    return cell;
  };

  const toolbarItems = (
    <form onSubmit={onFilterSubmit} style={{ width: '100%' }}>
      <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
        <Flex alignItems={{ default: 'alignItemsCenter' }}>
          <FlexItem>
            <InputGroup>
              <TextInput
                name="search"
                id="search"
                type="search"
                value={search}
                onChange={setSearch}
                placeholder="Nombre o CI"
              />
            </InputGroup>
          </FlexItem>
          <FlexItem variant="separator" />
          <FlexItem>
            <Button variant="primary" type="submit" isDisabled={search.length < 3}>
              Buscar
            </Button>
          </FlexItem>
          <ToolbarItem variant="label">Alto riesgo</ToolbarItem>
          <ToolbarItem>
            <FormSelect id="highBp" value={highRisk} onChange={(v) => setHighRisk(v)}>
              <FormSelectOption key="any" value="any" label="Todos" />
              <FormSelectOption key="high" value={true} label="Sí" />
              <FormSelectOption key="low" value={false} label="No" />
            </FormSelect>
          </ToolbarItem>
          <ToolbarItem variant="label">Fecha de última consulta</ToolbarItem>
          <ToolbarItem>
            <FormSelect id="ordering" value={ordering} onChange={(v) => setOrdering(v)}>
              <FormSelectOption value={'-last_consultation_date'} label="Más reciente" />
              <FormSelectOption value={'last_consultation_date'} label="Menos reciente" />
            </FormSelect>
          </ToolbarItem>
          <ToolbarItem variant="label">Días de atraso hasta</ToolbarItem>
          <ToolbarItem>
            <InputGroup>
              <TextInput
                style={{ width: 50 }}
                name="days"
                id="days"
                type="days"
                value={days}
                onChange={setDays}
                placeholder="días"
              />
            </InputGroup>
          </ToolbarItem>
        </Flex>
        <Button variant="secondary" onClick={download}>
          Descargar lista
        </Button>
      </Flex>
    </form>
  );

  const columns = {
    firstName: 'Nombres',
    lastName: 'Apellidos',
    govId: 'Nro. Doc.',
    phone: 'Teléfono',
    lastConsultationDate: 'Última consulta',
    days: 'Hace',
    highRisk: 'Alto riesgo',
  };
  const rows = entityList.map((patient) => ({
    id: patient.id,
    firstName: patient.firstName,
    lastName: patient.lastName,
    govId: patient.govId,
    lastConsultationDate: patient.lastConsultationDate,
    days: patient.days,
    consultationType: patient.consultationType,
    highRisk: patient.highRisk,
    phone: patient.cellphone,
  }));

  return (
    <>
      <Toolbar>
        <ToolbarContent>{toolbarItems}</ToolbarContent>
      </Toolbar>
      {isLoading && (
        <Bullseye>
          <Spinner isSVG />
        </Bullseye>
      )}
      {!isLoading && (
        <TableComposable variant="compact">
          <Thead>
            <Tr>
              {Object.keys(columns).map((k) => (
                <Th modifier="fitContent" key={k}>
                  {columns[k]}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {rows?.map((row, rowIndex) => (
              <Tr
                key={rowIndex}
                style={rows[rowIndex].days <= 5 ? { backgroundColor: '#FFEB9C' } : { backgroundColor: '#FFC7CE' }}
              >
                {Object.keys(columns).map((k) => (
                  <Td key={`${rowIndex}_${k}`} dataLabel={columns[k]}>
                    {columns[k] === 'Última consulta'
                      ? isoToPy(row[k])
                      : columns[k] === 'Hace'
                      ? `${parseCell(row[k])} días`
                      : parseCell(row[k])}
                  </Td>
                ))}

                <Td dataLabel={'Nueva consulta'} modifier="fitContent">
                  <Link
                    to={
                      rows[rowIndex].consultationType === 'follow_up'
                        ? `/patients/${rows[rowIndex].id}/new-consultation/3`
                        : `/patients/${rows[rowIndex].id}/new-consultation/2`
                    }
                  >
                    <Button onClick={() => {}}>{'Nueva consulta'}</Button>
                  </Link>
                </Td>
                <Td dataLabel={'Cargar aspirinas'} modifier="fitContent">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      handleModalToggle();
                      setSelectedPatient(rows[rowIndex]);
                    }}
                  >
                    {'Cargar aspirinas'}
                  </Button>
                </Td>
                <Td dataLabel={'Ver'} modifier="fitContent">
                  <Link to={`/patients/${row.id}`}>
                    <Button variant="tertiary" onClick={() => {}}>
                      {'Ver'}
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <AspirinModal
            isModalOpen={isModalOpen}
            handleModalToggle={handleModalToggle}
            onSubmit={onSubmit}
            sendingData={sendingData}
            selectedPatient={selectedPatient}
            pvValue={null}
            pvComments={null}
          />
        </TableComposable>
      )}
      {entityList.length === 0 && !isLoading && <EmptyState icon={UsersIcon} />}
      <Pagination pageCount={count} limit={limit} offset={offset} onLimitChange={setLimit} onOffsetChange={setOffset} />
    </>
  );
};

export default PregnantPatientList;
