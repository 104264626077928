import React from 'react';
import { ActionsColumn, TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { Button } from '@patternfly/react-core';

/**
 * Generic component that renders a table of given items.
 */
const EntityTable = ({ columns, rows, entity, onDelete, mainActionButtonText }) => {
  let auth = useAuth();

  const parseCell = (cell) => {
    if (typeof cell == 'boolean') return cell === true ? <CheckIcon /> : '';
    return cell;
  };

  const actions = (id) => {
    if (auth.userData?.isStaff || auth.userData?.isHospitalManager) {
      return [
        {
          title: 'Eliminar',
          onClick: () => {
            const res = window.confirm('Está seguro?');
            if (res) {
              onDelete(id);
            }
          },
        },
      ];
    }
    return [];
  };

  return (
    <React.Fragment>
      <TableComposable>
        <Thead>
          <Tr>
            {Object.keys(columns).map((k) => (
              <Th key={k}>{columns[k]}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows?.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {Object.keys(columns).map((k) => (
                <Td key={`${rowIndex}_${k}`} dataLabel={columns[k]}>
                  {parseCell(row[k])}
                </Td>
              ))}
              <Td dataLabel={mainActionButtonText} modifier="fitContent">
                <Link to={`/${entity}/${row.id}`}>
                  <Button variant="secondary" onClick={() => {}}>
                    {mainActionButtonText}
                  </Button>
                </Link>
              </Td>
              <Td isActionCell>{<ActionsColumn items={actions(row.id)} />}</Td>
            </Tr>
          ))}
        </Tbody>
      </TableComposable>
    </React.Fragment>
  );
};

EntityTable.defaultProps = {
  mainActionButtonText: 'Ver',
};

export default EntityTable;
