import React from 'react';
import Contraception from '../assets/Contraception.svg';
import Gynecology from '../assets/Gynecology.svg';
import PatientDetails from '../assets/PatientDetails.svg';
import PatientHistory from '../assets/PatientHistory.svg';
import PregnancyFirst from '../assets/PregnancyFirst.svg';
import PregnancyFollowUp from '../assets/PregnancyFollowUp.svg';
import Baby from '../assets/Baby.svg';
import { useNavigate } from 'react-router-dom';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #06c',
    borderRadius: '0.2rem',
    height: '64px',
    padding: '0.5rem 1rem',
    margin: '1.5rem',
    cursor: 'pointer',
  },
  icon: {
    filter: 'invert(21%) sepia(90%) saturate(4224%) hue-rotate(200deg) brightness(94%) contrast(101%)',
    height: '24px',
    marginRight: '1rem',
  },
  text: {
    color: '#06c',
    fontWeight: '600',
  },
};

const IconButton = ({ icon, text, link, action }) => {
  let navigate = useNavigate();
  let _icon = null;
  let _alt = '';
  switch (icon) {
    case 'baby':
      _icon = Baby;
      _alt = 'Parto';
      break;
    case 'contraception':
      _icon = Contraception;
      _alt = 'Anticoncepción';
      break;
    case 'gynecology':
      _icon = Gynecology;
      _alt = 'Ginecología';
      break;
    case 'patientDetails':
      _icon = PatientDetails;
      _alt = 'Detalle';
      break;
    case 'patientHistory':
      _icon = PatientHistory;
      _alt = 'Historial';
      break;
    case 'pregnancyFirst':
      _icon = PregnancyFirst;
      _alt = 'Embarazo: Primera consulta';
      break;
    case 'pregnancyFollowUp':
      _icon = PregnancyFollowUp;
      _alt = 'Embarazo: Seguimiento';
      break;
    default:
      break;
  }
  return (
    <div style={styles.wrapper} onClick={link !== null ? () => navigate(link) : action}>
      {_icon && <img src={_icon} alt={_alt} style={styles.icon} />}
      <span className="pf-u-primary-color-100" style={styles.text}>
        {text}
      </span>
    </div>
  );
};

export default IconButton;
