import React from 'react';
import { FormGroup, FormSection, FormSelect, FormSelectOption, Grid } from '@patternfly/react-core';

const Analisis = ({ proteinuria, onProteinuriaChange }) => {
  return (
    <FormSection
      isRequired
      title={
        <>
          Análisis
          <span className={proteinuria >= 2 ? 'ml-3 high-risk' : 'ml-3 low-risk'}>
            {proteinuria >= 2 && 'PROTEINURIA SIGNIFICATIVA'}
            {(proteinuria === 1 || proteinuria === 0) && 'NORMAL'}
          </span>
        </>
      }
    >
      <Grid md={4} hasGutter>
        <FormGroup isRequired label="Análisis de orina (Proteinuria)" fieldId="proteinuria">
          <FormSelect id="proteinuria" value={proteinuria} onChange={onProteinuriaChange}>
            <FormSelectOption key="select" isPlaceholder value={''} label="Seleccionar" />
            <FormSelectOption key="notDone" value={-1} label="No realizado" />
            <FormSelectOption key="negative" value={0} label="Negativo" />
            <FormSelectOption key={1} value={1} label="+" />
            <FormSelectOption key={2} value={2} label="++" />
            <FormSelectOption key={3} value={3} label="+++" />
            <FormSelectOption key={4} value={4} label="++++" />
          </FormSelect>
        </FormGroup>
      </Grid>
    </FormSection>
  );
};

export default Analisis;
