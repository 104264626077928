import { Routes, Route } from 'react-router-dom';
import { PatientProvider } from '../../context/patientContext';
import PatientForm from './PatientForm';
import PatientDashboard from './PatientDashboard';
import ConsultationHistory from './ConsultationHistory';
import PregnancyConsultationForm from './PregnancyConsultationForm';
import PregnancyCloseForm from './PregnancyCloseForm';
import GynecologyConsultationForm from './GynecologyConsultationForm';

const PatientBase = () => {
  return (
    <PatientProvider>
      <Routes>
        <Route path="new" element={<PatientForm />} />
        <Route path=":id" element={<PatientDashboard />} />
        <Route path=":id/data" element={<PatientForm />} />
        <Route path=":id/history" element={<ConsultationHistory />} />
        <Route path=":id/new-consultation/gynecology" element={<GynecologyConsultationForm />} />
        <Route path=":id/new-consultation/:consultationType" element={<PregnancyConsultationForm />} />
        <Route path=":id/history/:consultationId" element={<PregnancyConsultationForm />} />
        <Route path=":id/gynecology-history/:consultationId" element={<GynecologyConsultationForm />} />
        <Route path=":id/pregnancy" element={<PregnancyCloseForm />} />
      </Routes>
    </PatientProvider>
  );
};

export default PatientBase;
