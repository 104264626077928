import { useEffect, useState } from 'react';
import { Select, SelectOption, SelectVariant } from '@patternfly/react-core';
import api from '../services/api';

const InstitutionSelect = ({ value, onChange, validated, defaultValue, isDefaultValuePlaceholder }) => {
  const [institutions, setInstitutions] = useState([]);
  const [institution, setInstitution] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    api.hospitals.getMulti(null, null, 100, 0).then((data) => {
      const _institutions = data.results.map((inst) => ({
        id: inst.id,
        toString: () => inst.name,
      }));
      setInstitutions(_institutions);
    });
  }, []);

  useEffect(() => {
    if (value > 0) setInstitution(institutions.find((el) => el.id === value) || null);
    if (value === defaultValue.id) setInstitution(defaultValue);
  }, [value, institutions, defaultValue]);

  const onSelectChange = (e, v) => {
    onChange(v.id);
    setIsOpen(false);
  };

  return (
    <Select
      variant={SelectVariant.typeahead}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      selections={institution}
      onSelect={onSelectChange}
      placeholderText="Establecimiento"
      validated={validated}
    >
      <SelectOption key={0} value={defaultValue} isPlaceholder={isDefaultValuePlaceholder} />
      {institutions.map((c) => (
        <SelectOption key={c.id} value={c} />
      ))}
    </Select>
  );
};

InstitutionSelect.defaultProps = {
  validated: 'default',
  placeholderText: 'Ninguno',
  defaultValue: { id: 0, toString: () => 'Ninguno' },
  isDefaultValuePlaceholder: false,
};

export default InstitutionSelect;
