import {
  Bullseye,
  Checkbox,
  Form,
  FormGroup,
  FormSection,
  FormSelect,
  FormSelectOption,
  Grid,
  NumberInput,
  PageSection,
  PageSectionVariants,
  Spinner,
  TextArea,
  Title,
} from '@patternfly/react-core';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import FormButtons from '../../components/FormButtons';
import { usePatient } from '../../context/patientContext';
import api from '../../services/api';

const PregnancyForm = () => {
  let { id } = useParams();
  const [pregnancyId, setPregnancyId] = useState(null);
  let navigate = useNavigate();

  const { fetchPatient, patient } = usePatient();
  const [loading, setLoading] = useState(true);
  const [birthWeeks, setBirthWeeks] = useState('');
  const [birthDays, setBirthDays] = useState('');
  const [birthType, setBirthType] = useState('0');
  const [preeclampsia, setPreeclampsia] = useState(false);
  const [eclampsia, setEclampsia] = useState(false);
  const [birthComplicationsComments, setBirthComplicationsComments] = useState('');
  const [abortion, setAbortion] = useState(false);
  const [motherDeath, setMotherDeath] = useState(false);
  const [fetusDeath, setFetusDeath] = useState(false);
  const [hellpSyndrome, setHellpSyndrome] = useState(false);
  const [abortionWarning, setAbortionWarning] = useState('general');
  const [preeclampsiaWarning, setPreeclampsiaWarning] = useState('general');
  const [eclampsiaWarning, setEclampsiaWarning] = useState('general');

  const breadcrumbs = [
    { link: '/patients', title: 'Pacientes' },
    { link: `/patients/${id}`, title: `${patient?.firstName} ${patient?.lastName}` },
    { link: `/patients/${id}/pregnancy`, title: 'Cierre de embarazo' },
  ];

  useEffect(() => {
    fetchPatient(id).then((p) => {
      setPregnancyId(p?.activePregnancy.id);
      if (p?.activePregnancy.preeclampsia) setPreeclampsia(true);
      if (p?.activePregnancy.eclampsia) setEclampsia(true);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (preeclampsia) setPreeclampsiaWarning('warning');
    else setPreeclampsiaWarning('general');
    if (eclampsia) setEclampsiaWarning('warning');
    else setEclampsiaWarning('general');
    if (eclampsia) setAbortionWarning('warning');
    else setAbortionWarning('general');
  }, [abortion, eclampsia, preeclampsia]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      birthWeeks: birthWeeks ? birthWeeks : null,
      birthDays: birthDays ? birthDays : null,
      birthType,
      preeclampsia,
      eclampsia,
      birthComplicationsComments,
      abortion,
      motherDeath,
      fetusDeath,
      hellpSyndrome,
    };
    api.pregnancies.update(pregnancyId, data).then(() => {
      fetchPatient(id);
      navigate(`/patients/${id}`);
    });
  };

  if (loading) {
    return (
      <Bullseye>
        <Spinner />
      </Bullseye>
    );
  }

  const isSubmitDisabled = () => {
    return (
      ((preeclampsia || eclampsia || abortion || fetusDeath || motherDeath) && !birthComplicationsComments) ||
      !birthWeeks ||
      !birthDays ||
      birthType === '0'
    );
  };

  return (
    <PageSection>
      <Breadcrumbs links={breadcrumbs} />
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h2">Datos del embarazo</Title>
        <Form onSubmit={onSubmit} style={{ marginTop: '1.5rem' }}>
          <FormSection title="Datos del parto">
            <Grid md={4}>
              <FormGroup label="Edad gestacional al momento del parto" isRequired fieldId="birthWeeks">
                <NumberInput
                  id="birthWeeks"
                  name="birthWeeks"
                  onChange={(e) => setBirthWeeks(e.target.value)}
                  value={birthWeeks}
                  unit="semanas"
                  widthChars={2}
                  minusBtnProps={{ style: { display: 'none' } }}
                  plusBtnProps={{ style: { display: 'none' } }}
                  style={{ marginRight: '1rem' }}
                />
                <NumberInput
                  id="birthDays"
                  name="birthDays"
                  onChange={(e) => setBirthDays(e.target.value)}
                  value={birthDays}
                  unit="días"
                  widthChars={2}
                  minusBtnProps={{ style: { display: 'none' } }}
                  plusBtnProps={{ style: { display: 'none' } }}
                  style={{ marginRight: '1rem' }}
                />
              </FormGroup>
              {!abortion && (
                <FormGroup fieldId="birthType" label="Tipo de parto">
                  <FormSelect id="birthType" value={birthType} onChange={setBirthType}>
                    <FormSelectOption key="0" value="0" isPlaceholder label="Seleccionar" />
                    <FormSelectOption key="vaginal" value="vaginal" label="Vaginal" />
                    <FormSelectOption key="csection" value="csection" label="Cesárea" />
                    <FormSelectOption key="none" value="none" label="No corresponde" />
                  </FormSelect>
                </FormGroup>
              )}
            </Grid>
          </FormSection>
          <FormSection title="Complicaciones">
            <Grid md={4}>
              <FormGroup
                fieldId="preeclampsia"
                validated={preeclampsiaWarning}
                helperText={preeclampsia ? 'Especificar detalles en el cuadro de observaciones.' : ''}
              >
                <Checkbox
                  isChecked={preeclampsia}
                  onChange={() => setPreeclampsia(!preeclampsia)}
                  id="preeclampsia"
                  name="preeclampsia"
                  label="Preeclampsia"
                />
              </FormGroup>
              <FormGroup
                fieldId="eclampsia"
                validated={eclampsiaWarning}
                helperText={eclampsia ? 'Especificar detalles en el cuadro de observaciones.' : ''}
              >
                <Checkbox
                  isChecked={eclampsia}
                  onChange={() => setEclampsia(!eclampsia)}
                  id="eclampsia"
                  name="eclampsia"
                  label="Eclampsia"
                />
              </FormGroup>
              <FormGroup
                fieldId="abortion"
                validated={abortionWarning ? 'warning' : 'general'}
                helperText={abortion ? 'Especificar detalles en el cuadro de observaciones.' : ''}
              >
                <Checkbox
                  isChecked={abortion}
                  onChange={() => setAbortion(!abortion)}
                  id="abortion"
                  name="abortion"
                  label="Aborto"
                />
              </FormGroup>
              <FormGroup
                fieldId="fetusDeath"
                validated={fetusDeath ? 'warning' : 'general'}
                helperText={fetusDeath ? 'Especificar detalles en el cuadro de observaciones.' : ''}
              >
                <Checkbox
                  isChecked={fetusDeath}
                  onChange={() => setFetusDeath(!fetusDeath)}
                  id="fetusDeath"
                  name="fetusDeath"
                  label="Muerte intrauterina"
                />
              </FormGroup>
              <FormGroup
                fieldId="motherDeath"
                validated={motherDeath ? 'warning' : 'general'}
                helperText={motherDeath && 'Especificar detalles en el cuadro de observaciones.'}
              >
                <Checkbox
                  isChecked={motherDeath}
                  onChange={() => setMotherDeath(!motherDeath)}
                  id="motherDeath"
                  name="motherDeath"
                  label="Muerte materna"
                />
              </FormGroup>
              <FormGroup
                fieldId="hellpSyndrome"
                validated={hellpSyndrome ? 'warning' : 'general'}
                helperText={hellpSyndrome && 'Especificar detalles en el cuadro de observaciones.'}
              >
                <Checkbox
                  isChecked={hellpSyndrome}
                  onChange={() => setHellpSyndrome(!hellpSyndrome)}
                  id="hellpSyndrome"
                  name="motherDeath"
                  label="Síndrome de HELLP"
                />
              </FormGroup>
            </Grid>
          </FormSection>
          <FormSection title="Observaciones">
            <TextArea id="comments" onChange={setBirthComplicationsComments} value={birthComplicationsComments} />
          </FormSection>
          <FormButtons isSubmitDisabled={isSubmitDisabled} />
        </Form>
      </PageSection>
    </PageSection>
  );
};

export default PregnancyForm;
