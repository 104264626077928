import { useEffect, useState } from 'react';
import { FormSection, FormGroup, NumberInput, Grid, GridItem } from '@patternfly/react-core';
import { TableComposable, TableVariant, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { isoToPy } from '../../../utils';

const BloodPressure = ({ bloodPressure, onBloodPressureChange, bloodPressureHistory }) => {
  // controls values for each input
  const [values, setValues] = useState({
    systolicFirstTake: '',
    diastolicFirstTake: '',
    systolicSecondTake: '',
    diastolicSecondTake: '',
  });

  // controls which fields were touched
  const [touched, setTouched] = useState({
    systolicFirstTake: false,
    diastolicFirstTake: false,
    systolicSecondTake: false,
    diastolicSecondTake: false,
  });

  // controls input invalid state
  const [isValid, setIsValid] = useState({
    systolicFirstTake: 'general',
    diastolicFirstTake: 'general',
    systolicSecondTake: 'general',
    diastolicSecondTake: 'general',
  });

  // controls help text when input is invalid
  const [textInvalid, setTextInvalid] = useState({
    systolicFirstTake: '',
    diastolicFirstTake: '',
    systolicSecondTake: '',
    diastolicSecondTake: '',
  });

  // controls if blood pressure needs to be rechecked
  // true when systolicFirstTake >= 140 || diastolicFirstTake >= 90
  const [recheck, setRecheck] = useState(false);
  const [recheckText, setRecheckText] = useState('');

  useEffect(() => {
    setValues(bloodPressure);
  }, [bloodPressure]);

  const onBlur = (e) => {
    const field = e.target.name;
    const newIsValid = isValid;
    const newTextInvalid = textInvalid;
    if (values[field] < 30 || values[field] > 200) {
      newIsValid[field] = 'error';
      newTextInvalid[field] = 'El valor debe estar entre 30 y 200';
    } else {
      newIsValid[field] = 'general';
      newTextInvalid[field] = '';
    }
    setIsValid(newIsValid);
    setTextInvalid(newTextInvalid);

    // only update parent if values are correct.
    if (
      isValid.systolicFirstTake === 'general' &&
      isValid.diastolicFirstTake === 'general' &&
      isValid.systolicSecondTake === 'general' &&
      isValid.diastolicSecondTake === 'general'
    ) {
      onBloodPressureChange(values);
    }

    // set high blood pressure text
    if (
      (values.systolicFirstTake >= 140 || values.diastolicFirstTake >= 90) &&
      !values.systolicSecondTake &&
      !values.diastolicSecondTake
    ) {
      setRecheck(true);
      setRecheckText('ALTA. MEDIR NUEVAMENTE EN 10 MIN.');
    } else if (values.systolicSecondTake >= 140 || values.diastolicSecondTake >= 90) {
      setRecheck(true);
      setRecheckText('ALTA. REFERIR AL DOCTOR Y COMPLETAR CAMPO DE OBSERVACIONES CON LAS MEDIDAS QUE SE TOMARON.');
    } else {
      setRecheck(false);
      setRecheckText('NORMAL');
    }
  };

  const onInputChange = (e) => {
    const newValues = values;
    newValues[e.target.name] = parseInt(e.target.value) || '';
    setValues(newValues);
    const newTouched = { ...touched };
    newTouched[e.target.name] = true;
    setTouched(newTouched);
  };

  return (
    <FormSection
      title={
        <>
          Presión Arterial
          <span className={`blood-pressure pressure__${recheck ? 'high' : 'low'}`}>{recheckText}</span>
        </>
      }
    >
      <Grid md={4} hasGutter>
        <GridItem>
          <FormGroup
            label="Sistólica primera toma"
            isRequired
            fieldId="systolicFirstTake"
            style={{ marginBottom: '1rem' }}
            validated={isValid.systolicFirstTake}
            helperTextInvalid={textInvalid.systolicFirstTake}
            onBlur={onBlur}
          >
            <NumberInput
              placeholder="Sist."
              id="systolicFirstTake"
              inputName="systolicFirstTake"
              minusBtnProps={{ style: { display: 'none' } }}
              plusBtnProps={{ style: { display: 'none' } }}
              onChange={onInputChange}
              min={30}
              max={200}
              unit="mmHg"
              value={values.systolicFirstTake}
              widthChars={3}
            />
          </FormGroup>
          <FormGroup
            label="Diastólica primera toma"
            isRequired
            fieldId="diastolicFirstTake"
            style={{ marginBottom: '1rem' }}
            validated={isValid.diastolicFirstTake}
            helperTextInvalid={textInvalid.diastolicFirstTake}
            onBlur={onBlur}
          >
            <NumberInput
              placeholder="Diast."
              id="diastolicFirstTake"
              inputName="diastolicFirstTake"
              minusBtnProps={{ style: { display: 'none' } }}
              plusBtnProps={{ style: { display: 'none' } }}
              onChange={onInputChange}
              min={0}
              max={200}
              unit="mmHg"
              value={values.diastolicFirstTake}
              widthChars={3}
            />
          </FormGroup>
        </GridItem>
        <GridItem>
          <FormGroup
            label="Sistólica segunda toma"
            fieldId="systolicSecondTake"
            style={{ marginBottom: '1rem' }}
            validated={isValid.systolicSecondTake}
            helperTextInvalid={textInvalid.systolicSecondTake}
            onBlur={onBlur}
            isRequired={values.systolicFirstTake >= 140 || values.diastolicFirstTake >= 90}
          >
            <NumberInput
              isDisabled={!(values.systolicFirstTake >= 140 || values.diastolicFirstTake >= 90)}
              placeholder="Sist."
              id="systolicSecondTake"
              inputName="systolicSecondTake"
              minusBtnProps={{ style: { display: 'none' } }}
              plusBtnProps={{ style: { display: 'none' } }}
              onChange={onInputChange}
              min={0}
              max={200}
              unit="mmHg"
              value={values.systolicSecondTake}
              widthChars={3}
            />
          </FormGroup>
          <FormGroup
            label="Diastólica segunda toma"
            fieldId="diastolicSecondTake"
            style={{ marginBottom: '1rem' }}
            validated={isValid.diastolicSecondTake}
            helperTextInvalid={textInvalid.diastolicSecondTake}
            onBlur={onBlur}
            isRequired={values.systolicFirstTake >= 140 || values.diastolicFirstTake >= 90}
          >
            <NumberInput
              isDisabled={!(values.systolicFirstTake >= 140 || values.diastolicFirstTake >= 90)}
              placeholder="Diast."
              id="diastolicSecondTake"
              inputName="diastolicSecondTake"
              minusBtnProps={{ style: { display: 'none' } }}
              plusBtnProps={{ style: { display: 'none' } }}
              onChange={onInputChange}
              min={0}
              max={200}
              unit="mmHg"
              value={values.diastolicSecondTake}
              widthChars={3}
            />
          </FormGroup>
        </GridItem>
        <GridItem>
          <FormGroup label="Historial de medición" fieldId="" isStack>
            <TableComposable variant={TableVariant.compact}>
              <Thead>
                <Tr>
                  <Th key="fecha">Fecha</Th>
                  <Th key="sist">Sist.</Th>
                  <Th key="diast">Diast.</Th>
                </Tr>
              </Thead>
              <Tbody>
                {bloodPressureHistory.map((h, i) => (
                  <Tr key={i}>
                    <Td key={`${i}_date`}>{isoToPy(h.date)}</Td>
                    <Td key={`${i}_syst`}>{h.systolic}</Td>
                    <Td key={`${i}_diast`}>{h.diastolic}</Td>
                  </Tr>
                ))}
              </Tbody>
            </TableComposable>
          </FormGroup>
        </GridItem>
      </Grid>
      {/*{(values.systolicSecondTake >= 140 || values.diastolicSecondTake >= 90) && (*/}
      {/*  <p className="high-pressure">*/}
      {/*    Referir al doctor y completar campo de observaciones con las medidas que se tomaron.*/}
      {/*  </p>*/}
      {/*)}*/}
    </FormSection>
  );
};

export default BloodPressure;
