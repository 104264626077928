import { ActionsColumn, TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { isoToPy } from '../../../utils';
import { Button } from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import React from 'react';

const PregnancyConsultationHistory = ({ history, actions }) => {
  const columns = {
    date: 'Fecha',
    reasonsForConsultation: 'Motivos de consulta',
    planning: 'Planificación',
    hospital: 'Hospital',
    createdBy: 'Profesional',
  };

  return (
    <TableComposable>
      <Thead>
        <Tr key="heading">
          <Th key="date">{columns.date}</Th>
          <Th key="reasonsForConsultation">{columns.reasonsForConsultation}</Th>
          <Th key="planning">{columns.planning}</Th>
          <Th key="hospital">{columns.hospital}</Th>
          <Th key="doctor">{columns.createdBy}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {history.map((el) => {
          const reasons = el.reasonsForConsultation.map((r) => r.title).join(', ');
          for (const p in el) {
            if (el[p] === true) {
              var plan = p;
              if (plan === 'implantInsertion') {
                plan = 'Implante Inserción';
                break;
              } else if (plan === 'implantExtraction') {
                plan = 'Implante Extracción';
                break;
              } else if (plan === 'iudInsertion') {
                plan = 'DIU Inserción';
                break;
              } else if (plan === 'iudExtraction') {
                plan = 'DIU Extracción';
                break;
              } else if (plan === 'miniIudInsertion') {
                plan = 'Mini DIU Inserción';
                break;
              } else if (plan === 'miniIudExtraction') {
                plan = 'Mini DIU Extracción';
                break;
              } else if (plan === 'aoc') {
                plan = 'AOC';
                break;
              } else if (plan === 'dmpa') {
                plan = 'DMPA';
                break;
              } else if (plan === 'pae') {
                plan = 'PAE';
                break;
              } else if (plan === 'ai') {
                plan = 'AI';
                break;
              } else if (plan === 'counselingOnly') {
                plan = 'Solo consejería';
                break;
              }
            }
          }
          return (
            <Tr key={el.id}>
              <Td key={el.id}>{isoToPy(el.consultationDate)}</Td>
              <Td key={`${el.id}_reasons`}>{reasons}</Td>
              <Td key={`${el.id}_planning`}>{plan ? plan : 'Ninguno'}</Td>
              <Td key={`${el.id}_hospital`}>{el.hospital ? el.hospital.name : ''}</Td>
              {el.createdBy ? (
                <Td key={el.createdBy.id}>
                  {el.createdBy.lastName
                    ? `${el.createdBy.lastName}, ${el.createdBy.firstName}`
                    : el.createdBy.username}
                </Td>
              ) : (
                <Td />
              )}
              <Td dataLabel={'Editar'} modifier="fitContent">
                <Button
                  component={Link}
                  to={`/patients/${el.patient.id}/gynecology-history/${el.id}`}
                  variant="secondary"
                >
                  Editar
                </Button>
              </Td>
              <Td isActionCell>
                <ActionsColumn items={actions(el.id)} />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </TableComposable>
  );
};

export default PregnancyConsultationHistory;
