import axios from 'axios';

const errorCodes = {
  badRequest: 'badRequest',
  unauthorized: 'unauthorized',
  forbidden: 'forbidden',
  notFound: 'notFound',
  serverError: 'serverError',
  unexpected: 'unexpected',
  invalidCredentials: 'invalidCredentials',
};

class API {
  constructor(collection) {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
    this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
  }

  _handleError(error) {
    if (error.response.status === 400)
      return Promise.reject({ code: errorCodes.badRequest, data: error.response?.data });
    if (error.response.status === 401)
      return Promise.reject({ code: errorCodes.unauthorized, data: error.response?.data });
    if (error.response.status === 403)
      return Promise.reject({ code: errorCodes.forbidden, data: error.response?.data });
    if (error.response.status === 404) return Promise.reject({ code: errorCodes.notFound, data: error.response?.data });
    if (500 <= error.response.status <= 599)
      return Promise.reject({ code: errorCodes.serverError, data: error.response?.data });
    return Promise.reject({ code: errorCodes.unexpected, data: error.response?.data });
  }

  async getMulti(ordering = null, search = null, limit = 100, offset = 0, extra = {}) {
    const params = { limit, offset, ...extra };
    if (ordering) params.ordering = ordering;
    if (search && search.length > 0) params.search = search;
    try {
      const response = await axios.get(`${this.collectionUrl}/`, { params });
      return Promise.resolve(response.data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getOne(id) {
    try {
      const response = await axios.get(`${this.collectionUrl}/${id}/`);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async create(data) {
    try {
      const response = await axios.post(`${this.collectionUrl}/`, data);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async update(id, data, partial = false) {
    try {
      let response;
      if (partial) response = await axios.patch(`${this.collectionUrl}/${id}/`, data);
      else response = await axios.put(`${this.collectionUrl}/${id}/`, data);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async delete(id) {
    try {
      return await axios.delete(`${this.collectionUrl}/${id}/`);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class ConsultationAPI extends API {
  async changePatient(oldId, newId, action = 'save_both') {
    try {
      const response = await axios.put(`${this.collectionUrl}/${oldId}/change-patient/`, {
        destinationPatient: newId,
        action,
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class PatientAPI extends API {
  async update(id, data, partial = false, isConfirmation = false) {
    try {
      let response;
      if (partial)
        response = await axios.patch(`${this.collectionUrl}/${id}/?needs_confirmation=${isConfirmation}`, data);
      else response = await axios.put(`${this.collectionUrl}/${id}/?needs_confirmation=${isConfirmation}`, data);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
  async getPregnantPatients(
    ordering = null,
    search = null,
    limit = 100,
    offset = 0,
    high_risk = true,
    d = 30,
    extra = {}
  ) {
    const params = { limit, offset, high_risk, d, ...extra };
    if (ordering) params.ordering = ordering;
    if (search && search.length > 0) params.search = search;
    try {
      const response = await axios.get(`${this.baseUrl}/pregnant-patients/`, { params });
      return Promise.resolve(response.data);
    } catch (error) {
      return this._handleError(error);
    }
  }
  async downloadPregnantPatients(high_risk, d) {
    try {
      const response = await axios.get(`${this.baseUrl}/pregnant-patients/download/`, {
        responseType: 'arraybuffer',
        params: { high_risk, d },
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class UserAPI extends API {
  async getToken(username, password) {
    try {
      const response = await axios.post(`${this.collectionUrl}/token/`, { username, password });
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(errorCodes.invalidCredentials);
    }
  }

  async changePassword(id, newPassword) {
    try {
      const response = await axios.post(`${this.collectionUrl}/${id}/change-password/`, { password: newPassword });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class ReportingAPI extends API {
  async getPreeclampsia(params = {}) {
    const p = new URLSearchParams();
    for (const key of Object.keys(params)) {
      if (key === 'aspirin' || key === 'proteinuria') {
        for (const value of params[key]) {
          p.append(key, value);
        }
      } else {
        p.append(key, params[key]);
      }
    }
    try {
      const response = await axios.get(`${this.collectionUrl}/preeclampsia/data/`, { params: p });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
  async downloadPreeclampsia(params = {}) {
    try {
      const response = await axios.get(`${this.collectionUrl}/preeclampsia/download/`, {
        params,
        responseType: 'blob',
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
  async getNangareko(params = {}) {
    const p = new URLSearchParams();
    for (const key of Object.keys(params)) {
      if (key === 'reasons_for_consultation' || key === 'contraception') {
        for (const value of params[key]) {
          p.append(key, value);
        }
      } else {
        p.append(key, params[key]);
      }
    }
    try {
      const response = await axios.get(`${this.collectionUrl}/nangareko/data/`, { params: p });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
  async downloadNangareko(params = {}) {
    const p = new URLSearchParams();
    for (const key of Object.keys(params)) {
      if (key === 'reasons_for_consultation' || key === 'contraception') {
        for (const value of params[key]) {
          p.append(key, value);
        }
      } else {
        p.append(key, params[key]);
      }
    }
    try {
      const response = await axios.get(`${this.collectionUrl}/nangareko/download/`, {
        params: p,
        responseType: 'blob',
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class GynecologyConsultationAPI extends API {
  async getReasons() {
    try {
      const response = await axios.get(`${this.baseUrl}/reasons-for-consultation/`);
      return Promise.resolve(response.data.results);
    } catch (e) {
      return this._handleError(e);
    }
  }

  async getLastConsultation(patient) {
    try {
      const response = await axios.get(`${this.collectionUrl}/last-consultation/?patient=${patient}`);
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

class SurveyAPI extends API {
  async getQuestionReport(params = {}) {
    try {
      const response = await axios.get(`${this.collectionUrl}/report`, { params });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
  async downloadReport(params = {}) {
    try {
      const response = await axios.get(`${this.collectionUrl}/report/download`, { params, responseType: 'blob' });
      return Promise.resolve(response.data);
    } catch (e) {
      return this._handleError(e);
    }
  }
}

const api = {
  cities: new API('cities'),
  consultations: new ConsultationAPI('consultations'),
  gynecologyConsultations: new GynecologyConsultationAPI('gynecology-consultations'),
  hospitals: new API('hospitals'),
  hospitalUsers: new API('hospital-users'),
  patients: new PatientAPI('patients'),
  pregnancies: new API('pregnancies'),
  users: new UserAPI('users'),
  reports: new ReportingAPI('reporting'),
  aspirins: new API('aspirins'),
  survey: new SurveyAPI('rounds'),
};

export default api;
export { errorCodes, API, UserAPI };
