import React, { useEffect, useState } from 'react';
import { dateFormat, dateParse, dateSubmitParser, fileDownload } from '../../utils';
import {
  Button,
  ButtonVariant,
  Card,
  CardBody,
  CardTitle,
  DatePicker,
  Flex,
  FlexItem,
  FormSelect,
  FormSelectOption,
  Grid,
  GridItem,
  PageSection,
  Select,
  SelectOption,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  ToolbarToggleGroup,
} from '@patternfly/react-core';
import { FilterIcon } from '@patternfly/react-icons';
import InstitutionSelect from '../../components/InstitutionSelect';
import { useAuth } from '../../context/authContext';
import api from '../../services/api';
import Loading from '../../components/Loading';
import { ChartPie, ChartThemeColor } from '@patternfly/react-charts';

const NangarekoReport = () => {
  const oneMonthAgo = new Date(new Date() - 30 * 86400000);
  let { userData } = useAuth();
  const [filters, setFilters] = useState({
    date_from: dateFormat(oneMonthAgo),
    date_to: dateFormat(new Date()),
    type: 'any',
    weeks_pregnant: '',
    proteinuria: 'any',
    aspirin: 0,
    hospital: 0,
    high_bp: 'any',
    reasons_for_consultation: [],
    contraception: [],
  });
  const [reasonsSelect, setReasonsSelect] = useState({
    isOpen: false,
    selected: [],
  });
  const [contraceptionSelect, setContraceptionSelect] = useState({
    isOpen: false,
    selected: [],
  });
  const [reasonsForConsultation, setReasonsForConsultation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [report, setReport] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    api.gynecologyConsultations.getReasons().then((res) => {
      setReasonsForConsultation(res);
      setIsLoading(false);
    });
  }, []);

  const buildParams = () => {
    const params = {};
    if (filters.date_from) params['date_from'] = dateSubmitParser(filters.date_from);
    else params['date_from'] = dateSubmitParser(dateFormat(oneMonthAgo));
    if (filters.date_to) params['date_to'] = dateSubmitParser(filters.date_to);
    if (filters.type !== 'any') params['type'] = filters.type;
    if (filters.weeks_pregnant) params['weeks_pregnant'] = filters.weeks_pregnant;
    if (filters.proteinuria !== 'any') params['proteinuria'] = filters.proteinuria;
    if (filters.aspirin > 0) params['aspirin'] = filters.aspirin;
    if (filters.high_bp !== 'any') params['high_bp'] = filters.high_bp;
    if (filters.high_risk !== 'any') params['high_risk'] = filters.high_risk;
    if (filters.hospital > 0) params['hospital'] = filters.hospital;
    if (reasonsSelect.selected.length > 0) {
      params['reasons_for_consultation'] = [];
      for (const r of reasonsSelect.selected) {
        params['reasons_for_consultation'].push(r.id);
      }
    }
    if (contraceptionSelect.selected.length > 0) {
      params['contraception'] = [];
      for (const r of contraceptionSelect.selected) {
        params['contraception'].push(r);
      }
    }
    return params;
  };

  const getReport = () => {
    const params = buildParams();
    setIsLoading(true);
    api.reports
      .getNangareko(params)
      .then((res) => {
        setReport(res);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al obtener datos.');
        setIsLoading(false);
      });
  };

  const onDownload = () => {
    const params = {};
    if (filters.date_from) params['date_from'] = dateSubmitParser(filters.date_from);
    else params['date_from'] = dateSubmitParser(dateFormat(oneMonthAgo));
    if (filters.date_to) params['date_to'] = dateSubmitParser(filters.date_to);
    if (filters.type !== 'any') params['type'] = filters.type;
    if (filters.weeks_pregnant) params['weeks_pregnant'] = filters.weeks_pregnant;
    if (filters.proteinuria !== 'any') params['proteinuria'] = filters.proteinuria;
    if (filters.aspirin > 0) params['aspirin'] = filters.aspirin;
    if (filters.high_bp !== 'any') params['high_bp'] = filters.high_bp;
    if (filters.hospital > 0) params['hospital'] = filters.hospital;
    if (reasonsSelect.selected.length > 0) {
      params['reasons_for_consultation'] = [];
      for (const r of reasonsSelect.selected) {
        params['reasons_for_consultation'].push(r.id);
      }
    }
    if (contraceptionSelect.selected.length > 0) {
      params['contraception'] = [];
      for (const r of contraceptionSelect.selected) {
        params['contraception'].push(r);
      }
    }
    setIsDownloading(true);
    api.reports
      .downloadNangareko(params)
      .then((data) => {
        const now = new Date();
        const filename = `Preeclampsia ${now.getFullYear()}${
          now.getMonth() + 1
        }${now.getDate()}-${now.getHours()}${now.getMinutes()}.xlsx`;
        fileDownload(data, filename);
        setIsDownloading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al descargar el reporte.');
        setIsDownloading(false);
      });
  };

  const onReasonsSelect = (event, selection) => {
    const { selected } = reasonsSelect;
    if (selected.find((el) => el.id === selection.id)) {
      const newSelection = selected.filter((el) => el.id !== selection.id);
      setReasonsSelect({ ...reasonsSelect, selected: newSelection });
    } else {
      setReasonsSelect({ ...reasonsSelect, selected: [...selected, selection] });
    }
  };

  const onContraceptionSelect = (event, selection) => {
    const { selected } = contraceptionSelect;
    if (selected.includes(selection)) {
      const newSelection = selected.filter((el) => el !== selection);
      setContraceptionSelect({ ...contraceptionSelect, selected: newSelection });
    } else {
      setContraceptionSelect({ ...contraceptionSelect, selected: [...selected, selection] });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageSection variant="light">
        <Toolbar id="preeclampsiaFilters1">
          <ToolbarContent>
            <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="lg">
              <ToolbarItem variant="label">Fecha</ToolbarItem>
              <ToolbarGroup variant="filter-group">
                <ToolbarItem>
                  <DatePicker
                    id="dateFrom"
                    dateFormat={dateFormat}
                    dateParse={dateParse}
                    placeholder="dd/mm/aaaa"
                    locale="es-PY"
                    onChange={(value) => setFilters({ ...filters, date_from: value })}
                    value={filters.date_from}
                  />
                </ToolbarItem>
                <ToolbarItem>
                  <DatePicker
                    id="dateTo"
                    dateFormat={dateFormat}
                    dateParse={dateParse}
                    placeholder="dd/mm/aaaa"
                    locale="es-PY"
                    onChange={(value) => setFilters({ ...filters, date_to: value })}
                    value={filters.date_to}
                  />
                </ToolbarItem>
              </ToolbarGroup>
              <ToolbarItem variant="label">Tipo</ToolbarItem>
              <ToolbarItem>
                <FormSelect
                  id="consultationType"
                  value={filters.type}
                  onChange={(v) => setFilters({ ...filters, type: v })}
                >
                  <FormSelectOption key="0" value="any" label="Todos" />
                  <FormSelectOption key="1" value={2} label="Embarazo - Primera consulta" />
                  <FormSelectOption key="2" value={3} label="Embarazo - Seguimiento" />
                </FormSelect>
              </ToolbarItem>
              {userData?.isStaff && (
                <>
                  <ToolbarItem variant="label">Establecimiento</ToolbarItem>
                  <ToolbarItem>
                    <InstitutionSelect
                      id="hospital"
                      value={filters.hospital}
                      onChange={(v) => setFilters({ ...filters, hospital: v })}
                      defaultValue={{ id: 0, toString: () => 'Todos' }}
                    />
                  </ToolbarItem>
                </>
              )}
            </ToolbarToggleGroup>
          </ToolbarContent>
        </Toolbar>
        <Toolbar id="preeclampsiaFilters2">
          <ToolbarContent>
            <ToolbarItem variant="label">Motivos</ToolbarItem>
            <ToolbarItem>
              <Select
                variant="checkbox"
                onToggle={(isOpen) => setReasonsSelect({ ...reasonsSelect, isOpen })}
                onSelect={onReasonsSelect}
                selections={reasonsSelect.selected}
                isOpen={reasonsSelect.isOpen}
                placeholderText="Motivos de consulta"
              >
                {reasonsForConsultation.map((r) => (
                  <SelectOption
                    key={r.id}
                    value={{ id: r.id, toString: () => r.title, compareTo: (el) => el.id === r.id }}
                  />
                ))}
              </Select>
            </ToolbarItem>
            <ToolbarItem variant="label">Planificación</ToolbarItem>
            <ToolbarItem>
              <Select
                variant="checkbox"
                onToggle={(isOpen) => setContraceptionSelect({ ...contraceptionSelect, isOpen })}
                onSelect={onContraceptionSelect}
                selections={contraceptionSelect.selected}
                isOpen={contraceptionSelect.isOpen}
                placeholderText="Métodos de planificación"
              >
                <SelectOption value={'implant_insertion'}>IMPLANTE: INSERCIÓN</SelectOption>
                <SelectOption value={'implant_extraction'}>IMPLANTE: EXTRACCIÓN</SelectOption>
                <SelectOption value={'iud_insertion'}>DIU: INSERCIÓN</SelectOption>
                <SelectOption value={'iud_extraction'}>DIU: EXTRACCIÓN</SelectOption>
                <SelectOption value={'mini_iud_insertion'}>MINI DIU: INSERCIÓN</SelectOption>
                <SelectOption value={'mini_iud_extraction'}>MINI DIU: EXTRACCIÓN</SelectOption>
                <SelectOption value={'aoc'}>AOC</SelectOption>
                <SelectOption value={'dmpa'}>DMPA</SelectOption>
                <SelectOption value={'pae'}>PAE</SelectOption>
                <SelectOption value={'ai'}>AI</SelectOption>
                <SelectOption value={'counseling_only'}>SOLO CONSEJERÍA</SelectOption>
              </Select>
            </ToolbarItem>
            <ToolbarItem variant="label">Proteinuria</ToolbarItem>
            <ToolbarItem>
              <FormSelect
                id="proteinuria"
                value={filters.proteinuria}
                onChange={(v) => setFilters({ ...filters, proteinuria: v })}
              >
                <FormSelectOption key="select" isPlaceholder value={'any'} label="Todos" />
                <FormSelectOption key="notDone" value={-1} label="No realizado" />
                <FormSelectOption key="negative" value={0} label="Negativo" />
                <FormSelectOption key={1} value={1} label="+" />
                <FormSelectOption key={2} value={2} label="++" />
                <FormSelectOption key={3} value={3} label="+++" />
                <FormSelectOption key={4} value={4} label="++++" />
              </FormSelect>
            </ToolbarItem>
            <ToolbarItem variant="label">AAS</ToolbarItem>
            <ToolbarItem>
              <FormSelect id="aspirin" value={filters.aspirin} onChange={(v) => setFilters({ ...filters, aspirin: v })}>
                <FormSelectOption key="select" isPlaceholder value={0} label="Todos" />
                <FormSelectOption key={1} value={1} label="Entregado" />
                <FormSelectOption key={2} value={2} label="Obtuvo por sus propios medios" />
                <FormSelectOption key={3} value={3} label="No entregado" />
                <FormSelectOption key={4} value={4} label="Otros" />
              </FormSelect>
            </ToolbarItem>
            <ToolbarItem variant="label">PA</ToolbarItem>
            <ToolbarItem>
              <FormSelect id="highBp" value={filters.high_bp} onChange={(v) => setFilters({ ...filters, high_bp: v })}>
                <FormSelectOption key="any" value={'any'} label="Todos" />
                <FormSelectOption key="high" value={true} label="Alta" />
                <FormSelectOption key="normal" value={false} label="Normal" />
              </FormSelect>
            </ToolbarItem>
            <ToolbarItem>
              {isLoading && <Spinner size="md" />}
              {!isLoading && (
                <Button variant={ButtonVariant.primary} onClick={getReport}>
                  Consultar
                </Button>
              )}
            </ToolbarItem>
            <ToolbarItem align={{ default: 'alignRight' }}>
              {isDownloading && <Spinner size="md" />}
              {!isDownloading && (
                <Button variant={ButtonVariant.secondary} onClick={onDownload}>
                  Descargar
                </Button>
              )}
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      </PageSection>
      <PageSection />
      <PageSection>
        {isLoading && <Loading />}
        <Grid hasGutter>
          <GridItem span={8}>
            {report && (
              <Card>
                <CardTitle>Resumen</CardTitle>
                <CardBody>
                  <Grid md={2}>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.totalConsultations}</FlexItem>
                        <FlexItem>Consultas</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.totalPatients}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Pacientes</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.hpvFirstDose + report.hpvSecondDose}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Vacunas VPH</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.totalInsertions}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Inserciones</FlexItem>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction={{ default: 'column' }} alignItems={{ default: 'alignItemsCenter' }}>
                        <FlexItem>{report.totalExtractions}</FlexItem>
                        <FlexItem style={{ textAlign: 'center' }}>Extracciones</FlexItem>
                      </Flex>
                    </GridItem>
                  </Grid>
                </CardBody>
                <CardBody>
                  <Flex>
                    <FlexItem style={{ height: '230px', width: '350px' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Consultas</p>
                      <ChartPie
                        ariaTitle="Consultas"
                        constrainToVisibleArea={true}
                        data={[
                          { x: 'Ginecología', y: report.consultationsGynecology },
                          { x: 'Embarazo', y: report.consultationsPregnancy },
                        ]}
                        height={230}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `Ginecología: ${report.consultationsGynecology}` },
                          { name: `Embarazo: ${report.consultationsPregnancy}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 160,
                          top: 20,
                        }}
                        width={350}
                        themeColor={ChartThemeColor.green}
                      />
                    </FlexItem>
                    <FlexItem style={{ height: '230px', width: '350px' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Vacunas VPH</p>
                      <ChartPie
                        ariaTitle="Vacunas VPH"
                        constrainToVisibleArea={true}
                        data={[
                          { x: '1ra. Dosis', y: report.hpvFirstDose },
                          { x: '2da. Dosis', y: report.hpvSecondDose },
                        ]}
                        height={230}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `1ra. Dosis: ${report.hpvFirstDose}` },
                          { name: `2da. Dosis: ${report.hpvSecondDose}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 160,
                          top: 20,
                        }}
                        width={350}
                      />
                    </FlexItem>
                    <FlexItem style={{ height: '230px', width: '350px' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Inserciones</p>
                      <ChartPie
                        ariaTitle="Inserciones"
                        constrainToVisibleArea={true}
                        data={[
                          { x: 'Implante', y: report.implantInsertion },
                          { x: 'DIU', y: report.iudInsertion },
                          { x: 'Mini DIU', y: report.miniIudInsertion },
                        ]}
                        height={230}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `Implante: ${report.implantInsertion}` },
                          { name: `DIU: ${report.iudInsertion}` },
                          { name: `Mini DIU: ${report.miniIudInsertion}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 160,
                          top: 20,
                        }}
                        width={350}
                        themeColor={ChartThemeColor.gold}
                      />
                    </FlexItem>
                    <FlexItem style={{ height: '230px', width: '350px' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Extracciones</p>
                      <ChartPie
                        ariaTitle="Extracciones"
                        constrainToVisibleArea={true}
                        data={[
                          { x: 'Implante', y: report.implantExtraction },
                          { x: 'DIU', y: report.iudExtraction },
                          { x: 'Mini DIU', y: report.miniIudExtraction },
                        ]}
                        height={230}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `Implante: ${report.implantExtraction}` },
                          { name: `DIU: ${report.iudExtraction}` },
                          { name: `Mini DIU: ${report.miniIudExtraction}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 160,
                          top: 20,
                        }}
                        width={350}
                        themeColor={ChartThemeColor.orange}
                      />
                    </FlexItem>
                    <FlexItem style={{ height: '230px', width: '350px' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Otros métodos anticonceptivos</p>
                      <ChartPie
                        ariaTitle="Otros mñetodos anticonceptivos"
                        constrainToVisibleArea={true}
                        data={[
                          { x: 'AOC', y: report.aoc },
                          { x: 'DMPA', y: report.dmpa },
                          { x: 'PAE', y: report.pae },
                          { x: 'AI', y: report.ai },
                          { x: 'Solo consejería', y: report.counselingOnly },
                        ]}
                        height={230}
                        labels={({ datum }) => `${datum.x}: ${datum.y}`}
                        legendData={[
                          { name: `AOC: ${report.aoc}` },
                          { name: `DMPA: ${report.dmpa}` },
                          { name: `PAE: ${report.pae}` },
                          { name: `AI: ${report.ai}` },
                          { name: `Solo consejería: ${report.counselingOnly}` },
                        ]}
                        legendOrientation="vertical"
                        legendPosition="right"
                        padding={{
                          bottom: 20,
                          left: 20,
                          right: 160,
                          top: 20,
                        }}
                        width={350}
                        themeColor={ChartThemeColor.purple}
                      />
                    </FlexItem>
                  </Flex>
                </CardBody>
              </Card>
            )}
          </GridItem>
        </Grid>
      </PageSection>
    </>
  );
};

export default NangarekoReport;
