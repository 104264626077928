import {
  Button,
  DatePicker,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Spinner,
  Toolbar,
  ToolbarContent,
} from '@patternfly/react-core';
import React, { useState } from 'react';
import InstitutionSelect from '../components/InstitutionSelect';
import { useAuth } from '../context/authContext';
import api from '../services/api';
import { dateFormat, dateParse, dateSubmitParser, fileDownload } from '../utils';

const Reporting = () => {
  let { userData } = useAuth();
  const now = new Date();
  const oneMonthAgo = new Date(now - 30 * 86400000);
  const [hospital, setHospital] = useState(0);
  const [fromDate, setFromDate] = useState(dateFormat(oneMonthAgo));
  const [toDate, setToDate] = useState(dateFormat(new Date()));
  const [type, setType] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const applyFilters = () => {
    const params = {
      hospital: hospital > 0 ? hospital : '',
      fromDate: fromDate ? dateSubmitParser(fromDate) : '',
      toDate: toDate ? dateSubmitParser(toDate) : '',
      type: type > 0 ? type : '',
    };
    setIsDownloading(true);
    api.reports
      .download(params)
      .then((data) => {
        fileDownload(data, 'Reporte.xlsx');
        setIsDownloading(false);
      })
      .catch((e) => {
        console.error(e);
        alert('Error al descargar el reporte.');
        setIsDownloading(false);
      });
    // navigate(`/reporting?${new URLSearchParams(params).toString()}`);
  };

  const onFilterSubmit = (e) => {
    e.preventDefault();
    applyFilters();
  };

  const toolbarItems = (
    <Form onSubmit={onFilterSubmit} style={{ width: '100%' }}>
      <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }} alignItems={{ default: 'alignItemsCenter' }}>
        <Flex alignItems={{ default: 'alignItemsCenter' }}>
          <FlexItem>
            <FormGroup fieldId="dateFrom" label="Desde">
              <DatePicker
                id="dateFrom"
                dateFormat={dateFormat}
                dateParse={dateParse}
                placeholder="dd/mm/aaaa"
                locale="es-PY"
                onChange={(value) => setFromDate(value)}
                value={fromDate}
              />
            </FormGroup>
          </FlexItem>
          <FlexItem>
            <FormGroup fieldId="dateTo" label="Hasta">
              <DatePicker
                id="dateTo"
                dateFormat={dateFormat}
                dateParse={dateParse}
                placeholder="dd/mm/aaaa"
                locale="es-PY"
                onChange={(value) => setToDate(value)}
                value={toDate}
              />
            </FormGroup>
          </FlexItem>
          <FlexItem variant="separator" />
          {userData?.isStaff && (
            <FlexItem>
              <FormGroup fieldId="hospital" label="Establecimiento">
                <InstitutionSelect
                  id="hospital"
                  value={hospital}
                  onChange={setHospital}
                  defaultValue={{ id: 0, toString: () => 'Todos' }}
                />
              </FormGroup>
            </FlexItem>
          )}
          <FlexItem>
            <FormGroup fieldId="proteinuria" label="Tipo de consulta">
              <FormSelect id="proteinuria" value={type} onChange={(v) => setType(v)}>
                <FormSelectOption key="notDone" value={0} label="Todos" />
                <FormSelectOption key="no" value={2} label="Embarazo - Primera consulta" />
                <FormSelectOption key={1} value={3} label="Embarazo - Seguimiento" />
              </FormSelect>
            </FormGroup>
          </FlexItem>
          <FlexItem variant="separator" />
          <FlexItem>
            {isDownloading && <Spinner size="md" />}
            {!isDownloading && (
              <Button variant="primary" type="submit">
                Descargar
              </Button>
            )}
          </FlexItem>
        </Flex>
      </Flex>
    </Form>
  );

  return (
    <>
      <Toolbar>
        <ToolbarContent>{toolbarItems}</ToolbarContent>
      </Toolbar>
    </>
  );
};

export default Reporting;
