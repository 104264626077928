import { useParams, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import {
  Bullseye,
  PageSection,
  PageSectionVariants,
  Spinner,
  Title,
  Button,
  ModalVariant,
  Modal,
  FormGroup,
  TextInput,
  ButtonVariant,
  Grid,
  GridItem,
  List,
  ListItem,
  Nav,
  NavList,
  NavItem,
  PageSectionTypes,
} from '@patternfly/react-core';
import { ActionsColumn, TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { CONSULTATION_TYPES } from '../../constants';
import Breadcrumbs from '../../components/Breadcrumbs';
import { isoToPy } from '../../utils';
import { useAuth } from '../../context/authContext';
import { getAspirinText } from './utils';
import { usePatient } from '../../context/patientContext';
import PregnancyConsultationHistory from './components/PregnancyConsultationHistory';
import GynecologyConsultationHistory from './components/GynecologyConsultationHistory';
import AspirintHistory from './components/AspirinHistory';

const ConsultationHistory = () => {
  const { id } = useParams();
  const [history, setHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [patientChangeId, setPatientChangeId] = useState(null);
  const [destinationPatientID, setDestinationPatientID] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = useAuth();
  const { patient } = usePatient();
  const [tab, setTab] = useState(!auth.userData.isStaff && !auth.userData.enableNangareko ? 2 : 1);

  const breadcrumbs = [
    { link: '/patients', title: 'Pacientes' },
    { link: `/patients/${id}`, title: `${patient?.firstName} ${patient?.lastName}` },
    { link: `/patients/${id}/history`, title: 'Historial de consultas' },
  ];

  useEffect(() => {
    if (!auth.userData.isStaff && !auth.userData.enableNangareko && tab !== 2) {
      setTab(2);
    }
  }, [auth]);

  useEffect(() => {
    setIsLoading(true);
    if (tab === 2) {
      api.consultations.getMulti(null, null, 100, 0, { patient: id }).then((res) => {
        setHistory(res.results);
        setIsLoading(false);
      });
    } else if (tab === 1) {
      api.gynecologyConsultations.getMulti(null, null, 100, 0, { patient: id }).then((res) => {
        setHistory(res.results);
        setIsLoading(false);
      });
    } else if (tab === 3) {
      api.aspirins.getMulti(null, null, 100, 0, { patient: id }).then((res) => {
        setHistory(res.results);
        setIsLoading(false);
      });
    }
  }, [id, tab]);

  const getPatient = () => {
    api.patients.getOne(destinationPatientID).then((p) => {
      setPatientData(p);
    });
  };

  const closeModal = () => {
    setPatientChangeId(null);
    setDestinationPatientID(null);
    setPatientData(null);
    setIsModalOpen(false);
  };

  const relinkPatient = () => {
    api.consultations
      .changePatient(patientChangeId, destinationPatientID)
      .then(() => {
        setPatientChangeId(null);
        setDestinationPatientID(null);
        setPatientData(null);
        setIsModalOpen(false);
        api.consultations.getMulti(null, null, 100, 0, { patient: id }).then((res) => {
          setHistory(res.results);
          setIsLoading(false);
        });
      })
      .catch((e) => {
        console.log(e);
        alert('Ocurrio un error al realizar los cambios');
      });
  };

  const columns = {
    date: 'Fecha',
    type: 'Tipo',
    gestationalAge: 'Gesta',
    bloodPressure: 'PA',
    proteinuria: 'Proteinuria',
    aspirin: 'AAS',
    hospital: 'Hospital',
    createdBy: 'Profesional',
  };

  if (isLoading) {
    return (
      <Bullseye>
        <Spinner />
      </Bullseye>
    );
  }

  const onDelete = (id) => {
    api.consultations
      .delete(id)
      .then(() => {
        const newConsultations = history.filter((el) => el.id !== id);
        setHistory(newConsultations);
      })
      .catch((e) => {
        console.error(e);
        alert('Error inesperado. No se pudo eliminar la consulta.');
      });
  };

  const onTabChange = (res) => {
    setHistory([]);
    setTab(res.itemId);
  };

  const actions = (id) => {
    const actions = [];
    if (auth.userData?.isStaff) {
      actions.push({
        title: 'Vincular a otra paciente',
        onClick: () => {
          setPatientChangeId(id);
          setIsModalOpen(true);
        },
      });
    }
    if (auth.userData?.isStaff || auth.userData?.isHospitalManager) {
      actions.push({
        title: 'Eliminar',
        onClick: () => {
          const res = window.confirm('Está seguro?');
          if (res) {
            onDelete(id);
          }
        },
      });
    }
    return actions;
  };

  return (
    <>
      <PageSection type={PageSectionTypes.subNav} isWidthLimited>
        <Nav onSelect={onTabChange} variant="horizontal-subnav">
          <NavList>
            {(auth.userData.isStaff || auth.userData.isSuperuser || auth.userData.enableNangareko) && (
              <NavItem key={1} itemId={1} isActive={tab === 1} href="#">
                Ginecología y Planificación
              </NavItem>
            )}
            <NavItem key={2} itemId={2} isActive={tab === 2} href="#">
              Embarazo
            </NavItem>
            <NavItem key={3} itemId={3} isActive={tab === 3} href="#">
              Aspirinas
            </NavItem>
          </NavList>
        </Nav>
      </PageSection>

      <PageSection type={PageSectionTypes.breadcrumb} isWidthLimited>
        <Breadcrumbs links={breadcrumbs} />
      </PageSection>
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h2">Historial</Title>
        {tab === 1 && history && <GynecologyConsultationHistory history={history} actions={actions} />}
        {tab === 2 && history && <PregnancyConsultationHistory history={history} actions={actions} />}
        {tab === 3 && history && <AspirintHistory history={history} actions={actions} selectedPatient={patient} />}
      </PageSection>
      <Modal
        variant={ModalVariant.medium}
        title="Medium modal header"
        isOpen={isModalOpen}
        onClose={closeModal}
        actions={[
          <Button isDisabled={patientData === null} key="confirm" variant="primary" onClick={relinkPatient}>
            Confirmar
          </Button>,
          <Button key="cancel" variant="link" onClick={closeModal}>
            Cancelar
          </Button>,
        ]}
      >
        <FormGroup label="ID de la paciente de destino" isRequired fieldId="destinationPatientId">
          <Grid hasGutter>
            <GridItem span={9}>
              <TextInput
                isRequired
                type="text"
                id="destinationPatientID"
                name="destinationPatientId"
                value={destinationPatientID}
                onChange={(v) => setDestinationPatientID(v)}
              />
            </GridItem>
            <GridItem span={3}>
              <Button key="search" variant={ButtonVariant.primary} onClick={getPatient}>
                Buscar
              </Button>
            </GridItem>
          </Grid>
        </FormGroup>

        {patientData && (
          <div className="patient-data" style={{ marginTop: '1rem' }}>
            <Title headingLevel="h4">Paciente de destino</Title>
            <List isPlain>
              <ListItem>
                <strong>Nombre:</strong>
                <br />
                {patientData.firstName} {patientData.lastName}
              </ListItem>
              <ListItem>
                <strong>CI:</strong>
                <br />
                {patientData.govId}
              </ListItem>
            </List>
            <strong style={{ display: 'block', marginTop: '1rem' }}>
              Presione el botón "Confirmar" para realizar los cambios.
            </strong>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ConsultationHistory;
