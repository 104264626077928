import { useState, useEffect } from 'react';
import { FormSection, FormGroup, Checkbox, Grid, Button, ButtonVariant } from '@patternfly/react-core';
import { calculateRisk } from '../utils';

const RiskFactors = ({
  riskFactors,
  onRiskFactorsChange,
  isComponentDisabled,
  isAgeDisabled,
  onEditButtonClicked,
  showEditButton,
}) => {
  const [riskFactorText, setRiskFactorText] = useState('');

  useEffect(() => {
    const sum = calculateRisk(riskFactors);
    if (sum >= 2) {
      setRiskFactorText('ALTO');
    } else {
      setRiskFactorText('BAJO');
    }
  }, [riskFactors]);

  const _onRiskFactorChange = (name) => {
    const newRiskFactors = { ...riskFactors };
    newRiskFactors[name] = !newRiskFactors[name];
    onRiskFactorsChange(newRiskFactors);
  };

  return (
    <FormSection
      className="risk-factors"
      title={
        <>
          Evaluación de Riesgo
          {showEditButton && (
            <Button
              onClick={onEditButtonClicked}
              variant={ButtonVariant.link}
              isInline
              style={{ marginLeft: '1rem', fontSize: '.95rem' }}
            >
              {isComponentDisabled ? 'Editar' : 'Confirmar'}
            </Button>
          )}
        </>
      }
    >
      <span className={`risk-factor risk__${riskFactorText}`}>FACTOR DE RIESGO {riskFactorText}</span>
      <Grid md={6} hasGutter>
        <FormGroup label="Mayores" isStack fieldId="none">
          <Checkbox
            isChecked={riskFactors.hypertensionPreviousPregnancies}
            onChange={() => _onRiskFactorChange('hypertensionPreviousPregnancies')}
            id="hypertensionPreviousPregnancies"
            name="hypertensionPreviousPregnancies"
            label="Hipertensión arterial en embarazos anteriores"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.chronicHypertension}
            onChange={() => _onRiskFactorChange('chronicHypertension')}
            id="chronicHypertension"
            name="chronicHypertension"
            label="Hipertensión arterial crónica"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.diabetes}
            onChange={() => _onRiskFactorChange('diabetes')}
            id="diabetes"
            name="diabetes"
            label="Diabetes (Tipo 1 o 2)"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.autoimmune}
            onChange={() => _onRiskFactorChange('autoimmune')}
            id="autoimmune"
            name="autoimmune"
            label="Enfermedad autoinmune, Lupus Eritematoso Sistémico, Sindrome Antifosfolipídico"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.renal}
            onChange={() => _onRiskFactorChange('renal')}
            id="renal"
            name="renal"
            label="Enfermedad renal"
            isDisabled={isComponentDisabled}
          />
        </FormGroup>
        <FormGroup label="Moderados" isStack fieldId="none">
          <Checkbox
            id="oldAge"
            isChecked={riskFactors.oldAge}
            name="oldAge"
            label="Paciente mayor de 40 años"
            isDisabled={isComponentDisabled || isAgeDisabled}
          />
          <Checkbox
            isChecked={riskFactors.obesity}
            onChange={() => _onRiskFactorChange('obesity')}
            id="obesity"
            name="obesity"
            label="Obesidad"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.firstPregnancy}
            onChange={() => _onRiskFactorChange('firstPregnancy')}
            id="firstPregnancy"
            name="firstPregnancy"
            label="Primigesta"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.tooDistantPregnancy}
            onChange={() => _onRiskFactorChange('tooDistantPregnancy')}
            id="tooDistantPregnancy"
            name="tooDistantPregnancy"
            label="Último embarazo más de 10 años atrás"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.multiplePregnancy}
            onChange={() => _onRiskFactorChange('multiplePregnancy')}
            id="multiplePregnancy"
            name="multiplePregnancy"
            label="Embarazo múltiple"
            isDisabled={isComponentDisabled}
          />
          <Checkbox
            isChecked={riskFactors.familyPreeclampsia}
            onChange={() => _onRiskFactorChange('familyPreeclampsia')}
            id="familyPreeclampsia"
            name="familyPreeclampsia"
            label="Antecedentes familiares de Preeclampsia o HTA durante el embarazo (madre o hermanas)"
            isDisabled={isComponentDisabled}
          />
        </FormGroup>
      </Grid>
      <div>
        <p className="warning">
          No olvides realizar consejería adecuada a la paciente según el resultado de la evaluación de riesgo
        </p>
      </div>
    </FormSection>
  );
};

RiskFactors.defaultProps = {
  isComponentDisabled: false,
  isAgeDisabled: true,
};

export default RiskFactors;
