import {
  ActionGroup,
  Bullseye,
  Button,
  Form,
  FormGroup,
  PageSection,
  Spinner,
  TextInput,
  Title,
} from '@patternfly/react-core';
import api from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import DepartmentSelect from '../../components/DepartmentSelect';
import Breadcrumbs from '../../components/Breadcrumbs';

const CityForm = () => {
  let { id } = useParams();
  const [instance, setInstance] = useState(null);
  const [name, setName] = useState('');
  const [department, setDepartment] = useState(null);
  let navigate = useNavigate();
  const onFormSubmit = (e) => {
    e.preventDefault();
    id
      ? api.cities.update(id, { name, department }).then(() => navigate('/cities'))
      : api.cities.create({ name, department }).then(() => navigate('/cities'));
  };

  const breadcrumbs = () => {
    const list = [{ link: '/cities', title: 'Ciudades' }];
    if (id) {
      list.push({
        link: `/cities/${id}`,
        title: instance.name,
      });
    } else {
      list.push({ link: '/cities/new', title: 'Nueva Ciudad' });
    }
    return list;
  };

  useEffect(() => {
    id &&
      api.cities.getOne(id).then((city) => {
        setInstance(city);
        setName(city.name);
        setDepartment(city.department);
      });
  }, [id]);

  if (instance || !id) {
    return (
      <PageSection>
        <Breadcrumbs links={breadcrumbs()} />
        <PageSection variant="light">
          <Title headingLevel="h2">{id ? 'Editar' : 'Crear'} Ciudad</Title>
          <Form onSubmit={onFormSubmit}>
            <FormGroup label="Nombre" isRequired fieldId="name">
              <TextInput id="name" isRequired name="name" onChange={setName} value={name} />
            </FormGroup>
            <FormGroup label="Departamento" isRequired fieldId="department">
              <DepartmentSelect onChange={setDepartment} value={department} />
            </FormGroup>
            <ActionGroup>
              <Button variant="primary" type="submit" isDisabled={name.length < 3 || department === null}>
                Guardar
              </Button>
              <Button variant="link" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </ActionGroup>
          </Form>
        </PageSection>
      </PageSection>
    );
  } else {
    return (
      <Bullseye>
        <Spinner />
      </Bullseye>
    );
  }
};

export default CityForm;
